import authentication from './services/b2c-msal';
import { 
  AD_APP_ID,
  AD_TENANT,
  DISABLE_SSO,
  AD_REDIRECT_URI,
  AD_SIGN_IN_POLICY,
  AD_SCOPE,
  AD_PASSWORD_RESET_POLICY,
  AD_INSTANCE,
} from "./constants";

authentication.initialize({
  instance: AD_INSTANCE,
  tenant: AD_TENANT,
  signInPolicy: AD_SIGN_IN_POLICY,
  applicationId: AD_APP_ID,
  cacheLocation: 'localStorage',
  scopes: [ AD_SCOPE ],
  redirectUri: AD_REDIRECT_URI,
  resetPolicy: AD_PASSWORD_RESET_POLICY,
  postLogoutRedirectUri: window.location.origin
});


if (AD_APP_ID === "azure-appid") {
  
  require("./index-app-error.tsx");
  
} else if (DISABLE_SSO === false) {

  // runWithAdal(authContext, () => {

  //   require("./index-app.tsx");

  // }, false);
  authentication.run(() => {

    require("./index-app.tsx");

  });

} else {
  require("./index-app.tsx");
}