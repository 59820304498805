import { APP_API, V2_ORGANISATION_APP_API, REF_DATA_API } from "../constants";
import authentication from './b2c-msal';

const request =	(url: string, method: string, payload = {}) => {
  return requestWithAPI(APP_API, url, method, payload);
}

function requestV2<T>(url: string, method: string, payload: any): Promise<T> {  
  return requestWithAPIV2(V2_ORGANISATION_APP_API, url, method, payload);
}

export function getRefData<T>(url: string): Promise<T> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET"
  };
  
  return new Promise<T>((resolve, reject) => {
    
    let error = false
  
    fetch(`${REF_DATA_API}${url}`, options)
      .then(response => {
        error = !response.ok;
        return response;
      })
      .then(response => response.json().then(data => data as T))
      .then(json => {
        if (error) {
          return reject(json);
        } else {
          return resolve(json);
        }
      })
      .catch(error => reject({ error: error.message }));    
  });
}

const requestWithAPI =	(baseApi:string, url: string, method: string, payload = {}) => {

  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authentication.getAccessToken()}`
    },
    method
  };
  
  if (method === "GET") {
    url += "?" + new URLSearchParams(payload);
  } else if (method !== "DEL") {
    options.body = JSON.stringify(payload);
  }
  
  return new Promise((resolve, reject) => {
    
    let error = false
	
  	fetch(`${baseApi}${url}`, options)
      .then(response => {
        error = !response.ok;
        return response;
    	})
      .then(response => response.json())
      .then(json => {
        if (error) {
          return reject(json);
        } else {
          return resolve(json);
        }
      })
    	.catch(error => reject({ error: error.message }));
    
  });

}

function requestWithFormV2<T>(url: string, method: string, payload: FormData): Promise<T> {
  // apparently fetch doesn't work with defined content types and form data
  const options: RequestInit = {
    headers: {
      //"Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${authentication.getAccessToken()}`
    },
    method,
    body: payload
  };

  return new Promise<T>((resolve, reject) => {
      
    let error = false
  
    fetch(`${V2_ORGANISATION_APP_API}${url}`, options)
      .then(response => {
        error = !response.ok;
        return response;
      })
      .then(response => response.json().then(data => data as T))
      .then(json => {
        if (error) {
          return reject(json);
        } else {
          return resolve(json);
        }
      })
      .catch(error => reject({ error: error.message }));    
  });
}

function requestWithAPIV2<T>(baseApi:string, url: string, method: string, payload : any): Promise<T>  {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authentication.getAccessToken()}`
    },
    method
  };
  
  if (method === "GET") {
    url += "?" + new URLSearchParams(payload);
  } else if (method !== "DEL") {
    options.body = JSON.stringify(payload);
  }
  
  return new Promise<T>((resolve, reject) => {
    
    let error = false
  
    fetch(`${baseApi}${url}`, options)
      .then(response => {
        error = !response.ok;
        return response;
      })
      .then(response => response.json().then(data => data as T))
      .then(json => {
        if (error) {
          return reject(json);
        } else {
          return resolve(json);
        }
      })
      .catch(error => reject({ error: error.message }));    
  });

}

function requestFileWithAPIV2(url: string, payload?: any): Promise<Blob>  {
  const options: RequestInit = {
    headers: {
      "Authorization": `Bearer ${authentication.getAccessToken()}`
    },
    method: "GET"
  };
  
  if (payload) {
    url += "?" + new URLSearchParams(payload);
  }
  
  return new Promise<Blob>((resolve, reject) => {
    
    let error = false
  
    fetch(`${V2_ORGANISATION_APP_API}${url}`, options)
      .then(response => {
        error = !response.ok;
        
        if (error) {
          throw new Error(response.statusText);
        }        

        return response;
      })
      .then(response => response.blob())
      .then(blob => resolve(blob))
      .catch(error => reject({ error: error.message }));    
  });

}

export function del(url: string) {
	
	return request(url, "DELETE");
	
}

export function get(url: string, payload?: object) {

	return request(url, "GET", payload);

}

export function getV2<T>(url: string) : Promise<T> {
	return requestV2(url, "GET", {});
}

export function getBlobV2(url: string): Promise<Blob> {
  return requestFileWithAPIV2(url);
}

export function putV2<T>(url: string, payload: object): Promise<T>  {
	return requestV2(url, "PUT", payload);	
}

export function postV2<T>(url: string, payload: object): Promise<T> {
  return requestV2(url, "POST", payload);
}

export function postFormV2<T>(url: string, payload: FormData): Promise<T> {
  return requestWithFormV2(url, "POST", payload);
}

export function delV2<T>(url: string, payload?: object): Promise<T> {
  return requestV2(url, "DELETE", payload);
}

export function patch(url: string, payload: object) {

	return request(url, "PATCH", payload);
	
}

export function post(url: string, payload: object) {

	return request(url, "POST", payload);
	
}

export function put(url: string, payload: object) {

	return request(url, "PUT", payload);
	
}

export function upload(url: string, files: Array<any>, payload: any = {}) {
  
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();

    url += "?" + new URLSearchParams(payload);

    const formData = new FormData();

    files.forEach(file => {
      formData.append("file", file, file.name);
    });

    req.open("POST", `${APP_API}${url}`, true);
    req.setRequestHeader('Authorization', `Bearer ${authentication.getAccessToken()}`);
    req.onload = () => {
      if(req.readyState === 4) {
        if(req.status === 200) {
          resolve(req.responseText);
        } else {
          reject(req.responseText);
        }
      }
    }
    req.onerror = () => {
      reject(req.statusText);
    }
    req.send(formData);
  });
}