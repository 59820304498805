import React from "react";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface Props {
  error?: string,
  fieldName: string,
  label?: string,
  onChange?: Function,
  value: string,
  disabled?: boolean,
};

const Checkbox = (props: Props) => {
  const {
    error,
    fieldName,
    label = "",
    onChange = () => {},
    value,
    disabled = false
  } = props;
  
  return (
    <FormControl error={error !== undefined}>
      <FormControlLabel
        control={
          <MaterialCheckbox
            checked={value}
            onChange={event => onChange(event.target.checked)}
            value={fieldName}
            disabled={disabled}
          />
        }
        label={label}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default Checkbox;
