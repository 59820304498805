import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

interface Props {
  error?: string,
  fieldName: string,
  fullWidth?: boolean,
  label?: string,
  multiple?: boolean,
  onChange?: Function,
  options: any,
  value: any,
  disabled?: boolean,
  children?: any
};

const Selection = (props: Props) => {
  const {
    error,
    fieldName,
    fullWidth = false,
    label = "",
    multiple = false,
    onChange = () => {},
    options,
    value,
    disabled = false,
    children
  } = props;
  
  let className = `select ${fieldName}`;
  if (Array.isArray(value)) {
    className += " multi";
  }
  if (fullWidth) {
    className += " full-width";
  }
  
  return (
    <FormControl className={className} error={error !== undefined}>
      <InputLabel htmlFor={`${fieldName}-label-placeholder`}>{label}</InputLabel>
      <Select
        name={fieldName}
        multiple={multiple}
        value={value || (multiple ? [] : "")}
        onChange={(event: any) => onChange(event.target.value)}
        disabled={disabled}
      >
        {Object.keys(options).map((key: string, i: number) => (
          <MenuItem key={i} value={key}>{options[key]}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
      {children}
    </FormControl>
  );
};

export default Selection;
