import fileDownload from "js-file-download";
import {
  GET_RESPONSES_REQUEST,
  GET_RESPONSES_SUCCESS,
  GET_RESPONSES_FAILURE,
  SEND_RESPONSE_REQUEST,
  SEND_RESPONSE_SUCCESS,
  SEND_RESPONSE_FAILURE,
  RECEIVED_RESPONSE_FROM_SOCKET,
  RESPONSE_TYPE,
  LOAD_MORE_RESPONSES,
  CHANGE_MESSAGE,
  SET_ATTACHMENTS,
  DOWNLOAD_ATTACHMENT_REQUEST,
  DOWNLOAD_ATTACHMENT_SUCCESS,
  DOWNLOAD_ATTACHMENT_FAILURE
} from "../constants";

import {
  getResponses as getResponsesService,
  sendResponse as sendResponseService,
  downloadFile as downloadFileService
} from "../services";

import { IResponse, IUser } from "../types";

export function receivedResponseFromSocket(payload: IResponse) {
  
  return (dispatch: Function) => {
    dispatch({ type : RECEIVED_RESPONSE_FROM_SOCKET, payload });
  }
  
}

//export function getResponses(orgId: string, amount: number = RESPONSES_PAGE_SIZE) {
export function getResponses() {
  return async (dispatch : Function) => {
    
    dispatch({ type: GET_RESPONSES_REQUEST });
    
    // return getResponsesService(orgId, { amount }).then((payload: Object) => {
    return getResponsesService().then((payload: any[]) => {
      const mappedResponses: IResponse[] = payload
        .map(x => {
          const body = JSON.parse(x.activityMetaData);
          const isAttachments = [38,39].includes(x.activityType);
          return {
            id: x.id,
            CreatedBy: {
              DisplayName: x.createdByDisplayName,
              UPN: x.createdByUpn
            },
            CreatedDate: new Date(x.createdDate),
            Message: body.note || body.message,
            ResponseType: isAttachments ? 5 : 0,
            Documents: isAttachments 
              ? body.documents?.map((y: any) => ({ Link: y.blobId, FileName: y.fileName })) ?? []
              : []
          }
        })
        .sort((a, b) => a.CreatedDate.getTime() - b.CreatedDate.getTime());
      dispatch({ type: GET_RESPONSES_SUCCESS, payload: mappedResponses });
      
    }).catch(() => {
      
      dispatch({ type: GET_RESPONSES_FAILURE });
      
    });
    
  }
  
}

export function loadMoreResponses() {
  return (dispatch: Function) => {
    dispatch({ type: LOAD_MORE_RESPONSES });
  }
}

export function sendMessage(message: string, user: IUser, files?: Array<File>) {
  let response: IResponse = {
    CreatedBy: { UPN: user.email, DisplayName: `${user.givenName} ${user.surname}`},
    CreatedDate: new Date(),
    Message: message,
    ResponseType: -1
  };

  if (!!files && files.length > 1) {
    response.ResponseType = RESPONSE_TYPE.AttachmentArray;
  } else if (!!files && files.length === 1) {
    response.ResponseType = RESPONSE_TYPE.Attachment;
  } else {
    response.ResponseType = RESPONSE_TYPE.Message;
  }

  return sendResponse(response, files);
}

export function sendResponse(response: IResponse, files?: Array<File>) {
  
  return async (dispatch : Function) => {
    
    dispatch({ type: SEND_RESPONSE_REQUEST });
    
    // let uploadedFiles: Array<any> = [];
    // if (files && files.length > 0) {
    //   try {
    //     let uploads = await uploadAttachments2(orgId, files);
    //     uploadedFiles = uploads ? uploads : [];
    //   } catch (err) {
    //     dispatch({ type: SEND_RESPONSE_FAILURE });
    //     return;
    //   }
    // }

    // if (uploadedFiles) {
    //   switch(response.ResponseType) {
    //     case RESPONSE_TYPE.Attachment:
    //       response.FileName = uploadedFiles[0].fileName;
    //       response.Link = uploadedFiles[0].link;
    //     break;
    //     case RESPONSE_TYPE.AttachmentArray:
    //       response.Documents = uploadedFiles.map(({ link, fileName }: any) => ({ Link: link, FileName: fileName }));
    //     break;
    //   }
    // }
    const formData = new FormData();
    formData.append("body", response.Message);
    if (files && files.length > 0) {
      files.forEach((f, ix) => formData.append(`files${ix}`, f, f.name))
    }

    return sendResponseService(formData).then(() => {
      
      dispatch({ type: SEND_RESPONSE_SUCCESS });
      return getResponses()(dispatch);
      
    }).catch(() => {
      
      dispatch({ type: SEND_RESPONSE_FAILURE });
      
    });
    
  }
  
}

export function changeMessage(message: string) {
  return (dispatch: Function) => {
    dispatch({ type: CHANGE_MESSAGE, payload: message });
  }
}

export function setAttachments(attachments: Array<File>) {
  return (dispatch: Function) => {
    dispatch({ type: SET_ATTACHMENTS, payload: attachments });
  }
}

export function downloadMessageAttachment(id: string, blobId: string, fileName: string) {
  return (dispatch: Function) => {
    dispatch({ type: DOWNLOAD_ATTACHMENT_REQUEST });

    downloadFileService(id, blobId).then((blob: Blob) => {
      fileDownload(blob, fileName);
      dispatch({ type: DOWNLOAD_ATTACHMENT_SUCCESS })
    }).catch((err: any) => {
      dispatch({ type: DOWNLOAD_ATTACHMENT_FAILURE });
    });

  }
}