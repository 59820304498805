import React, { Component } from "react";
import { Typography, Grid } from '@material-ui/core'
import { connect } from "react-redux";

import {
  getSupportDownload as getDownloadAction
} from "../../actions"

interface SupportProps {
  getDownload: Function,
  supportDownloadUrl: string
}

class SupportAndGuidance extends Component<SupportProps, {}> {

  componentDidUpdate(prevProps: SupportProps){
    if(!!this.props.supportDownloadUrl && this.props.supportDownloadUrl !== prevProps.supportDownloadUrl){
      let link = document.createElement('a');
      link.href = this.props.supportDownloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  render() {
    const { 
      getDownload
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h4">Support and Guidance</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Getting started</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Welcome to the gateway for awarding organisations interested in applying for recognition by Ofqual.
            The gateway is an online system that enables you to communicate with us directly and seek information about what it means,
            and involves, to be recognised and regulated by Ofqual.
            You can use the gateway to arrange an initial meeting with us to discuss the recognition process.
            If you decide to apply, you will submit your application to us via the gateway.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Through the gateway you will be able to:
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            <ul>
              <li>
                Manage key information that we hold about you and your organisation,
                </li>
              <li>
                Arrange a meeting with us to find out what the recognition process involves,
                </li>
              <li>
                Complete and submit an application for recognition,
                </li>
              <li>
                Respond to requests from Ofqual to provide more information to support your application,
                </li>
              <li>
                Track the progress of your application,
                </li>
              <li>
                Be informed of the outcome of your application and our decision on whether to recognise you as an awarding organisation.
                </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            We provide <a rel="noopener noreferrer" href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank">guidance, supporting information</a> and other <a rel="noopener noreferrer" href="https://www.gov.uk/guidance/apply-to-have-your-qualifications-regulated" target="_blank">details</a> on our website to support organisations applying for recognition.
            The guidance should help you to understand our <a rel="noopener noreferrer" href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/371106/2011-05-16-criteria-for-recognition.pdf" target="_blank">recognition requirements</a>.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you wish to offer regulated qualifications in Wales or Northern Ireland,
            you will need to apply to the qualifications regulator in Wales - <a rel="noopener noreferrer" href="https://www.qualificationswales.org/english/" target="_blank">Qualifications Wales</a> or the qualifications regulator in
            Northern Ireland – <a rel="noopener noreferrer" href="http://ccea.org.uk/regulation" target="_blank">The Council for the Curriculum, Examinations and Assessment</a>.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Need further support?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Please refer to our <a href="javascript:void(0);" onClick={() => getDownload('Portal MFA How-to guide.pdf')}>how-to guide</a> for information on gateway functionality and the guidance document to help you with multi-factor authentication (MFA).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            In the first instance send us a message through the Responses message chain and we will get back to you.
            If you are unable to do this then please email: <a href="mailto:strategic.relationships@ofqual.gov.uk">strategic.relationships@ofqual.gov.uk</a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            For technical IT queries email us at: <a href="mailto:portalsupport@ofqual.gov.uk">portalsupport@ofqual.gov.uk</a>
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h4">Accessibility statement</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            You can access our accessibility statement <a rel="noopener noreferrer" href="https://www.gov.uk/government/publications/accessibility-statement-for-the-office-of-qualifications-and-examinations-regulation-ofqual/accessibility-statement-for-the-office-of-qualifications-and-examinations-regulation-ofqual" target="_blank">here</a>.
          </Typography>
        </Grid>
      </Grid>
    );
  }

}

const mapStateToProps = (state: any) => {
  const { support } = state;
  const { supportDownloadUrl } = support;
  return { supportDownloadUrl };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDownload: (link: string) => dispatch(getDownloadAction(link))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportAndGuidance);