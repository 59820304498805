import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

const AccessDenied = () => {
    return <div className="content">
        <Grid container>
            <Grid item>
                <Warning fontSize='large' />
            </Grid>
            <Grid item>
                <Typography variant='h5' style={{ paddingLeft: '25px' }}>You are not logged in</Typography>
            </Grid>
        </Grid>
    </div>
}

export default AccessDenied;