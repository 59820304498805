import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Table, TableHead, TableRow } from "@material-ui/core";
import React, { FC } from "react";
import { TableBody, TableCell } from "semantic-ui-react";

interface CookieConsentDialogProps {
  show: boolean;
  onConsent: Function
}

const CookieConsentDialog: FC<CookieConsentDialogProps> = (props) => {
  return (
    <Dialog open={props.show} maxWidth="md" aria-labelledby="cookie-consent-dialog-title" aria-describedby="cookie-consent-dialog-content">
      <DialogTitle id="cookie-consent-dialog-title">
        We use some essential cookies to make this website work
      </DialogTitle>
      {/* <DialogTitle>
        Cookies on the Ofqual Recognition gateway
      </DialogTitle> */}
      <DialogContent id="cookie-consent-dialog-content">
        <Grid container spacing={16}>
          {/* <Grid item xs={12}>
            <Typography variant="h5">
              We use some essential cookies to make this website work
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="body1">
              Cookies are files saved to your phone, tablet or computer when you visit a website. We only use cookies that are strictly necessary to the service.
            </Typography>
            <Typography variant="body1">
              These essential cookies do things like remember your progress through a form. They always need to be on.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead style={{fontWeight: "bold"}}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Purpose</TableCell>
                  <TableCell>Expires</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>ofqual-recognition-cookie-consent</TableCell>
                  <TableCell>Saves your cookie consent settings</TableCell>
                  <TableCell>1 month</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ofqual-recognition-privacy-policy-accepted</TableCell>
                  <TableCell>Records that you have seen and accepted our privacy policy</TableCell>
                  <TableCell>1 month</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>application-page</TableCell>
                  <TableCell>Saves your progress in the application form</TableCell>
                  <TableCell>1 month</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onConsent()}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CookieConsentDialog;