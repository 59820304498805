import React from 'react';
import { INavLink, IUser } from "../types";
import { MuiThemeProvider, createMuiTheme, Typography, Divider } from "@material-ui/core";
import MuiTheme from "../themes/mui-theme";
import SideNavWelcome from "./SideNavWelcome";
import SideNavLinks from "./SideNavLinks";

interface SideNavProps {
    items: Array<INavLink>;
    user: IUser | undefined;
    show: boolean;
    //login: Function;
}

const SideNav = (props: SideNavProps) => {
    const { items, user } = props;

    // if (user === undefined || !show) {
    //     return <span></span>;
    // }

    return (
        <div className="setWidth">
            <MuiThemeProvider theme={createMuiTheme(new MuiTheme().getSideNavTheme())}>
                <a href="https://www.gov.uk/government/organisations/ofqual" target="_blank" rel="noopener noreferrer">
                    <div className="logo" id="ofqual-logo">
                        <object type="image/svg+xml" data={"/" + process.env.PUBLIC_URL + "images/Ofqual_logo.svg"} height="44" style={{pointerEvents: 'none'}} aria-label='Ofqual logo'></object>
                    </div>
                    <Typography variant="srOnly">Ofqual logo</Typography>
                </a>
                <Divider />
                <SideNavWelcome show={user !== undefined} user={user} />
                <Divider />
                <SideNavLinks links={items} user={user} login={() => {}} />
            </MuiThemeProvider>
        </div>
    )
};

export default SideNav;