import { default as Page1 } from "./applicationPage1";
import { default as Page2 } from "./applicationPage2";
import { default as Page3 } from "./applicationPage3";
import { default as Page4 } from "./applicationPage4";
import { default as Page5 } from "./applicationPage5";
import { default as Page6 } from "./applicationPage6";
import { default as Page7 } from "./applicationPage7";
import { default as Page8 } from "./applicationPage8";
import { default as Page9 } from "./applicationPage9";

export default [
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
  Page9
];