export const GET_ORGANISATION_DETAILS_FAILURE = "GET_ORGANISATION_DETAILS_FAILURE";
export const GET_ORGANISATION_DETAILS_REQUEST = "GET_ORGANISATION_DETAILS_REQUEST";
export const GET_ORGANISATION_DETAILS_SUCCESS = "GET_ORGANISATION_DETAILS_SUCCESS";

export const SET_ORGANISATION_DETAILS_FAILURE = "SET_ORGANISATION_DETAILS_FAILURE";
export const SET_ORGANISATION_DETAILS_REQUEST = "SET_ORGANISATION_DETAILS_REQUEST";
export const SET_ORGANISATION_DETAILS_SUCCESS = "SET_ORGANISATION_DETAILS_SUCCESS";

export const GET_ORGANISATIONS_FAILURE = "GET_ORGANISATIONS_FAILURE";
export const GET_ORGANISATIONS_SUCCESS = "GET_ORGANISATIONS_SUCCESS";
export const GET_ORGANISATIONS_REQUEST = "GET_ORGANISATIONS_REQUEST";

export const SORT_ORGANISATIONS_BY_FOLLOW_UP = "SORT_ORGANISATIONS_BY_FOLLOW_UP";
export const SORT_ORGANISATIONS_BY_STATUS = "SORT_ORGANISATIONS_BY_STATUS";
export const SORT_ORGANISATIONS_BY_ASSIGNED = "SORT_ORGANISATIONS_BY_ASSIGNED";
export const SORT_ORGANISATIONS_BY_LAST_RESPONSE = "SORT_ORGANISATIONS_BY_LAST_RESPONSE";

export const UPDATE_ORGANISATION_DETAILS = "UPDATE_ORGANISATION_DETAILS";