import React, { useEffect } from "react";
import { Typography, Grid, IconButton } from '@material-ui/core';
import { CloudDownload, DeleteForever } from '@material-ui/icons';

import { SupportingDocument } from "../../types";

interface Props {
  file: Array<SupportingDocument>;
  getDownload: Function;
  deleteFile: Function;
  downloadUrl: string;
  disabled: boolean;
}

const DownloadLink = (props: Props) => {
  const { file, getDownload, downloadUrl, deleteFile, disabled } = props;

  useEffect(() => {
      if (!!downloadUrl) {
        let link = document.createElement('a');
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  }, [downloadUrl])

  const renderDownload = (file: SupportingDocument, key?: number) => {
    return (
      <Grid item xs={12} key={key}>
        <Grid container spacing={8} alignItems='center'>
          <Grid item>
            <IconButton onClick={() => getDownload(file.blobId, file.fileName)}>
              <CloudDownload />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant='body1'>{file.fileName}</Typography>
          </Grid>
          <Grid item>
            <IconButton disabled={disabled} onClick={() => deleteFile(file.blobId)}>
              <DeleteForever />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Grid container spacing={8}>
        {Array.isArray(file) ? (
          file.map((f, i) => renderDownload(f, i))
        ) : renderDownload(file)}
      </Grid>
    </div>
  )
}

export default DownloadLink;