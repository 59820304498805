import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { authentication } from "./authentication";
import { applications } from "./applications";
import { responses } from "./responses";
import { organisations } from "./organisations";
import { alert } from "./alert";
import { app } from './app';
import { support } from './support';
import { RefDataState, referenceData } from "./referenceData";

interface State {
  authentication: any,
  router: any,
  applications: any,
  responses: any,
  organisations: any,
  alert: any,
  support: any,
  app: any,
  referenceData: RefDataState
};

const rootReducer = (history: any) => combineReducers<State>({
  authentication,
  router: connectRouter(history),
  applications,
  responses,
  organisations,
  alert,
  support,
  app,
  referenceData,
});

export default rootReducer;
