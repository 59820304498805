const UNSET = 0;
const EXPERT_APPLICANT = 1;
const EXPERT_STAFF = 2;
const RECOGNITION_APPLICANT = 3;
const RECOGNITION_STAFF = 4;

export default {
  UNSET,
  EXPERT_APPLICANT,
  EXPERT_STAFF,
  RECOGNITION_APPLICANT,
  RECOGNITION_STAFF
};