import React from "react";
import moment from "moment";
import { Grid, Typography } from '@material-ui/core';

import { IApplicationProps } from "../../../types";
import { TextField, YesNo, Upload } from "../../";

class ApplicationPage9 extends React.Component<IApplicationProps> {

  render() {
    
    const {
      fieldErrors,
      pageData,
      updateApplicationState,
      readonlyControls,
      application,
      uploadSupportingDocuments,
      getDownload,
      downloadUrl,
      deleteFile
    } = this.props;
    
    const { supportingDocuments } = application;
    
    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>12. Supporting evidence and checklist</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>To help us process your application please check that:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <ul>
                <li>{
                  `all supporting evidence is labelled and cross-referenced to your 
                  application form`
                }</li>
                <li>{
                  `all documents (application, response and supporting information) 
                  are in Microsoft Word, Excel, PowerPoint or Adobe PDF formats or a 
                  format compatible with these programs and are clearly labelled and 
                  referenced to your responses in the application`
                }</li>
                <li>{
                  `all documents referenced in this application form are included. 
                  Please tell us the number of documents so we can check we receive 
                  all the documents you intend us to`
                }</li>
                <li>{
                  `file names of documents should be kept precise, abbreviated where
                  applicable and should use a maximum of 30 characters. The following 
                  characters are unsupported / not allowable when used in file names: 
                  + & " : * ? <> # {} % ~ / Tab.`
                }</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={readonlyControls}
              error={fieldErrors["application.pageData.numberOfDocuments"]}
              fieldName="numberOfDocuments"
              label={"Please state the number of supporting documents that you are providing to us: "}
              onChange={(value: string) => updateApplicationState(8, "numberOfDocuments", value)}
              value={pageData["numberOfDocuments"] || ""}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Upload
              label="Upload Documents"
              value={supportingDocuments || null}
              uploadAction={(files: FileList) => uploadSupportingDocuments(files)}
              multiple={true}
              getDownload={getDownload}
              downloadUrl={downloadUrl}
              deleteFile={deleteFile}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>13. Approval and submission</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Do you agree to us contacting other bodies to whose regulation you
              or your senior officers are subject? *`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <YesNo
              error={fieldErrors["application.pageData.section8a"]}
              fieldName="section8a"
              onChange={(value: string) => updateApplicationState(8, "section8a", value)}
              value={pageData["section8a"]?.toString() || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Do you agree to us contacting other organisations named in your 
              application or supporting materials to verify the information you 
              have provided? *`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <YesNo
              error={fieldErrors["application.pageData.section8b"]}
              fieldName="section8b"
              onChange={(value: string) => updateApplicationState(8, "section8b", value)}
              value={pageData["section8b"]?.toString() || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>14. Declaration of proposed responsible officer</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `I certify that the information on this application form is complete, 
              true and accurate. I understand that, should Ofqual grant recognition, 
              should I provide information which is found to be untrue or 
              inaccurate, I and/or the awarding organisation may be subject to 
              investigation and possible regulatory action.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={fieldErrors["application.pageData.proposedResponsibleOfficer"]}
              fieldName="proposedResponsibleOfficer"
              label="Full name"
              onChange={(value: string) => updateApplicationState(8, "proposedResponsibleOfficer", value)}
              value={pageData["proposedResponsibleOfficer"] || ""}
              disabled={readonlyControls}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fieldName="submissionTimestamp"
              label="Date"
              value={pageData.submissionTimestamp ? moment(new Date(pageData.submissionTimestamp)).format("h:mma Do MMM YYYY") : moment().format("h:mma Do MMM YYYY")}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage9;