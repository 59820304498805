import { IResponseIconTypes } from "../types";

export const GET_RESPONSES_REQUEST = "GET_RESPONSES_REQUEST";
export const GET_RESPONSES_SUCCESS = "GET_RESPONSES_SUCCESS";
export const GET_RESPONSES_FAILURE = "GET_RESPONSES_FAILURE";

export const SEND_RESPONSE_REQUEST = "SEND_RESPONSE_REQUEST";
export const SEND_RESPONSE_FAILURE = "SEND_RESPONSE_FAILURE";
export const SEND_RESPONSE_SUCCESS = "SEND_RESPONSE_SUCCESS";

export const RECEIVED_RESPONSE_FROM_SOCKET = "RECEIVED_RESPONSE_FROM_SOCKET";

export const LOAD_MORE_RESPONSES = "recognition/Responses/LOAD_MORE_RESPONSES";
export const CHANGE_MESSAGE = "recognition/Responses/CHANGE_MESSAGE";
export const SET_ATTACHMENTS = "recognition/Responses/SET_ATTACHMENTS";

export const DOWNLOAD_ATTACHMENT_REQUEST = 'recognition/Responses/DOWNLOAD_ATTACHMENT_REQUEST';
export const DOWNLOAD_ATTACHMENT_SUCCESS = 'recognition/Responses/DOWNLOAD_ATTACHMENT_SUCCESS';
export const DOWNLOAD_ATTACHMENT_FAILURE = 'recognition/Responses/DOWNLOAD_ATTACHMENT_FAILURE';

export const RESPONSES_PAGE_SIZE = 5;

export const RESPONSE_TYPE = {
  Message: 0,
  Assignment: 1,
  StatusUpdate: 2,
  Attachment: 3,
  FieldValueChange: 4,
  AttachmentArray: 5
};

export const ALL_RESPONSE_OPTIONS = ["send a message", "upload an attachment"];

export const getIconFromFileName = (fileName?: string) : keyof IResponseIconTypes => {

  if (!fileName) {
    return "file alternate";
  }

  var filetype = fileName.substring(fileName.lastIndexOf(".") + 1);
  
  switch (filetype) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "file image";
    case "doc":
    case "docx":
    case "dot":
    case "dotx":
    case "docm":
    case "dotm":
      return "file word";
    case "txt":
    case "text":
    case "config":
      return "file alternate";
    case "xls":
    case "xlt":
    case "xlm":
    case "xlsx":
    case "xlsm":
    case "xltx":
    case "xltm":
    case "csv":
      return "file excel";
    case "ppt":
    case "pot":
    case "pps":
    case "pptx":
    case "pptm":
    case "potx":
    case "potm":
    case "ppam":
    case "ppsx":
    case "ppsm":
    case "sldx":
    case "sldm":
      return "file powerpoint";
    case "zip":
    case "rar":
    case "7z":
    case "tar":
      return "file powerpoint";
    case "pdf":
      return "file pdf";
    default:
      return "file alternate";
  }
};
