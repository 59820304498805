import {
  GET_USER_APPLICATION_REQUEST,
  GET_USER_APPLICATION_SUCCESS,
  GET_USER_APPLICATION_FAILURE,
  SET_USER_APPLICATION_REQUEST,
  SET_USER_APPLICATION_SUCCESS,
  SET_USER_APPLICATION_FAILURE,
  GET_APPLICATION_DOCUMENTS_REQUEST,
  GET_APPLICATION_DOCUMENTS_SUCCESS,
  GET_APPLICATION_DOCUMENTS_FAILURE,
  SET_APPLICATION_DOCUMENTS_REQUEST,
  SET_APPLICATION_DOCUMENTS_SUCCESS,
  SET_APPLICATION_DOCUMENTS_FAILURE,
  GET_APPLICATION_STATUS_REQUEST,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATION_STATUS_FAILURE,
  SET_APPLICATION_STATUS_REQUEST,
  SET_APPLICATION_STATUS_SUCCESS,
  SET_APPLICATION_STATUS_FAILURE,
  DOWNLOAD_APPLICATION_DOCUMENT_REQUEST,
  DOWNLOAD_APPLICATION_DOCUMENT_SUCCESS,
  DOWNLOAD_APPLICATION_DOCUMENT_FAILURE,
  DISMISS_DOWNLOAD_ERROR,
  DELETE_APPLICATION_DOCUMENT_REQUEST,
  DELETE_APPLICATION_DOCUMENT_SUCCESS,
  DELETE_APPLICATION_DOCUMENT_FAILURE
} from "../constants";

import { IApplication, IApplicationManagement,  Action, Application } from "../types";

interface State {
  uploading: boolean,
  downloading: boolean,
  downloadError: boolean;
  sending: boolean,
  loading: boolean,
  application: IApplication | null
  applicationStatus: IApplicationManagement | null
  downloadUrl: string,
  currentApplication: Application | null
}

const initialState : State = {
  uploading: false,
  downloading: false,
  downloadError: false,
  sending: false,
  loading: false,
  application: null,
  applicationStatus: null,
  downloadUrl: '',
  currentApplication: null
};

export function applications(state = initialState, action: Action) {
  const { payload }: { payload?: any } = action;
  
  switch(action.type) {
    case GET_USER_APPLICATION_REQUEST:
      return { ...state, loading: true, application: null }
    case GET_USER_APPLICATION_SUCCESS:
    return { ...state, loading: false, application: payload?.spaApplication, currentApplication: payload?.currentApplication }
    case GET_USER_APPLICATION_FAILURE:
      return { ...state, loading: false }
    case SET_USER_APPLICATION_REQUEST:
      return { ...state, sending: true }
    case SET_USER_APPLICATION_SUCCESS:
      return { ...state, sending: false, application: payload?.spaApplication, currentApplication: payload?.currentApplication }
    case SET_USER_APPLICATION_FAILURE:
      return { ...state, sending: false }
    case GET_APPLICATION_STATUS_REQUEST:
      return { ...state, loading: true, applicationStatus: null }
    case GET_APPLICATION_STATUS_SUCCESS:
      return { ...state, loading: false, applicationStatus: payload }
    case GET_APPLICATION_STATUS_FAILURE:
      return { ...state, loading: false }
    case SET_APPLICATION_STATUS_REQUEST:
      return { ...state, sending: true }
    case SET_APPLICATION_STATUS_SUCCESS:
      return { ...state, sending: false, applicationStatus: payload}
    case SET_APPLICATION_STATUS_FAILURE:
      return { ...state, sending: false }

    case GET_APPLICATION_DOCUMENTS_REQUEST:
      return { ...state, downloading: true }
    case GET_APPLICATION_DOCUMENTS_SUCCESS:
      return {...state, downloading: false }//TODO
    case GET_APPLICATION_DOCUMENTS_FAILURE:
      return { ...state, downloading: false }
    case SET_APPLICATION_DOCUMENTS_REQUEST:
      return { ...state, uploading: true }
    case SET_APPLICATION_DOCUMENTS_SUCCESS:
      return {...state, uploading: false, application: payload?.spaApplication, currentApplication: payload?.currentApplication }//TODO
    case SET_APPLICATION_DOCUMENTS_FAILURE:
      return { ...state, uploading: false }
    case DOWNLOAD_APPLICATION_DOCUMENT_REQUEST:
      return { ...state, downloading: true };
    case DOWNLOAD_APPLICATION_DOCUMENT_SUCCESS:
      return { ...state, downloading: false };
    case DOWNLOAD_APPLICATION_DOCUMENT_FAILURE:
      return { ...state, downloading: false, downloadError: true };
    case DISMISS_DOWNLOAD_ERROR:
      return { ...state, downloadError: false };
    case DELETE_APPLICATION_DOCUMENT_REQUEST:
      return { ...state, deleting: true };
    case DELETE_APPLICATION_DOCUMENT_SUCCESS:
      return {...state, deleting: false, application: payload?.spaApplication, currentApplication: payload?.currentApplication }//TODO
    case DELETE_APPLICATION_DOCUMENT_FAILURE:
      return { ...state, deleting: false };
    default:
      return state;
  }
}