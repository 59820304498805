import React from "react";
import { Grid, Typography, RadioGroup, FormControl, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

import { IApplicationProps } from "../../../types";

class ApplicationPage5 extends React.Component<IApplicationProps> {

  render() {

    const {
      fieldErrors,
      pageData,
      updateApplicationState,
      readonlyControls
    } = this.props;

    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>8. Criteria B: Integrity</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>B.1 Integrity of the applicant</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `The Applicant must be a suitable person to be recognised for the 
              award of the relevant qualifications, having regard in particular to:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              (A) Any criminal convictions held by it,<br />
              (B) Any finding by a court or any professional, regulatory, or government body that it has been in breach of any provision of Competition Law, Equalities Law, or Data Protection Law,<br />
              (C) Any finding by a court or any professional, regulatory, or government body that it has been in breach of a provision of any legislation or any regulatory obligation to which it is subject, and<br />
              (D) Any instance of insolvency or corporate financial restructuring to which it is or has been subject.<br />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`Please select an option below *`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={fieldErrors["application.pageData.criteriaB1"] !== undefined}>
              <RadioGroup
                aria-label="criteriaB1"
                name="criteriaB1"
                value={String(pageData.criteriaB1)}
                onChange={(event: React.ChangeEvent<unknown>) => {
                  let val;
                  if((event.target as HTMLInputElement).value === "true"){
                    val = true
                  } else {
                    val = false
                  }
                  updateApplicationState(4, "criteriaB1", val)
                }}
              >
                <FormControlLabel value={"true"} control={<Radio disabled={readonlyControls} />} label="On behalf of the Applicant I declare that I have made diligent enquiry, including having had regard to the matters specified in this criterion, and have identified no concerns relating to the integrity of the Applicant. I am aware that Ofqual may conduct its own enquiries in this regard and should it discover I have provided false information this may cause it to reject the application for recognition or, should such information be discovered following recognition, to take enforcement action." />
                <FormControlLabel value={"false"} control={<Radio disabled={readonlyControls}/>} label="Having made diligent enquiry, I have identified matters likely to of concern in respect of the integrity of the applicant and I provide full details of those concerns and an explanation how the attendant risks will be mitigated with the application." />
              </RadioGroup>
              <FormHelperText>{fieldErrors["application.pageData.criteriaB1"]}</FormHelperText>
            </FormControl>
            {/* <Checkbox
              disabled={readonlyControls}
              error={fieldErrors["application.pageData.criteriaB1"]}
              fieldName="criteriaB1"
              label="I confirm that I meet criterion B.1"
              onChange={(value: boolean) => updateApplicationState(4, "criteriaB1", value)}
              value={pageData.criteriaB1}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>B.2 Integrity of Senior Officers</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Each of the Senior Officers of the Applicant must be a suitable 
              person to be engaged in that role in an awarding organisation that is 
              recognised for the award of the relevant qualifications, having 
              regard in particular to:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              (A) Any criminal convictions held by him or her,<br />
              (B) Any finding by a court or any professional, regulatory, or government body that he or she has breached a provision of any legislation or any regulatory obligation to which he or she is subject,<br />
              (C) Any instance of bankruptcy or any individual financial arrangements to which he or she is or has been subject,<br />
              (D) Any occasion on which he or she has been disqualified from holding the directorship of a company or from public office, and<br />
              (E) Any finding of malpractice or maladministration, in relation to a qualification (whether a regulated qualification or a qualification which is not regulated), to which he or she is or has been subject.`
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`Please select an option below *`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={fieldErrors["application.pageData.criteriaB2"] !== undefined}>
              <RadioGroup
                aria-label="criteriaB2"
                name="criteriaB2"
                value={String(pageData.criteriaB2)}
                onChange={(event: React.ChangeEvent<unknown>) => {
                  let val;
                  if((event.target as HTMLInputElement).value === "true"){
                    val = true
                  } else {
                    val = false
                  }
                  updateApplicationState(4, "criteriaB2", val)
                }}
              >
                <FormControlLabel value={"true"} control={<Radio disabled={readonlyControls}/>} label="On behalf of the Applicant I declare that I have made diligent enquiry, including having had regard to the matters specified in this criterion, and have identified no concerns relating to the integrity of the proposed Senior Officers. I am aware that Ofqual may conduct its own enquiries in this regard and should it discover I have provided false information this may cause it to reject the application for recognition or, should such information be discovered following recognition, to take enforcement action." />
                <FormControlLabel value={"false"} control={<Radio disabled={readonlyControls}/>} label="Having made diligent enquiry, I have identified matters likely to of concern in respect of the integrity of one or more of the proposed Senior Officers and I provide full details of those concerns and an explanation how the attendant risks will be mitigated with the application." />
              </RadioGroup>
              <FormHelperText>{fieldErrors["application.pageData.criteriaB2"]}</FormHelperText>
            </FormControl>
            {/* <Checkbox
              disabled={readonlyControls}
              error={fieldErrors["application.pageData.criteriaB2"]}
              fieldName="criteriaB2"
              label="I confirm that I meet criterion B.2"
              onChange={(value: boolean) => updateApplicationState(4, "criteriaB2", value)}
              value={pageData.criteriaB2}
            /> */}
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage5;