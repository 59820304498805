import React from "react";
import moment from "moment";
import { DatePicker as DatePickerComponent } from "material-ui-pickers";

interface Props {
  defaultValue?: number,
  disableFuture?: boolean,
  error?: string,
  fieldName: string,
  label?: string,
  multiple?: boolean,
  onChange?: Function,
  value: string | number,
  disabled?: boolean,
  fullWidth?: boolean,
  withManual?: boolean,
  format?: string
};

class DatePicker extends React.Component<Props> {
  
  componentDidMount() {
    const { value, onChange = () => {} } = this.props;
    onChange(moment(value, "X").unix());
  }
  
  render() {
    const {
      disableFuture = false,
      error,
      label = "",
      onChange = () => {},
      value,
      disabled = false,
      fieldName,
      fullWidth = false,
      withManual = false,
      format = "Do MMM YYYY"
    } = this.props;
    
    let className = `datepicker ${fieldName}`;
    if (Array.isArray(value)) {
      className += " multi";
    }
    if (fullWidth) {
      className += " full-width";
    }

    return (
      <DatePickerComponent
        className={className}
        autoOk
        disableFuture={disableFuture}
        format={format}
        helperText={error}
        label={label}
        value={moment(value).toDate()}
        onChange={(value: moment.Moment) => { onChange(new Date(value.toDate().getTime() - value.toDate().getTimezoneOffset() * 60000).toISOString().split('T')[0]) }}
        disabled={disabled}
        keyboard={withManual}
      />
    );
    
  }
  
}

export default DatePicker;
