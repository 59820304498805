import { TOGGLE_DRAWER } from '../constants';

interface Action {
    type: string;
}

interface State {
    mobileOpen: boolean;
}

const initialState: State = {
    mobileOpen: false,
};

export function app(state = initialState, action: Action) {
    switch(action.type) {
        case TOGGLE_DRAWER: 
            return { ...state, mobileOpen: !state.mobileOpen };
        default: 
            return state;
    }
}