export const GET_USER_APPLICATION_REQUEST = "GET_USER_APPLICATION_REQUEST";
export const GET_USER_APPLICATION_SUCCESS = "GET_USER_APPLICATION_SUCCESS";
export const GET_USER_APPLICATION_FAILURE = "GET_USER_APPLICATION_FAILURE";

export const SET_USER_APPLICATION_REQUEST = "SET_USER_APPLICATION_REQUEST";
export const SET_USER_APPLICATION_SUCCESS = "SET_USER_APPLICATION_SUCCESS";
export const SET_USER_APPLICATION_FAILURE = "SET_USER_APPLICATION_FAILURE";

export const GET_APPLICATION_DOCUMENTS_REQUEST = "GET_APPLICATION_DOCUMENTS_REQUEST";
export const GET_APPLICATION_DOCUMENTS_SUCCESS = "GET_APPLICATION_DOCUMENTS_SUCCESS";
export const GET_APPLICATION_DOCUMENTS_FAILURE = "GET_APPLICATION_DOCUMENTS_FAILURE";

export const SET_APPLICATION_DOCUMENTS_REQUEST = "SET_APPLICATION_DOCUMENTS_REQUEST";
export const SET_APPLICATION_DOCUMENTS_SUCCESS = "SET_APPLICATION_DOCUMENTS_SUCCESS";
export const SET_APPLICATION_DOCUMENTS_FAILURE = "SET_APPLICATION_DOCUMENTS_FAILURE";

export const GET_APPLICATION_STATUS_REQUEST = "GET_APPLICATION_STATUS_REQUEST";
export const GET_APPLICATION_STATUS_SUCCESS = "GET_APPLICATION_STATUS_SUCCESS";
export const GET_APPLICATION_STATUS_FAILURE = "GET_APPLICATION_STATUS_FAILURE";

export const SET_APPLICATION_STATUS_REQUEST = "SET_APPLICATION_STATUS_REQUEST";
export const SET_APPLICATION_STATUS_SUCCESS = "SET_APPLICATION_STATUS_SUCCESS";
export const SET_APPLICATION_STATUS_FAILURE = "SET_APPLICATION_STATUS_FAILURE";

export const UPLOAD_MANAGEMENT_REQUEST = "UPLOAD_MANAGEMENT_REQUEST";
export const UPLOAD_MANAGEMENT_SUCCESS = "UPLOAD_MANAGEMENT_SUCCESS";
export const UPLOAD_MANAGEMENT_FAILURE = "UPLOAD_MANAGEMENT_FAILURE";

export const DOWNLOAD_APPLICATION_DOCUMENT_REQUEST = 'recognition/Applications/DOWNLOAD_APPLICATION_DOCUMENT_REQUEST';
export const DOWNLOAD_APPLICATION_DOCUMENT_SUCCESS = 'recognition/Applications/DOWNLOAD_APPLICATION_DOCUMENT_SUCCESS';
export const DOWNLOAD_APPLICATION_DOCUMENT_FAILURE = 'recognition/Applications/DOWNLOAD_APPLICATION_DOCUMENT_FAILURE';
export const DISMISS_DOWNLOAD_ERROR = 'recognition/Applications/DISMISS_DOWNLOAD_ERROR';

export const DELETE_APPLICATION_DOCUMENT_REQUEST = 'recognition/Applications/DELETE_APPLICATION_DOCUMENT_REQUEST';
export const DELETE_APPLICATION_DOCUMENT_SUCCESS = 'recognition/Applications/DELETE_APPLICATION_DOCUMENT_SUCCESS';
export const DELETE_APPLICATION_DOCUMENT_FAILURE = 'recognition/Applications/DELETE_APPLICATION_DOCUMENT_FAILURE';

export const APPLICATION_CHARACTERS_MAX = 3000;
export const APPLICATION_WORDS_MAX = 500;
export const APPLICATION_LIMIT_FIELDS = [
  "furtherDetails", "explanationOnRecognised", "criteriaA4", "criteriaA5",
  "criteriaA6", "criteriaC1b", "qualificationDetails"
];
export const APPLICATION_LIMIT_FIELDS_DOUBLE = [
  "criteriaC1a", "competentD1a", "competentD1b", "competentD1c"
];

export const APPLICATION_STATUS = [
  "",
  "In Progress",
  "Recognised",
  "Recognised with special conditions",
  "Rejected"
]

export const INTERNAL_APPLICATION_STATUS = [
  "",
  "Pending",
  "Under Review",
  "Awaiting internal meeting",
  "Decision - awaiting letter to be sent",
  "Awaiting external panel",
  "Approved",
  "Recognised",
  "Recognised with special conditions",
  "Rejected"
];

// taken from /portal/Views/Mixins/Helpers/CountryValues.pug
export const COUNTRIES = { 
  'United Kingdom': 'United Kingdom',
  Ireland: 'Ireland',
  Afghanistan: 'Afghanistan',
  Albania: 'Albania',
  Algeria: 'Algeria',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  'Antigua & Barbuda': 'Antigua & Barbuda',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Aruba: 'Aruba',
  Australia: 'Australia',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaijan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahrain',
  Bangladesh: 'Bangladesh',
  Barbados: 'Barbados',
  Belarus: 'Belarus',
  Belgium: 'Belgium',
  Belize: 'Belize',
  Benin: 'Benin',
  Bermuda: 'Bermuda',
  Bhutan: 'Bhutan',
  Bolivia: 'Bolivia',
  'Bosnia & Herzegovina': 'Bosnia & Herzegovina',
  Botswana: 'Botswana',
  Brazil: 'Brazil',
  'British Virgin Islands': 'British Virgin Islands',
  Brunei: 'Brunei',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  Cambodia: 'Cambodia',
  Cameroon: 'Cameroon',
  Canada: 'Canada',
  'Cape Verde': 'Cape Verde',
  'Cayman Islands': 'Cayman Islands',
  Chad: 'Chad',
  Chile: 'Chile',
  China: 'China',
  Colombia: 'Colombia',
  Congo: 'Congo',
  'Cook Islands': 'Cook Islands',
  'Costa Rica': 'Costa Rica',
  'Cote D Ivoire': 'Cote D Ivoire',
  Croatia: 'Croatia',
  Cuba: 'Cuba',
  Cyprus: 'Cyprus',
  'Czech Republic': 'Czech Republic',
  Denmark: 'Denmark',
  Djibouti: 'Djibouti',
  Dominica: 'Dominica',
  'Dominican Republic': 'Dominican Republic',
  Ecuador: 'Ecuador',
  Egypt: 'Egypt',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Equatorial Guinea',
  Estonia: 'Estonia',
  Ethiopia: 'Ethiopia',
  'Falkland Islands': 'Falkland Islands',
  'Faroe Islands': 'Faroe Islands',
  Fiji: 'Fiji',
  Finland: 'Finland',
  France: 'France',
  'French Polynesia': 'French Polynesia',
  'French West Indies': 'French West Indies',
  Gabon: 'Gabon',
  Gambia: 'Gambia',
  Georgia: 'Georgia',
  Germany: 'Germany',
  Ghana: 'Ghana',
  Gibraltar: 'Gibraltar',
  Greece: 'Greece',
  Greenland: 'Greenland',
  Grenada: 'Grenada',
  Guam: 'Guam',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernsey',
  Guinea: 'Guinea',
  'Guinea Bissau': 'Guinea Bissau',
  Guyana: 'Guyana',
  Haiti: 'Haiti',
  Honduras: 'Honduras',
  'Hong Kong': 'Hong Kong',
  Hungary: 'Hungary',
  Iceland: 'Iceland',
  India: 'India',
  Indonesia: 'Indonesia',
  Iran: 'Iran',
  Iraq: 'Iraq',
  'Isle of Man': 'Isle of Man',
  Israel: 'Israel',
  Italy: 'Italy',
  Jamaica: 'Jamaica',
  Japan: 'Japan',
  Jersey: 'Jersey',
  Jordan: 'Jordan',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kuwait: 'Kuwait',
  'Kyrgyz Republic': 'Kyrgyz Republic',
  Laos: 'Laos',
  Latvia: 'Latvia',
  Lebanon: 'Lebanon',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  Libya: 'Libya',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Macau: 'Macau',
  Macedonia: 'Macedonia',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Maldives',
  Mali: 'Mali',
  Malta: 'Malta',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauritius',
  Mexico: 'Mexico',
  Moldova: 'Moldova',
  Monaco: 'Monaco',
  Mongolia: 'Mongolia',
  Montenegro: 'Montenegro',
  Montserrat: 'Montserrat',
  Morocco: 'Morocco',
  Mozambique: 'Mozambique',
  Namibia: 'Namibia',
  Nepal: 'Nepal',
  Netherlands: 'Netherlands',
  'Netherlands Antilles': 'Netherlands Antilles',
  'New Caledonia': 'New Caledonia',
  'New Zealand': 'New Zealand',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Norway: 'Norway',
  Oman: 'Oman',
  Pakistan: 'Pakistan',
  Palestine: 'Palestine',
  Panama: 'Panama',
  'Papua New Guinea': 'Papua New Guinea',
  Paraguay: 'Paraguay',
  Peru: 'Peru',
  Philippines: 'Philippines',
  Poland: 'Poland',
  Portugal: 'Portugal',
  'Puerto Rico': 'Puerto Rico',
  Qatar: 'Qatar',
  Reunion: 'Reunion',
  Romania: 'Romania',
  Russia: 'Russia',
  Rwanda: 'Rwanda',
  'Saint Pierre & Miquelon': 'Saint Pierre & Miquelon',
  Samoa: 'Samoa',
  'San Marino': 'San Marino',
  'Saudi Arabia': 'Saudi Arabia',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leone',
  Singapore: 'Singapore',
  Slovakia: 'Slovakia',
  Slovenia: 'Slovenia',
  'South Africa': 'South Africa',
  'South Korea': 'South Korea',
  Spain: 'Spain',
  'Sri Lanka': 'Sri Lanka',
  'St Kitts Nevis': 'St Kitts Nevis',
  'St Lucia': 'St Lucia',
  'St Vincent': 'St Vincent',
  Sudan: 'Sudan',
  Suriname: 'Suriname',
  Swaziland: 'Swaziland',
  Sweden: 'Sweden',
  Switzerland: 'Switzerland',
  Syria: 'Syria',
  Taiwan: 'Taiwan',
  Tajikistan: 'Tajikistan',
  Tanzania: 'Tanzania',
  Thailand: 'Thailand',
  'Timor L\'Este': 'Timor L\'Este',
  Togo: 'Togo',
  Tonga: 'Tonga',
  'Trinidad & Tobago': 'Trinidad & Tobago',
  Tunisia: 'Tunisia',
  Turkey: 'Turkey',
  Turkmenistan: 'Turkmenistan',
  'Turks & Caicos': 'Turks & Caicos',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  'United Arab Emirates': 'United Arab Emirates',
  'United States': 'United States',
  'United States Minor Outlying Islands': 'United States Minor Outlying Islands',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistan',
  Venezuela: 'Venezuela',
  Vietnam: 'Vietnam',
  'Virgin Islands (US)': 'Virgin Islands (US)',
  Yemen: 'Yemen',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabwe' 
};

export const LEGAL_ENTITY_TYPES = {
  "Corporate entity": "Corporate entity",
  "Registered charity": "Registered charity",
  "Limited liability partnership": "Limited liability partnership",
  "Sole trader (individual)": "Sole trader (individual)",
  "Partnership (except limited liability partnership)": "Partnership (except limited liability partnership)",
  "Higher education or further education institution": "Higher education or further education institution",
  "Other (please specify)": "Other (please specify)"
};

export const QUALIFICATION_TYPES = {
  "Advanced Extension Award": "Advanced Extension Award",
  "End-Point Assessment": "End-Point Assessment",
  "English For Speakers Of Other Languages": "English For Speakers Of Other Languages",
  "Functional Skills": "Functional Skills",
  "GCE A Level": "GCE A Level",
  "GCE AS Level": "GCE AS Level",
  "GCSE (9 to 1)": "GCSE (9 to 1)",
  "GCSE (A* to G)": "GCSE (A* to G)",
  "Project": "Project",
  "Other": "Other"
}