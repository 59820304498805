import React from 'react';
import { Grid, TextField, Button, IconButton, Badge } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';

interface Props {
    message: string;
    onChange: (event: any) => void;
    attachmentCount: number;
    sendClicked: (event: any) => void;
    attachClicked: (event: any) => void;
    isSending: boolean;
}

const renderIconButton = (attachmentCount: number) => {
    if (attachmentCount > 0) {
        return (
            <Badge badgeContent={attachmentCount} color="secondary">
                <AttachFile />
            </Badge>
        );
    } else {
        return <AttachFile />;
    }
}

const ResponsesAdd = (props: Props) => {
    const { message, onChange, attachmentCount, sendClicked, attachClicked, isSending } = props;

    return (
        <Grid container spacing={8} direction='row' justify='space-between' alignItems='center'>
            <Grid item xs={12}>
                <TextField
                    onChange={onChange}
                    label='Message'
                    placeholder='Send a message...'
                    multiline
                    fullWidth
                    rowsMax={10}
                    variant='filled'
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={message}
                    id='responses-add-text-field' />
            </Grid>
            <Grid item>
                <Button color='primary' onClick={sendClicked} disabled={isSending} id='responses-add-send-button'>
                    Send
                </Button>
            </Grid>
            <Grid item>
                <IconButton disabled={isSending} component='span' color='primary' onClick={attachClicked} id='responses-add-attach-button'>
                    {renderIconButton(attachmentCount)}
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default ResponsesAdd;