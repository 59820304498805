import React from 'react';
import { Grid, Avatar, Typography, Divider, Paper, List, ListItem, ListItemIcon, ListItemText, Chip } from '@material-ui/core';
import { AttachFile, CompareArrows } from '@material-ui/icons';
import moment from 'moment';
import { RESPONSE_TYPE } from '../../constants';
import { IResponse } from '../../types';

interface Props {
    response: IResponse;
    divider: boolean;
    downloadAttachment: Function;
}

const renderAssignment = (response: IResponse, isRegulator: boolean) => {
    const { Message, AssignedTo } = response;
    const initials = AssignedTo.DisplayName.split(' ').map((n: string) => n.substring(0,1)).join('');
    return (
        <div>
            {Message && <Paper elevation={1} style={isRegulator ? { padding: 6 * 2, float: 'left' } : { padding: 6 * 2, backgroundColor: '#ffdf9e', float: 'right' }}>
                <Typography component="p" style={{whiteSpace: 'pre-wrap'}}>
                    {Message}
                </Typography>
            </Paper>}
            <Chip
                avatar={<Avatar>{initials}</Avatar>}
                label={AssignedTo.DisplayName}
                color="primary"
            />
        </div>
    );
}

const renderAttachment = (response: IResponse, isRegulator: boolean, downloadAttachment: Function) => {
    const { id, Message, FileName, Link } = response;
    return (
        <Paper elevation={1} style={isRegulator ? { padding: 6 * 2, float: 'left' } : { padding: 6 * 2, backgroundColor: '#ffdf9e', float: 'right' }}>
            <Typography component="p" style={{whiteSpace: 'pre-wrap'}}>
                {Message}
                <List dense>
                    <ListItem button dense onClick={() => downloadAttachment(id, Link, FileName)}>
                        <ListItemIcon>
                            <AttachFile />
                        </ListItemIcon>
                        <ListItemText
                            primary={FileName}
                        />
                    </ListItem>
                </List>
            </Typography>
        </Paper>
    );
}

const renderAttachmentArray = (response: IResponse, isRegulator: boolean, downloadAttachment: Function) => {
    const { id, Documents, Message } = response;
    const docs = !!Documents ? Documents : [];
    return (
        <Paper elevation={1} style={isRegulator ? { padding: 6 * 2, float: 'left' } : { padding: 6 * 2, backgroundColor: '#ffdf9e', float: 'right' }}>
            <Typography component="p" style={{whiteSpace: 'pre-wrap'}}>
                {Message}
                <List dense>
                    {
                        docs.map((doc: any, index: number) => {
                            return <ListItem button dense onClick={() => downloadAttachment(id, doc.Link, doc.FileName)}>
                                <ListItemIcon>
                                    <AttachFile />
                                </ListItemIcon>
                                <ListItemText
                                    primary={doc.FileName}
                                />
                            </ListItem>
                        })
                    }
                </List>
            </Typography>
        </Paper>
    );
}

const renderMessage = (response: IResponse, isRegulator: boolean) => {
    const { Message } = response;
    return (
        <Paper elevation={1} style={isRegulator ? { padding: 6 * 2, float: 'left' } : { padding: 6 * 2, backgroundColor: '#ffdf9e', float: 'right' }}>
            <Typography component="p" style={{whiteSpace: 'pre-wrap'}}>
                {Message}
            </Typography>
        </Paper>
    );
}

const renderContents = (response: IResponse, isRegulator: boolean, downloadAttachment: Function) => {
    const { ResponseType } = response;
    switch(ResponseType) {
        case RESPONSE_TYPE.Assignment: 
            return renderAssignment(response, isRegulator);
        case RESPONSE_TYPE.Attachment: 
            return renderAttachment(response, isRegulator, downloadAttachment);
        case RESPONSE_TYPE.AttachmentArray: 
            return renderAttachmentArray(response, isRegulator, downloadAttachment)
        case RESPONSE_TYPE.Message: 
            return renderMessage(response, isRegulator);
        case RESPONSE_TYPE.StatusUpdate: 
            return renderStatusUpdate(response, isRegulator);
        default:
            return <div></div>;
    }
}

const renderStatusUpdate = (response: IResponse, isRegulator: boolean) => {
    const { Message, NewStatus } = response;
    return (
        <div>
            {Message && <Paper elevation={1} style={isRegulator ? { padding: 6 * 2, float: 'left' } : { padding: 6 * 2, backgroundColor: '#ffdf9e', float: 'right' }}>
                <Typography component="p" style={{whiteSpace: 'pre-wrap'}}>
                    {Message}
                </Typography>
            </Paper>}<Chip
                icon={<CompareArrows />}
                label={NewStatus}
                color="primary"
            />
        </div>
    )
}

const ResponsesListItem = (props: Props) => {
    const { response, divider, downloadAttachment } = props;
    const { CreatedBy, CreatedDate } = response;
    const isSentByOfqual = CreatedBy.UPN.endsWith('@ofqual.gov.uk');
    const createdByName = CreatedBy.DisplayName.split(' ');

    return (
        <Grid item xs={12}>
            <Grid container direction='column' justify='flex-start' alignItems={isSentByOfqual ? 'flex-start' : 'flex-end'} spacing={8}>
                <Grid item>
                    <Grid container spacing={8} direction='row' justify='flex-start' alignItems='center'>
                        <Grid item>
                            <Avatar alt={CreatedBy.DisplayName}>{createdByName.map((n: string) => n.substring(0, 1))}</Avatar>
                        </Grid>
                        <Grid item>
                            <Typography>{CreatedBy.DisplayName}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{maxWidth: '100%'}}>
                    {renderContents(response, isSentByOfqual, downloadAttachment)}
                </Grid>
                <Grid item>
                    <Typography variant='caption'>
                        {moment(CreatedDate).format('Do MMM YYYY HH:mm')}
                    </Typography>
                </Grid>
                {divider && <Divider style={{width: '30%'}} />}
            </Grid>
        </Grid>
    )
}

export default ResponsesListItem;