import React, { Component } from "react";
import { connect } from "react-redux";

import { clearAlert } from "../../actions";
import { IFieldErrors } from "../../types";

const withFormErrors = <P extends Object>(Wrapped: React.ComponentType<P>) => {
  
  interface Props {
    fieldErrors: IFieldErrors,
    clearAlert: Function
  }
  
  const mapStateToProps = (state: any) => {
    
    const { alert } = state;
    
    return {
      fieldErrors: alert.fieldErrors
    }
  }
  
  class WithFormErrors extends Component<P & Props> {

    render() {
      
      return <Wrapped {...this.props}/>;
    }
    
  }
  
  return connect(mapStateToProps, { clearAlert })(WithFormErrors as any);
  
};

export default withFormErrors;