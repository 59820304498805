import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <p>
    Please supply Azure AD connection values in the environment.
    Refer to the README for more details.
  </p>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
