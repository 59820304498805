import { Action } from "../types";
import { Title, Country, LegalEntityType } from "../types/ref-data";
import { LOADING_REF_DATA, REF_DATA_SUCCESS, REF_DATA_ERROR } from "../constants/referenceData";
import { Reducer } from "redux";

export interface RefDataState {
    titles: Title[],
    countries: Country[],
    legalEntityTypes: LegalEntityType[]
    loading: boolean;
}

const initialState: RefDataState = {
    loading: false,
    titles: [],
    countries: [],
    legalEntityTypes: []
}

export const referenceData: Reducer<RefDataState, any> = (state = initialState, action: Action) => {
    const { payload }: { payload?: any } = action;

    switch (action.type) {
        case LOADING_REF_DATA:
            const newState: RefDataState = { ...state, loading: true }
            return newState;
        case REF_DATA_SUCCESS:
            return {
                ...state,
                titles: payload?.titles || [],
                countries: payload?.countries || [],
                legalEntityTypes: payload?.legalEntityTypes || [],
                loading: false
            };
        case REF_DATA_ERROR:
            return { ...state, loading: false }
        default:
            return state;
    }
}