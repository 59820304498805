import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Helmet } from "react-helmet";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { CssBaseline, Drawer, AppBar, Toolbar, Typography } from "@material-ui/core";
// import "semantic-ui-css/semantic.min.css";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ExitToApp, Domain, Help, Assignment } from '@material-ui/icons';
import { withCookies, Cookies } from "react-cookie";
import authentication from '../services/b2c-msal';

import MuiTheme from "../themes/mui-theme";
import { logout as logoutAction, toggleDrawer, getMe as getMeAction, getMeFromToken as getMeFromTokenAction } from "../actions";
import { history, Routes } from "../resources";

// import { SocketConnection } from "../services";
import { SideNav, AccessDenied } from "../components";
import { IADUser, IUser, INavLink, IOrganisationDetails } from "../types";

import { Alert } from "./";
import moment from "moment";
import CookieConsentDialog from "../components/CookieConsentDialog";

interface Props {
  adUser?: IADUser;
  localUser?: IUser;
  pathname: string;
  logout: Function;
  handleDrawerToggle: Function;
  mobileOpen: boolean;
  getMe: Function;
  getMeFromToken: Function;
  organisationDetails: IOrganisationDetails;
  cookies: Cookies;
}

interface State {
  email: string
  showCookiePolicy: boolean;
}

const COOKIE_CONSENT = "ofqual-recognition-cookie-consent";

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      showCookiePolicy: false
    }    
  }
  componentDidMount() {
    this.props.getMeFromToken();
  }

  hasConsentedToCookies = () => {
    return !!this.props.cookies.get(COOKIE_CONSENT);
  }

  setCookieConsent = (choice: boolean) => {
    this.props.cookies.set(COOKIE_CONSENT, choice, { 
      sameSite: true,
      expires: moment().add(1, "months").toDate(),
      path: "/" 
    });
  }
  
  render() {
    const { localUser, logout, mobileOpen, organisationDetails } = this.props;

    const navItems: Array<INavLink> = [
      { text: "Organisation details", url: "/", icon: <Domain />, show: true },
      { text: "Support and guidance", url: "/support", icon: <Help />, show: true },
      { text: "Apply", url: "/apply", icon: <Assignment />, show: (organisationDetails.allowedToApply === true) },
      { text: "Log out", action: logout, icon: <ExitToApp />, show: true },
    ];
    
    return (
      <MuiThemeProvider theme={createMuiTheme(new MuiTheme().getViewTheme())}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ConnectedRouter history={history}>
            <div className="Root">
              <Helmet
                title="Ofqual"
              />
              <Alert/>
              <AppBar>
                <Toolbar className="AppBar">
                  <Typography variant='h6'>Recognition</Typography>
                </Toolbar>
              </AppBar>
              <nav>
                <Drawer variant="permanent" className="Drawer" open>
                  <SideNav user={localUser} items={navItems} show={mobileOpen} />
                </Drawer>
              </nav>
              <main className="Content">
                <div className="Padded">
                  <div className="Toolbar">
                    <Switch>
                      {Routes.map((route, i) => {
                        const {
                          component: Component,
                          path,
                          title
                        } = route;

                        if (!localUser) {
                          return <AccessDenied key={i} />;
                        }

                        return (
                          <Route
                            key={i}
                            path={path}
                            render={(routeProps: Object) => (
                              <Component
                                title={title}
                                {...routeProps as any}
                                userId={localUser._id}
                                type={localUser.type}
                                user={localUser}
                              />
                            )}
                          />
                        );
                      })}
                    </Switch>
                  </div>
                </div>
              </main>
            </div>
          </ConnectedRouter>
        </MuiPickersUtilsProvider>
        <CookieConsentDialog show={!this.hasConsentedToCookies() || this.state.showCookiePolicy} onConsent={() => this.setCookieConsent(true)} />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  
  const { authentication, router, organisations, app } = state;
  const { user: localUser } = authentication;
  const { mobileOpen } = app;
  const { organisationDetails } = organisations;
  
  return { localUser, pathname: router.location.pathname, mobileOpen, organisationDetails };
  
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => { dispatch(logoutAction()); authentication.signOut(); },
    handleDrawerToggle: () => dispatch(toggleDrawer()),
    getMe: () => dispatch(getMeAction()),
    getMeFromToken: () => dispatch(getMeFromTokenAction(authentication.getAccessToken() || ""))
  }
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(App));
