import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Grid, Button, Typography, CircularProgress } from '@material-ui/core';

import { IResponse, IUser } from '../../types';
import { ResponsesListItem, ResponsesAdd } from '../../components/responses';
import { 
    getResponses as getResponsesAction, 
    loadMoreResponses,
    sendMessage as sendMessageAction,
    setAttachments as setAttachmentsAction,
    changeMessage as changeMessageAction,
    downloadMessageAttachment as downloadAttachmentAction } from '../../actions/responses';
import { AttachmentUpload, DownloadPreparing } from '../../components/attachments';

interface Props {
    sending: boolean;
    loading: boolean;
    responses: Array<IResponse>;
    showResponseCount: number;
    clickLoadMore: (event: any) => void;
    getResponses: Function;
    currentMessage: string;
    currentAttachments: Array<File>;
    sendMessage: Function;
    changeMessage: Function;
    setAttachments: Function;
    orgId: string;
    downloadAttachment: Function;
    isPreparingDownload: boolean;
    downloadUrl: string;
    localUser: IUser;
}

const ResponsesList = (props: Props) => {
    const { 
        responses, 
        showResponseCount, 
        clickLoadMore, 
        getResponses, 
        loading,
        sending, 
        currentMessage, 
        currentAttachments,
        sendMessage,
        changeMessage,
        setAttachments,
        orgId,
        downloadAttachment,
        isPreparingDownload, 
        localUser } = props;

    const [showAttachments, setShowAttachments] = useState(false);

    const clickSend = (event: any) => {
        if (!currentMessage && !currentAttachments) {
            return;
        }

        sendMessage(currentMessage, localUser, currentAttachments);
    }

    const clickAttach = (event: any) => {
        setShowAttachments(true);
    }

    const onAttachmentLoad = (files: FileList | null) => {
        if (files !== null) {
            let attachments = Array.from(files);
            setAttachments(attachments);
        } else {
            setAttachments([]);
        }

        setShowAttachments(false);
    }
    
    const onChangeMessage = (event: any) => {
        const value = event.target.value;
        changeMessage(value);
    }

    useEffect(() => { 
        getResponses()
    }, [sending, orgId, getResponses]);

    return (
        <Grid container spacing={16}>
            {responses && responses.length > showResponseCount &&
                <Grid item xs={12} container justify='center'>
                    <Grid item>
                        <Button component='span' onClick={clickLoadMore}>Show older</Button>
                    </Grid>
                </Grid>
            }
            {loading && 
                <Grid item xs={12} container justify='center'>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            }
            {(responses.length === 0 && !loading) && 
                <Grid item xs={12}>
                    <Typography variant='body1'>There are no messages</Typography>
                </Grid>
            }
            {responses && 
                responses.map((r: IResponse, ix: number) => {
                    if (ix >= responses.length - showResponseCount) {
                        const showDivider = responses.length !== (ix + 1);
                        return <ResponsesListItem key={r.CreatedDate.toString()} response={r} divider={showDivider} downloadAttachment={downloadAttachment} />
                    }
                    return (<></>)
                })
            }
            <Grid item xs={12}>
                <ResponsesAdd message={currentMessage} 
                    onChange={onChangeMessage} 
                    attachmentCount={currentAttachments.length} 
                    sendClicked={clickSend}
                    attachClicked={clickAttach} 
                    isSending={sending} />
            </Grid>
            <AttachmentUpload show={showAttachments} onClose={() => setShowAttachments(false)} onAttach={onAttachmentLoad} />
            <DownloadPreparing show={isPreparingDownload} />
        </Grid>
    );
}

const mapStateToProps = (state: any) => {
    const { responses, organisations, authentication } = state;
    const { organisationDetails } = organisations;
    const { user: localUser } = authentication;
    const { sending, loading, responses: allResponses, showResponseCount, currentMessage, currentAttachments, isPreparingDownload, downloadUrl } = responses;
    
    return { sending, 
        loading, 
        responses: allResponses, 
        showResponseCount, 
        currentMessage, 
        currentAttachments, 
        orgId: organisationDetails._id, 
        isPreparingDownload, 
        downloadUrl,
        localUser };
  };
  
  const mapDispatchToProps = (dispatch: any) => {    
    return {
        getResponses: () => dispatch(getResponsesAction()),
        clickLoadMore: (event: any) => dispatch(loadMoreResponses()),
        sendMessage: (message: string, user: IUser, attachments?: Array<File>) => {
            dispatch(sendMessageAction(message, user, attachments))
        },
        changeMessage: (message: string) => dispatch(changeMessageAction(message)),
        setAttachments: (attachments: Array<File>) => dispatch(setAttachmentsAction(attachments)),
        downloadAttachment: (responseId: string, blobId: string, fileName: string) => {
            dispatch(downloadAttachmentAction(responseId, blobId, fileName))
        }
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ResponsesList);