import { ALERT, ALERT_CLEAR, ALERT_DIALOG_CLOSE } from "../constants";
import { IFieldErrors } from "../types";

export const alertAction = (message: string) => ({ type: ALERT, message });

interface IExpressError {
  location: string,
  msg: string,
  param: string
};

export function error(errors: any) {
  const toCamelCase = (s: string) => {
    return s.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  return (dispatch: Function) => {
  
    let fieldErrors: IFieldErrors = {};
    let message: any = false;
    
    if (errors.hasOwnProperty("errors")) {
      if(Array.isArray(errors.errors)){
        errors.errors.forEach((error: IExpressError) => {
          fieldErrors[error.param] = error.msg;
          message = "Some fields are invalid. Please correct any errors and try again.";
        });
      }
      else{
        const errorKeys = Object.keys(errors.errors).map(x => {
          if (errors["shouldChangeCase"] === false) {
            return { origKey: x, cameCaseKey: x };
          }
          return { origKey: x, cameCaseKey: toCamelCase(x) }
        });
        errorKeys.forEach(({ origKey, cameCaseKey })=>{
          var value = errors.errors[origKey];
          fieldErrors[cameCaseKey] = value;
          message = "Some fields are invalid. Please correct any errors and try again.";
        });
      }
      
    } else if (errors.hasOwnProperty("error")) {
      message = errors.error;
    } else {
      message = errors;
    }
    
    dispatch({ type: ALERT, message, error: true, fieldErrors });
    
  };
  
};

export const clearAlert = () => {
  return { type: ALERT_CLEAR };
};

export const clearAlertDialog = () => {
  return { type: ALERT_DIALOG_CLOSE };
}
  