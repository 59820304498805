import { get, post, upload } from "./";
import { putV2, getV2, postFormV2, delV2, getBlobV2 } from './api';
import { Application } from '../types';



export function getUserApplication(): Promise<Application> {
  
  return getV2(`/recognition/application`);
  
}

export function submitUserApplicationPage(application: Application): Promise<any> {
  
  return putV2<Application>(`/recognition/application`, application);
}

export async function uploadSupportingDocuments(userId: string, files: Array<File>): Promise<any> {
  let formData: FormData = new FormData();
  files.forEach((f, ix) => formData.append(`files${ix}`, f, f.name));
  return postFormV2<Application>(`/recognition/application/files`, formData);
}

export function getApplicationSasDownloadUrl(link: string): Promise<any> {
  const splitLink = link.split('/');
  const fileName = splitLink.pop();
  const userId = splitLink.pop();

  return get(`/application/download/${userId}/${fileName}`);
}

export function deleteFile(blobId: string): Promise<any> {
  return delV2(`/recognition/application/files/${blobId}`);
}

export function getSupportingDocument(userId: string): Promise<any> {
  
  return get(`/application/${userId}/supporting/`);
  
}

export function getApplicationSupportingDocument(blobId: string): Promise<Blob> {
  return getBlobV2(`/recognition/application/files/${blobId}`);
}

export function uploadManagementFile(applicationId: string, files: object[], documentType: string): Promise<any> {
  return upload(`/storage-mutator/${applicationId}`, files,
    { type : "app_management", documentType }
  );
}

export function getApplicationStatus(userId: string): Promise<any> {
  
  return get(`/application/manage/${userId}`);
  
}

export function setApplicationStatus(userId: string, applicationStatus: Object): Promise<any> {
  
    return post(`/application/manage/${userId}`, applicationStatus);
  
}