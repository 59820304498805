import React from "react";
import MaterialTextField from "@material-ui/core/TextField";

interface Props {
  disabled?: boolean;
  error?: string;
  fieldName: string;
  multiLine?: boolean;
  rows?: number;
  rowsMax?: number;
  label?: string;
  onChange?: Function;
  value: string;
  required?: boolean;
};

const TextField = (props: Props) => {
  const {
    disabled = false,
    error,
    fieldName,
    label = "",
    onChange = () => {},
    rows = 1,
    rowsMax,
    multiLine = false,
    value,
    required = false,
  } = props;
  
  return (
    <MaterialTextField
      disabled={disabled}
      multiline={multiLine}
      rows={rows}
      rowsMax={(rowsMax) ? rowsMax : rows}
      className={`input full-width ${fieldName}`}
      error={error !== undefined}
      helperText={error}
      label={label}
      onChange={e => onChange(e.target.value)}
      required={required}
      fullWidth
      variant='filled'
      value={value || ""}
    />
  )
};

export default TextField;
