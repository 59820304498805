import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

interface Props {
  error?: string,
  fieldName: string,
  label?: string,
  onChange?: Function,
  value: string,
  disabled?: boolean
};

const YesNo = (props: Props) => {
  const {
    error,
    fieldName,
    label = "",
    onChange = () => {},
    value,
    disabled
  } = props;
  
  return (
    <FormControl error={error !== undefined} disabled={disabled}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        aria-label={fieldName}
        name={fieldName}
        value={value}
        onChange={(event: any) => onChange(event.target.value)}
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
      </RadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default YesNo;
