import { NewOrganisation as Organisation } from "../types/NewOrganisation";
import { get, getV2,putV2, post } from "./";

export function getOrganisations(): Promise<any> {
  
  return get("/organisations");
  
}

export function getOrganisationsV2(): Promise<Organisation> {
  var response=  getV2<Organisation>(`/recognition`);  
  return response;
}


export function getOrganisationDetailsV2(userId: string): Promise<Organisation> {
  return getOrganisationsV2();
}

export function getOrganisationDetails(userId: string): Promise<any> {
  
  return get(`/organisations/${userId}`);
  
}

export function setOrganisationDetailsV2(userId: string, orgDetails: Organisation): Promise<Organisation> {
  return putV2(`/recognition`, orgDetails);  
}

export function setOrganisationDetails(userId: string, details: Object): Promise<any> {
  return post(`/organisations/${userId}`, details);
}
