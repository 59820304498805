import {
    GET_SUPPORT_DOCUMENT_REQUEST,
    GET_SUPPORT_DOCUMENT_SUCCESS,
    GET_SUPPORT_DOCUMENT_FAILURE
} from '../constants'
import { getSupportSasDownloadUrl } from "../services";

export function getSupportDownload(fileName: string) {
    return async (dispatch: Function) => {
      dispatch({ type: GET_SUPPORT_DOCUMENT_REQUEST });
  
      return getSupportSasDownloadUrl(fileName).then((sasResult: any) => {
        dispatch({ type: GET_SUPPORT_DOCUMENT_SUCCESS, payload: sasResult.uri });
      }).catch(() => {
        dispatch({ type: GET_SUPPORT_DOCUMENT_FAILURE });
      });
    }
  }