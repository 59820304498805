import { ALERT, ALERT_CLEAR, ALERT_DIALOG_CLOSE } from "../constants";

interface Action {
  type: string,
  error?: boolean,
  fieldErrors?: object,
  message?: string
}

interface State {
  error: boolean,
  fieldErrors: object,
  message: string | boolean
};

const initialState: State = { error: false, fieldErrors: {}, message: false };

export function alert(state = initialState, action: Action) {
  
  const { error = false, fieldErrors = {}, message, type } = action;
  
  switch (type) {
  
  case ALERT:
    return { ...state, error, fieldErrors, message };
  case ALERT_CLEAR:
    return initialState;
  case ALERT_DIALOG_CLOSE:
    return { ...state, message: false };
  default:
    return state
  
  }

}
