export const APP_API = process.env.REACT_APP_API || "http://localhost:8082";
export const AD_TENANT = process.env.REACT_APP_AD_TENANT || "OfqualB2C.onmicrosoft.com";
export const AD_APP_ID = process.env.REACT_APP_AD_APP_ID || "5c31d59f-97b4-4530-b569-6a3b2aa4a5be";
export const AD_REDIRECT_URI = process.env.REACT_APP_AD_REDIRECT_URI || "http://localhost:3000";
export const AD_SIGN_IN_POLICY = process.env.REACT_APP_AD_SIGN_IN_POLICY || "B2C_1_signup_dev";
export const AD_PASSWORD_RESET_POLICY = process.env.REACT_APP_AD_PASSWORD_RESET_POLICY || "B2C_1_passwordreset_dev";
export const DISABLE_SSO = false;
export const AD_SCOPE = process.env.REACT_APP_AD_SCOPE || "https://OfqualB2C.onmicrosoft.com/expertsdev/user_impersonation";
export const V2_ORGANISATION_APP_API = process.env.REACT_APP_V2_ORGANISATION_APP_API ||"http://localhost:5002";
export const REF_DATA_API = process.env.REACT_APP_REF_DATA_API || "https://ofq-dev-refdata.azurewebsites.net";
export const AD_INSTANCE = process.env.REACT_APP_AD_INSTANCE || 'https://ofqualb2c.b2clogin.com/tfp/';