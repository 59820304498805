import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";

import { Button, Grid, Typography } from '@material-ui/core';
import { withCookies, Cookies } from "react-cookie";

import {
  clearAlert as clearAlertAction,
  getUserApplication as getUserApplicationAction,
  getOrganisationDetails as getOrganisationDetailsAction,
  submitUserApplicationPage,
  setSupportingDocuments,
  getDownload as getDownloadAction,
  deleteFile as deleteFileAction,
  dismissDownloadError as dismissDownloadErrorAction,
  validatePage as validatePageAction
} from "../../actions";
import {
  APPLICATION_CHARACTERS_MAX,
  APPLICATION_WORDS_MAX,
  APPLICATION_LIMIT_FIELDS,
  APPLICATION_LIMIT_FIELDS_DOUBLE
} from "../../constants";
import {
  IApplication,
  IApplicationState,
  IFieldErrors,
  IOrganisationDetails,
  Application as ApplicationType
} from "../../types";

import ApplicationPages from "../../components/routes/application";
import DownloadErrorDialog from "../../components/attachments/DownloadErrorDialog";
import { getRefData as getRefDataAction } from "../../actions/referenceData";
import { RefDataState } from "../../reducers/referenceData";

interface Props extends RouteComponentProps {
  uploadSupportingDocuments: Function;
  application: IApplication;
  applicationStatus: number;
  downloading: boolean;
  getOrganisationDetails: Function;
  getUserApplication: Function;
  clearAlert: Function;
  fieldErrors: IFieldErrors;
  loading: boolean;
  organisationDetails: IOrganisationDetails;
  savePage: Function;
  sending: boolean;
  uploading: boolean;
  userId: string;
  getDownload: Function;
  downloadUrl: string;
  deleteFile: Function;
  currentApplication: ApplicationType;
  cookies: Cookies;
  downloadError: boolean;
  dismissDownloadError: Function;
  getRefData: Function;
  referenceData: RefDataState;
  validatePage: Function;
}

const PAGE_COOKIE_NAME = "application-page";

class Application extends Component<Props, IApplicationState> {
  
  state: any = {
    currentPage: 0,
    submissionTimestamp: null,
    // application data
    pages: [
      // Page 0
      {
        privacyPolicyConfirmed: false
      },
      // Page 1
      {
        qualificationDetails: null,
        explanationOnRecognised: null,
      },
      // Page 2
      { },
      // Page 3
      {
        criteriaA4: null,
        criteriaA5: null,
        criteriaA6: null
      },
      // Page 4
      {
        criteriaB1: null,
        criteriaB2: null
      },
      // Page 5
      {
        criteriaC1a: null,
        criteriaC1b: null
      },
      // Page 6
      {
        competentD1a: null,
        competentD1b: null,
        competentD1c: null
      },
      // Page 7
      {
        readAndUnderstood: null
      },
      // Page 8
      {
        numberOfDocuments: null,
        section8a: null,
        section8b: null,
        submissionTimestamp: null,
        proposedResponsibleOfficer: null
      }
    ],
    supportingDocuments: [],
  }

  mapPagesToApplication(pages: any[]) {
    const application: ApplicationType = {
      id: this.props?.currentApplication?.id,
      privacyPolicyConfirmed: pages[0]?.privacyPolicyConfirmed,
      qualificationDetails: pages[1]?.qualificationDetails,
      explanationOnRecognised: pages[1]?.explanationOnRecognised,
      criteriaA4: pages[3]?.criteriaA4,
      criteriaA5: pages[3]?.criteriaA5,
      criteriaA6: pages[3]?.criteriaA6,
      criteriaB1: pages[4]?.criteriaB1,
      criteriaB2: pages[4]?.criteriaB2,
      criteriaC1a: pages[5]?.criteriaC1a,
      criteriaC1b: pages[5]?.criteriaC1b,
      competentD1a: pages[6]?.competentD1a,
      competentD1b: pages[6]?.competentD1b,
      competentD1c: pages[6]?.competentD1c,
      readAndUnderstood: pages[7]?.readAndUnderstood,
      numberOfDocuments: pages[8]?.numberOfDocuments,
      section8a: pages[8]?.section8a,
      section8b: pages[8]?.section8b,
      submittedDate: undefined,
      proposedResponsibleOfficer: pages[8]?.proposedResponsibleOfficer
    };

    return application;
  }
  
  async componentDidMount() {
    
    const { getOrganisationDetails, getUserApplication, history, cookies, getRefData } = this.props;
    
    await Promise.all([
      getOrganisationDetails(),
      getUserApplication(),
      getRefData(),
    ]);
    
    const pageCookie = cookies.get(PAGE_COOKIE_NAME);
    if (pageCookie != undefined) {
      const numericalPage = Number(pageCookie);
      history.push(`/apply/${pageCookie}`)
      this.setState({ currentPage: (numericalPage) });
    } else {
      history.push("/apply/0");
      this.setState({ currentPage: 0 });
    }    
  }
  
  componentDidUpdate(prevProps: any, prevState: any) {
    const { application, loading, sending } = this.props;

    if(this.state.currentPage !== prevState.currentPage) {
      window.scrollTo(0, 0);
    }
    
    if (
      (
        (prevProps.loading && !loading) ||
        (prevProps.sending && !sending)
      ) &&
      application && Object.keys(application).length
    ) {
      const { pages, submissionTimestamp } = application;
      const { pages: statePages } = this.state;
      let changeMade = false;
      pages.forEach((page: any, i) => {
        let statePage: any = statePages[i];
        let thisChangeMade = false;
        for (let prop in page) {
          if (statePage.hasOwnProperty(prop) && page[prop] !== statePage[prop]) {
            statePage[prop] = page[prop];
            thisChangeMade = true;
          }
        }
        if (thisChangeMade) {
          statePages[i] = statePage;
          changeMade = true;
        }
      });
      if (
        pages.length === 9 &&
        submissionTimestamp && 
        submissionTimestamp !== statePages[8].submissionTimestamp
      ) {
        changeMade = true;
        statePages[8].submissionTimestamp = submissionTimestamp;
      }
      if (changeMade) {
        this.setState({ pages: statePages, submissionTimestamp: submissionTimestamp || null });
      }
    }
  }
  
  nextPage = (isSubmitting: boolean, isReadonly: boolean) => {
    const { currentPage } = this.state;
    const { history, cookies } = this.props;
    let savePromise = Promise.resolve();
    const apiApplication = this.mapPagesToApplication(this.state.pages);
    this.props.validatePage(currentPage, apiApplication)
      .then(() => {
        if (Object.keys(this.props.fieldErrors).length === 0) {
          if (!isReadonly) {
            // const apiApplication = this.mapPagesToApplication(this.state.pages);
            if (isSubmitting) {
              apiApplication.submittedDate = new Date();
            }
      
            savePromise = this.props.savePage(apiApplication);
          }
          savePromise.then(() => {
            if (
              Object.keys(this.props.fieldErrors).length === 0 &&
              currentPage + 1 < ApplicationPages.length
            ) {
              history.push(`/apply/${currentPage + 1}`);
              this.setState({ currentPage: currentPage + 1 });
              cookies.set(PAGE_COOKIE_NAME, currentPage + 1, {
                sameSite: true,
                expires: moment().add(1, "months").toDate(),
                path: "/"
              });
            }
          });
        }
      });
  }
  
  previousPage = () => {
    const { currentPage } = this.state;
    const { history, fieldErrors, clearAlert, cookies } = this.props;
    if(currentPage - 1 >= 0) {
      history.push(`/apply/${currentPage-1}`);
      this.setState({ currentPage: currentPage - 1 });
      if (Object.keys(fieldErrors).length) {
        clearAlert();
      }
      cookies.set(PAGE_COOKIE_NAME, currentPage - 1, {
        sameSite: true,
        expires: moment().add(1, "months").toDate(),
        path: "/"
      });
    }
  }
  
  updateApplicationState = (pageNumber: number, property: string, value: any) => {
    const { clearAlert, fieldErrors } = this.props;
    let { pages } = this.state;
    let pageData: any = pages[pageNumber];
    if (
      APPLICATION_LIMIT_FIELDS.indexOf(property) > -1 ||
      APPLICATION_LIMIT_FIELDS_DOUBLE.indexOf(property) > -1
    ) {
      let characterLimit = APPLICATION_CHARACTERS_MAX;
      let wordLimit = APPLICATION_WORDS_MAX;
      if (APPLICATION_LIMIT_FIELDS_DOUBLE.indexOf(property) > -1) {
        characterLimit = characterLimit * 2;
        wordLimit = wordLimit * 2;
      }
      let valueString = String(value);
      if (valueString.length > characterLimit) {
        valueString = valueString.substr(0, characterLimit);
      }
      const valueWords = valueString.split(" ");
      if (valueWords.length > wordLimit) {
        valueString = valueWords.slice(0, wordLimit).join(" ");
      }
      value = valueString;
    }
    pageData[property] = value;
    pages[pageNumber] = pageData;
    //const currentApplication = this.mapPagesToApplication(pages);
    this.setState({ pages }, () => {
      if (Object.keys(fieldErrors).length) {
        clearAlert();
      }
    });
  }
  
  render() {
    
    const {
      application,
      clearAlert,
      fieldErrors,
      organisationDetails,
      getDownload,
      downloadUrl,
      deleteFile,
      referenceData,
      sending
    } = this.props;
    
    const { currentPage, pages, submissionTimestamp } = this.state;
    const pageData: any = pages[currentPage];
    
    const PageComponent = ApplicationPages[currentPage];
    const canSubmit = !submissionTimestamp && !sending;
    
    const nextButtonDisabled = !canSubmit && currentPage === ApplicationPages.length - 1;
    
    if(!organisationDetails.allowedToApply) {
      return (
        <div className="application-page">
          <Typography variant="h3">You can not apply until your application process has been approved</Typography>
        </div>
      );
    }

    // todo figure out sticking the buttons to the bottom while spacing stuff out?
    return (
      <div style={{height: '100%'}}>
        {this.props.downloadError === true &&
          <DownloadErrorDialog open={this.props.downloadError} dismiss={this.props.dismissDownloadError} />
        }
        <Grid container spacing={16} direction='column' justify='space-between' alignItems='flex-start'>
          <Grid item>
            <PageComponent
              userId={this.props.userId}
              uploadSupportingDocuments={this.props.uploadSupportingDocuments}
              application={application}
              clearAlert={clearAlert}
              fieldErrors={fieldErrors}
              updateApplicationState={this.updateApplicationState}
              organisationDetails={organisationDetails}
              pageData={pageData}
              readonlyControls={!canSubmit}
              getDownload={getDownload}
              downloadUrl={downloadUrl}
              deleteFile={deleteFile}
              referenceData={referenceData}
            />
          </Grid>
          <Grid item>
            <div className="page-controls"> 
              <Grid container spacing={16} direction='row' justify='flex-end' alignItems='center'>
                {currentPage > 0 && (
                  <Grid item>
                    <Button className="submit-form" color="primary" onClick={() => this.previousPage()} variant="contained">
                      Previous
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    className="submit-form"
                    color="primary"
                    onClick={
                      currentPage < ApplicationPages.length - 1 
                        ? () => this.nextPage(false, !canSubmit)
                        : () => this.nextPage(true, !canSubmit)
                      }
                    variant="contained"
                    disabled={nextButtonDisabled}
                  >
                    {currentPage < ApplicationPages.length - 1 ? (canSubmit ? "Save and continue" : "Next") : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  
}

const mapStateToProps = (state: any) => {

  const { alert, applications, organisations, referenceData } = state;
  const { fieldErrors } = alert;
  const { organisationDetails } = organisations;
  
  return { ...applications, fieldErrors, organisationDetails, referenceData };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const { userId } = ownProps;
  
  return {
    clearAlert: () => dispatch(clearAlertAction()),
    getOrganisationDetails: () => dispatch(getOrganisationDetailsAction(userId)),
    getUserApplication: () => dispatch(getUserApplicationAction()),
    savePage: (application: ApplicationType) => dispatch(submitUserApplicationPage(application)),
    uploadSupportingDocuments: (files: FileList) => dispatch(setSupportingDocuments(userId, files)),
    getDownload: (blobId: string, fileName: string) => dispatch(getDownloadAction(blobId, fileName)),
    deleteFile: (link: string) => dispatch(deleteFileAction(link)),
    dismissDownloadError: () => dispatch(dismissDownloadErrorAction()),
    getRefData: () => dispatch(getRefDataAction()),
    validatePage: (pageNumber: number, application: ApplicationType) => dispatch(validatePageAction(pageNumber, application))
  }
};

const ApplicationWithCookies = withCookies(Application);
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationWithCookies);