import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";

import Home from "@material-ui/icons/HomeRounded";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

import {
  getOrganisations,
  sortOrgsByFollowUp,
  sortOrgsByStatus,
  sortOrgsByAssigned,
  sortOrgsByLastResponse
} from "../../actions";
import { IOrganisation } from "../../types";
import { APPLICATION_STATUS } from "../../constants/applications";

interface SortPropFunction {
  (text: string) : void
}

interface Props {
  allOrganisations: Array<IOrganisation>,
  loading: boolean,
  submitting: boolean,
  getOrganisations: Function,
  sortControls: any,
  sortOrgsByFollowUp: SortPropFunction,
  sortOrgsByStatus: SortPropFunction,
  sortOrgsByAssigned: SortPropFunction,
  sortOrgsByLastResponse: SortPropFunction
}

class Organisations extends Component<Props & RouteComponentProps> {
  
  componentDidMount() {
    this.props.getOrganisations();
  }
  
  renderSortControl = (text: string, onSortCallback: SortPropFunction) => {
    const { sortControls } = this.props;
    
    let sortState = 0;
    if(text in sortControls) {
      sortState = sortControls[text];
    }
    let className = ["sort"];
    if(sortState !== 0) {
      className.push("active");
    }
    
    const arrowUp = <ArrowDropUp className="icon up"/>;
    const arrowDown = <ArrowDropDown className="icon down"/>;
    let sortStateIcon = (
      <React.Fragment>
        {arrowUp}
        {arrowDown}
      </React.Fragment>
    );
    
    if(sortState === 1) {
      sortStateIcon = arrowUp;
    } else if(sortState === 2) {
      sortStateIcon = arrowDown;
    }

    return (
      <div className={className.join(" ")} onClick={() => onSortCallback(text)}>
        {text}
        <div className="sort-state">
          {sortStateIcon}
        </div>
      </div>
    );
  }
  
  renderOrganisationRow = (org : IOrganisation, index: number) => {
    const { history } = this.props;
    const { legalName, acronym, status, assignedTo, lastResponse, user, followUpDate } = org;
    
    let fullName = "NA";
    if(assignedTo) {
      const { givenName, surname } = assignedTo;
      if(givenName && surname) {
        fullName = `${givenName} ${surname}`;
      }
    }
    
    return (
      <tr key={index} className="org-row" onClick={() => history.push(`/details/${user}`, { isStaff : true })}>
        <td colSpan={2}>
          <div className="main-info">
            <div className="icon-wrap">
              <Home className="icon"/>
            </div>
            {`${legalName} ( ${acronym} )`}
          </div>
        </td>
        <td>{followUpDate ? moment(followUpDate, "X").format('MMMM Do YYYY') : "NA"}</td>
        <td>{APPLICATION_STATUS[status]}</td>
        <td>{fullName}</td>
        <td>{lastResponse ? moment(lastResponse).format('MMMM Do YYYY, h:mm:ss a') : "NA"}</td>
      </tr>
    )
  }
  
  render() {
    const {
      allOrganisations,
      sortOrgsByFollowUp,
      sortOrgsByStatus,
      sortOrgsByAssigned,
      sortOrgsByLastResponse,
    } = this.props;
    
    return (
      <div className="page organisations">
        <div className="sort-options">
          <div className="title">Sort By:</div>
          {this.renderSortControl("Follow-up date", sortOrgsByFollowUp)}
          {this.renderSortControl("Status", sortOrgsByStatus)}
          {this.renderSortControl("Assigned To", sortOrgsByAssigned)}
          {this.renderSortControl("Last Response", sortOrgsByLastResponse)}
        </div>
        <table className="organisation-table">
          <thead>
            <tr>
              <th colSpan={2}/>
              <th>Follow-up Date</th>
              <th>Status</th>
              <th>Assigned to</th>
              <th>Last Response</th>
            </tr>
          </thead>
          <tbody>
            {allOrganisations.map(this.renderOrganisationRow)}
          </tbody>
        </table>
      </div>
    );
  }
  
}

function mapStateToProps(state: any) {
  
  const { organisations } = state;
  const {
    organisations : allOrganisations,
    loading,
    submitting,
    sortControls
  } = organisations;
  
  return {
    loading,
    submitting,
    allOrganisations,
    sortControls
  }
}

export default connect(mapStateToProps,
  {
    getOrganisations,
    sortOrgsByFollowUp,
    sortOrgsByStatus,
    sortOrgsByAssigned,
    sortOrgsByLastResponse
  })(Organisations);