import React, { Fragment } from 'react';
import { IUser } from "../types";
import { Grid, Typography, Avatar, CircularProgress } from "@material-ui/core";

interface Props {
    user: IUser | undefined;
    show: boolean;
}

const SideNavWelcome = (props: Props) => {
    const { user, show } = props;

    if (show) {
        return (
            <div id="side-nav-welcome">
                <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
                    {user &&
                        <Fragment>
                            <Grid item key="photo" xs={3} sm={3} >
                                <Avatar style={{ backgroundColor: '#293445' }} alt={`${user.givenName} ${user.surname}`}>
                                    {`${user.givenName.substring(0, 1)}${user.surname.substring(0, 1)}`}
                                </Avatar>
                            </Grid>
                            <Grid item key="details" xs={9} sm={9} style={{wordBreak: 'break-word'}}>
                                <Typography variant="h6" id='side-nav-user-name'>{user.givenName} {user.surname}</Typography>
                            </Grid>
                        </Fragment>
                    }
                    {!user &&
                        <CircularProgress />
                    }
                </Grid>
            </div>
        );
    } else {
        return <span />;
    }
};

export default SideNavWelcome;