import React from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, Grid } from '@material-ui/core';

interface Props {
    show: boolean;
}

const DownloadPreparing = (props: Props) => {
    const { show } = props;

    return (
        <Dialog open={show}>
            <DialogTitle>Preparing your download...</DialogTitle>
            <DialogContent>
                <Grid container spacing={24} justify='center' alignItems='center'>
                    <CircularProgress />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default DownloadPreparing;