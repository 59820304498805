import React from "react";
import { Grid, Typography } from '@material-ui/core';
import {
  APPLICATION_CHARACTERS_MAX,
  APPLICATION_WORDS_MAX
} from "../../../constants";
import { IApplicationProps } from "../../../types";
import { WordCountField } from "../../";

class ApplicationPage4 extends React.Component<IApplicationProps> {

  render() {
    
    const {
      fieldErrors,
      pageData,
      updateApplicationState,
      readonlyControls
    } = this.props;
    
    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>7. Criteria A: Identity, constitution and governance</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>A.4 Organisation and governance</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `A.4 The Applicant is organised and governed in such a way that will 
              provide appropriate support for its activities as a recognised 
              awarding organisation.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` document explains the type of information 
              we expect to see in your response as to how you meet criterion A.4.`
            }</Typography>
            </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.criteriaA4"]}
              fieldName="criteriaA4"
              label="Please explain how you meet Criterion A.4 and list the documents that support this."
              maxCharacters={APPLICATION_CHARACTERS_MAX}
              maxWords={APPLICATION_WORDS_MAX}
              onChange={(value: string) => updateApplicationState(3, "criteriaA4", value)}
              value={pageData["criteriaA4"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>A.5 Conflicts of Interest</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `A.5 The Applicant has in place appropriate arrangements to secure 
              that it will be able to:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <ul>
                <li>{
                  `identify and monitor any Conflict of Interest* which exists in 
                  relation to it manage any Conflict of Interest which relates to it,`
                }</li>
                <li>{
                  `to ensure that the Conflict of Interest does not give rise to 
                  prejudice to Learners or potential Learners or adversely affect:`}
                  <ul>
                    <li>{
                      `the ability of the Applicant to undertake the development, 
                      delivery or award of qualifications in accordance with 
                      Conditions of Recognition`
                    }</li>
                    <li>{
                      `the standards of qualifications which the Applicant may make 
                      available as a recognised awarding organisation, or`
                    }</li>
                    <li>public confidence in qualifications.</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` explains the type of information 
              we expect to see in your response as to how you meet criterion A.5.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              * A Conflict of Interest exists in relation to an Applicant where –<br />
              (A) its interests in any activity undertaken by it, on its behalf, or 
              by a member of its Group have the potential to lead it to act contrary 
              to its interests in the development, delivery and award of 
              qualifications in accordance with its Conditions of Recognition,<br />
              (B) a person who is connected to the development, delivery or award of 
              qualifications by the Applicant has interests in any other activity 
              which have the potential to lead that person to act contrary to his or 
              her interests in that development, delivery or award in accordance 
              with the Applicant’s Conditions of Recognition, or<br />
              (C) an informed and reasonable observer would conclude that either of 
              these situations was the case.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.criteriaA5"]}
              label="Please explain how you meet Criterion A.5 and list the documents that support this."
              fieldName="criteriaA5"
              maxCharacters={APPLICATION_CHARACTERS_MAX}
              maxWords={APPLICATION_WORDS_MAX}
              onChange={(value: string) => updateApplicationState(3, "criteriaA5", value)}
              value={pageData["criteriaA5"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>A.6 Governing Body oversight</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Where the Applicant is not an individual, it has in place appropriate 
              arrangements to ensure that its Governing Body will have continuing 
              oversight of, and be directly accountable for:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <ul>
                <li>{
                  `the performance of its activities as a recognised awarding 
                  organisation, and`
                }</li>
                <li>its compliance with its Conditions of Recognition.</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` explains the type of information 
              we expect to see in your response as to how you meet criterion A.6.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.criteriaA6"]}
              label="Please explain how you meet Criterion A.6 and list the documents that support this."
              fieldName="criteriaA6"
              maxCharacters={APPLICATION_CHARACTERS_MAX}
              maxWords={APPLICATION_WORDS_MAX}
              onChange={(value: string) => updateApplicationState(3, "criteriaA6", value)}
              value={pageData["criteriaA6"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage4;