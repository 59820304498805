import React from 'react';
import { INavLink, IUser } from "../types";
import MuiTheme from "../themes/mui-theme";
import { MuiThemeProvider, createMuiTheme, Typography, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";

interface Props {
    links: Array<INavLink>;
    user: IUser | undefined;
    login: Function;
}

const SideNavLinks = (props: Props) => {
    const { links, user } = props;

    return (
        <div>
            <MuiThemeProvider theme={createMuiTheme(new MuiTheme().getSideNavSubTheme())}>
                <Typography variant="srOnly">Navigation links</Typography>
                <List>
                    { user !== undefined &&
                        links.filter(link => link.show).map((link, ix) => {
                            const NavLink = (internalProps: any) => <li><Link {...internalProps} to={link.url} /></li>
                            if (!!link.url) {
                                return (
                                    <ListItem button key={ix} href={link.url} component={NavLink} id={`sidenav-link-${link.text.replace(' ', '')}`}>
                                        <ListItemIcon>
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={link.text} />
                                    </ListItem>
                                );
                            } else if (!!link.action) {
                                let linkAction: Function = link.action;
                                return (
                                    <ListItem button key={ix} onClick={(e) => linkAction(e)} id={`sidenav-link-${link.text.replace(' ', '')}`}>
                                        <ListItemIcon>
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={link.text} />
                                    </ListItem>
                                );
                            } else {
                                return <span />
                            }
                        })
                    }
                </List>
            </MuiThemeProvider>
        </div>
    );
}

export default SideNavLinks;