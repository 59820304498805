export default class MuiTheme {
    public getViewTheme(): any {
        return {
            typography: {
                useNextVariants: true,
            },
            palette: {
                primary: {
                    light: '#7f5500',
                    main: '#f0ac24',
                    dark: '#ffdf9e',
                    contrastText: '#000',
                },
                secondary: {
                    light: '#535d70',
                    main: '#293445',
                    dark: '#000d1e',
                    contrastText: '#fff',
                },
            },
            overrides: {
                MuiButton: {
                    textPrimary: {
                        color: '#1b57b2',
                        '&:hover': {
                            backgroundColor: '#f9f9f9'
                        }
                    },
        
                },
                MuiFormLabel: {
                    root: {
                        '&$focused': {
                            color: '#1b57b2'
                        }
                    }
                },
                MuiInput: {
                    underline: {
                        '&:after': {
                            borderBottomColor: '#1b57b2'
                        }
                    }
                },
                MuiTabs: {
                    root: {
                        backgroundColor: '#293445',
                        textPrimary: '#fffffff',
                        color: '#ffffff'
                    },
                    indicator: {
                        backgroundColor: '#f0ac24'
                    }
                },
                MuiFormHelperText: {
                    contained: {
                        margin: '8px 0px 4px 0px '
                    }
                },
            }
        }
    }

    public getEditTheme(): any {
        return {
            overrides: {
                MuiTabs: {
                    root: {
                        backgroundColor: '#f0ac24',
                        textPrimary: '#000000',
                        color: '#000000'
                    },
                    indicator: {
                        backgroundColor: '#293445'
                    }
                }
            }
        }
    }

    public getSideNavTheme(): any {
        return {
            typography: {
                useNextVariants: true,
            },
            overrides: {
                MuiTypography: {
                    root: {
                        color: 'white',
                        fontStretch: 'condensed',
                        fontVariantCaps: 'all-small-caps'
                    },
                    subtitle1: {
                        color: 'white',
                    },
                    h6: {
                        color: 'white',
                        fontStretch: 'condensed',
                        fontVariantCaps: 'small-caps'
                    }
                },
                MuiSvgIcon: {
                    root: {
                        fill: 'white',
                    }
                },
                MuiDivider: {
                    root: {
                        backgroundColor: '#535d70',
                    },
                },
                MuiCircularProgress: {
                    circle: {
                        color: 'white'
                    },
                    root: {
                        background: 'none'
                    }
                }
            },
        }
    }

    public getSideNavSubTheme(): any {
        return {
            typography: {
                useNextVariants: true,
                body1: {
                    color: 'white',
                    fontSize: 16,
                    fontStretch: 'condensed',
                    fontVariantCaps: 'all-small-caps'
                }
            },
            overrides: {
                MuiSvgIcon: {
                    root: {
                        fill: 'white',
                        fontSize: 20
                    },
                }
            },
        }
    }
}