import { takeLatest, call, delay, all } from "redux-saga/effects";
import { acquireToken } from "../services/b2c-msal";
import { USER_SUCCESS } from "../constants";


export function* refreshToken() {
    while (true) {
        yield delay(900000);
        yield call(acquireToken, () => {});
    }
}

export default function* saga() {
    yield all([
        takeLatest(USER_SUCCESS, refreshToken)
    ]);
}