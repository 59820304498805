import {
  LOGOUT,
  USER_FAILURE,
  USER_SUCCESS,
  ACCEPT_PRIVACY_POLICY
} from "../constants";
import { IUser } from "../types";

interface State {
  loading: boolean;
  sentCode: boolean;
  user?: IUser
}

const initialState: State = {
  loading: false,
  sentCode: false,
  user: undefined
};

interface Action {
  type: string;
  payload?: Object;
}

export function authentication(state = initialState, action: Action) {
  
  switch (action.type) {
  
  case LOGOUT:
    return { ...initialState };
  case USER_FAILURE:
    return { ...state, loading: false };
  case USER_SUCCESS:
    return { ...state, loading: false, user: action.payload };
  case ACCEPT_PRIVACY_POLICY:
    return { ...state, user: { ...state.user, privacy: true } };
  default:
    return state
  
  }

}
