import {
  GET_RESPONSES_REQUEST,
  GET_RESPONSES_SUCCESS,
  GET_RESPONSES_FAILURE,
  SEND_RESPONSE_REQUEST,
  SEND_RESPONSE_FAILURE,
  SEND_RESPONSE_SUCCESS,
  LOAD_MORE_RESPONSES,
  CHANGE_MESSAGE,
  SET_ATTACHMENTS,
  DOWNLOAD_ATTACHMENT_REQUEST,
  DOWNLOAD_ATTACHMENT_SUCCESS,
  DOWNLOAD_ATTACHMENT_FAILURE
} from "../constants";

import { IResponse, Action } from "../types";

interface State {
  sending: boolean;
  loading: boolean;
  responses: IResponse[];
  showResponseCount: number;
  currentMessage: string;
  currentAttachments: Array<File>;
  isPreparingDownload: boolean;
  downloadUrl: string;
}

const initialState: State = {
  sending: false,
  loading: false,
  responses: [],
  showResponseCount: 5,
  currentMessage: '',
  currentAttachments: [],
  isPreparingDownload: false,
  downloadUrl: ''
}

export function responses(state = initialState, action: Action) {
  switch(action.type) {
    case GET_RESPONSES_REQUEST:
      return { ...state, loading: true }
    case GET_RESPONSES_SUCCESS:
      return { ...state, loading: false, responses: action.payload }
    case GET_RESPONSES_FAILURE:
      return { ...state, loading: false }
    case SEND_RESPONSE_REQUEST:
      return { ...state, sending: true }
    case SEND_RESPONSE_FAILURE:
      return { ...state, sending: false }
    // case RECEIVED_RESPONSE_FROM_SOCKET:{
    //   const { responses } = state;
    //   const newResponses = responses.slice();
    //   newResponses.unshift(action.payload as IResponse);
      
    //   return {
    //     ...state,
    //     sending: false,
    //     responses: newResponses
    //   }
    // }
    case LOAD_MORE_RESPONSES: 
      return { ...state, showResponseCount: state.showResponseCount + 5 }
    case CHANGE_MESSAGE: 
      return { ...state, currentMessage: action.payload }
    case SET_ATTACHMENTS:
      return { ...state, currentAttachments: action.payload }
    case SEND_RESPONSE_SUCCESS:
      return { ...state, sending: false, currentMessage: '', currentAttachments: [] }
    case DOWNLOAD_ATTACHMENT_REQUEST:
      return { ...state, isPreparingDownload: true };
    case DOWNLOAD_ATTACHMENT_FAILURE:
      return { ...state, isPreparingDownload: false };
    case DOWNLOAD_ATTACHMENT_SUCCESS:
      return { ...state, isPreparingDownload: false, downloadUrl: action.payload };
    default:
      return state;
  }
}