import React from "react";
import { Grid, Typography } from '@material-ui/core';

import {
  APPLICATION_CHARACTERS_MAX,
  APPLICATION_WORDS_MAX
} from "../../../constants";
import { IApplicationProps } from "../../../types";
import { WordCountField } from "../../";

class ApplicationPage7 extends React.Component<IApplicationProps> {

  render() {
    
    const {
      fieldErrors,
      pageData,
      updateApplicationState,
      readonlyControls
    } = this.props;
    
    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>10. Criteria D: Competence</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>{
              `D.1(a) it will be able to undertake, in accordance with its 
              Conditions of Recognition, the development, delivery and award of any 
              qualification which it may make available as a recognised awarding 
              organisation`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `The Applicant’s understanding of, commitment to, and approach to the 
              development, delivery and award of qualifications is sufficient, 
              having regard to the need to ensure that:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>  
              <i>{
                `it will be able to undertake, in accordance with its Conditions of 
                Recognition, the development, delivery  and award of any qualification 
                which it may make available as a recognised awarding organisation`
              }</i>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` explains the type of information 
              we would expect to see in your response as to how you meet criteria 
              D.1 (a).`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.competentD1a"]}
              label="Please explain how you meet Criterion D.1(a) and list the documents that support this."
              fieldName="competentD1a"
              maxCharacters={APPLICATION_CHARACTERS_MAX * 2}
              maxWords={APPLICATION_WORDS_MAX * 2}
              onChange={(value: string) => updateApplicationState(6, "competentD1a", value)}
              value={pageData["competentD1a"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>{
              `D.1(b) any such qualification must, as far as possible, be Valid, 
              Reliable, Comparable, Manageable and Minimise Bias`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `The Applicant’s understanding of, commitment to, and approach to the 
              development, delivery and award of qualifications is sufficient, 
              having regard to the need to ensure that:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <i>{
                `any such qualification must, as far as possible, be Valid, Reliable, 
                Comparable, Manageable and Minimise Bias`
              }</i>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` explains the type of information 
              we would expect to see in your response as to how you meet criterion 
              D.1 (b).`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.competentD1b"]}
              label="Please explain how you meet Criterion D.1(b) and list the documents that support this."
              fieldName="competentD1b"
              maxCharacters={APPLICATION_CHARACTERS_MAX * 2}
              maxWords={APPLICATION_WORDS_MAX * 2}
              onChange={(value: string) => updateApplicationState(6, "competentD1b", value)}
              value={pageData["competentD1b"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>{
              `D.1(c) any such qualification must be compatible with the 
              requirements of Equalities Law`
            }</Typography>
          </Grid>
          <Grid item xs={12}> 
            <Typography variant='body1'>{
              `The Applicant’s understanding of, commitment to, and approach to the 
              development, delivery and award of qualifications is sufficient, 
              having regard to the need to ensure that:<br />
              any such qualification must be compatible with the requirements of 
              Equalities Law`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` explains the type of information 
              we would expect to see in your response as to how you meet criterion 
              D.1(c).`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.competentD1c"]}
              label="Please explain how you meet Criterion D.1(c) and list the documents that support this."
              fieldName="competentD1c"
              maxCharacters={APPLICATION_CHARACTERS_MAX * 2}
              maxWords={APPLICATION_WORDS_MAX * 2}
              onChange={(value: string) => updateApplicationState(6, "competentD1c", value)}
              value={pageData["competentD1c"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage7;