import * as Msal from 'msal';
import * as React from 'react';
import { AD_INSTANCE } from '../constants';

export interface AuthConfig {
    instance: string;
    tenant: string;
    signInPolicy: string;
    resetPolicy?: string;
    applicationId: string;
    cacheLocation: 'localStorage' | 'sessionStorage';
    redirectUri: string;
    postLogoutRedirectUri: string;
    scopes: Array<string>;
}

interface B2CAuthState {
    stopLoopingRedirect: boolean;
    launchApp: any;
    accessToken: string | null;
    scopes: Array<string>;
}

const logger = new Msal.Logger(loggerCallback, { level: Msal.LogLevel.Warning });
const DEFAULT_INSTANCE = AD_INSTANCE;

const state: B2CAuthState = {
  stopLoopingRedirect: false,
  launchApp: null,
  accessToken: null,
  scopes: []  
}

let appConfig: AuthConfig = {
  instance: '',
  tenant: '',
  signInPolicy: '',
  resetPolicy: undefined,
  applicationId: '',
  cacheLocation: 'localStorage',
  redirectUri: '',
  postLogoutRedirectUri: '',
  scopes: []
};

function loggerCallback(logLevel: Msal.LogLevel, message: string, piiLoggingEnabled: boolean) {
  if (piiLoggingEnabled) {
    console.log(message);
  }
}

function authCallback(errorDesc: string, token: string, error: string, tokenType: any) {
  if (errorDesc && errorDesc.indexOf('AADB2C90118') > -1) {
    redirect();
  }
  else if (errorDesc) {
    state.stopLoopingRedirect = true;
  } else {
    acquireToken(() => {});
  }  
}

function redirect() {
  const localMsalApp: any = window.msal;
  const instance = appConfig.instance ? appConfig.instance : DEFAULT_INSTANCE;
  localMsalApp.authority = `${instance}${appConfig.tenant}/${appConfig.resetPolicy}`;
  acquireToken(() => {});
}

export function acquireToken(successCallback: Function) {
  const localMsalApp: any = window.msal; 
  const user = localMsalApp.getUser(state.scopes);
  if (!user) {
    localMsalApp.loginRedirect(state.scopes);
  }
  else {
    localMsalApp.acquireTokenSilent(state.scopes).then((accessToken: string) => {
      state.accessToken = accessToken;
      if (state.launchApp) {
        state.launchApp();
      }
      if (successCallback) {
        successCallback();
      }
    }, (error: any) => {
      if (error) {
        localMsalApp.acquireTokenRedirect(state.scopes);
      }
    });
  }
  
}

interface B2CAuthentication {
    initialize: (config: AuthConfig) => void;
    run: (launchApp: any) => void;
    required: (WrappedComponent: React.ComponentType<any>, renderLoading: Function | null) => React.ComponentType<any>;
    signOut: () => void;
    getAccessToken: () => string | null;
}

const authentication: B2CAuthentication = {
  initialize: (config: AuthConfig) => {
    appConfig = config;
    const instance = config.instance ? config.instance : DEFAULT_INSTANCE;
    const authority = `${instance}${config.tenant}/${config.signInPolicy}`;
    let scopes = config.scopes;
    if (!scopes || scopes.length === 0) {
      //console.log('To obtain access tokens you must specify one or more scopes. See https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens');
      state.stopLoopingRedirect = true;
    }  
    state.scopes = scopes;
    
    new Msal.UserAgentApplication(config.applicationId,
      authority,
      authCallback,
      { 
        logger: logger,
        cacheLocation: config.cacheLocation,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        redirectUri: config.redirectUri,
        validateAuthority: false,
      },
    );
  },
  run: (launchApp: any) => {
    let msal: any = window.msal;
    state.launchApp = launchApp; 
    if (!msal.isCallback(window.location.hash) && window.parent === window && !window.opener) {
      if (!state.stopLoopingRedirect) {
        acquireToken(() => {});
      }    
    }
  },
  required: (WrappedComponent: React.ComponentType<any>, renderLoading: Function | null) =>  {
    return class extends React.Component<any, any> {
      constructor(props: any) {
        super(props);
        this.state = {
          signedIn: false,
          error: null,
        };
      }

      componentWillMount() {
        acquireToken(() => {
          this.setState({
            signedIn: true
          });
        });        
      }

      render(): JSX.Element {
        if (this.state.signedIn) {
          return <WrappedComponent {...this.props} />;
        }
        return typeof renderLoading === 'function' ? renderLoading() : null;
      }
    };
  },
  signOut: () => {
      let msal: any = window.msal;
      msal.logout();
  },
  getAccessToken: () => state.accessToken
}

export default authentication;