import fileDownload from "js-file-download";

import { error, alertAction } from "./";
import {
  GET_USER_APPLICATION_REQUEST,
  GET_USER_APPLICATION_SUCCESS,
  GET_USER_APPLICATION_FAILURE,
  SET_USER_APPLICATION_REQUEST,
  SET_USER_APPLICATION_FAILURE,
  SET_USER_APPLICATION_SUCCESS,
  GET_APPLICATION_STATUS_REQUEST,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATION_STATUS_FAILURE,
  SET_APPLICATION_STATUS_REQUEST,
  SET_APPLICATION_STATUS_SUCCESS,
  SET_APPLICATION_STATUS_FAILURE,
  GET_APPLICATION_DOCUMENTS_REQUEST,
  GET_APPLICATION_DOCUMENTS_FAILURE,
  GET_APPLICATION_DOCUMENTS_SUCCESS,
  SET_APPLICATION_DOCUMENTS_REQUEST,
  SET_APPLICATION_DOCUMENTS_SUCCESS,
  SET_APPLICATION_DOCUMENTS_FAILURE,
  UPLOAD_MANAGEMENT_REQUEST,
  UPLOAD_MANAGEMENT_FAILURE,
  UPLOAD_MANAGEMENT_SUCCESS,
  DOWNLOAD_APPLICATION_DOCUMENT_REQUEST,
  DOWNLOAD_APPLICATION_DOCUMENT_SUCCESS,
  DOWNLOAD_APPLICATION_DOCUMENT_FAILURE,
  DISMISS_DOWNLOAD_ERROR,
  DELETE_APPLICATION_DOCUMENT_REQUEST,
  DELETE_APPLICATION_DOCUMENT_SUCCESS,
  DELETE_APPLICATION_DOCUMENT_FAILURE
} from "../constants";

import {
  getUserApplication as getUserApplicationService,
  submitUserApplicationPage as submitUserApplicationPageService,
  uploadSupportingDocuments as uploadSupportingDocumentsService,
  getSupportingDocument as getSupportingDocumentsService,
  getApplicationStatus as getApplicationStatusService,
  setApplicationStatus as setApplicationStatusService,
  uploadManagementFile as uploadManagementFileService,
  deleteFile as deleteFileService,
  getApplicationSupportingDocument
} from "../services";
import { Application, IApplication } from "../types";

const mapApiApplication = (application: Application) => {
  const spaApplication: IApplication = {
    pages: [
      {
        privacyPolicyConfirmed: application?.privacyPolicyConfirmed
      },
      {
        qualificationDetails: application?.qualificationDetails,
        explanationOnRecognised: application?.explanationOnRecognised,
      },
      {},
      {
        criteriaA4: application?.criteriaA4,
        criteriaA5: application?.criteriaA5,
        criteriaA6: application?.criteriaA6,
      },
      {
        criteriaB1: application?.criteriaB1,
        criteriaB2: application?.criteriaB2,
      },
      {
        criteriaC1a: application?.criteriaC1a,
        criteriaC1b: application?.criteriaC1b,
      },
      {
        competentD1a: application?.competentD1a,
        competentD1b: application?.competentD1b,
        competentD1c: application?.competentD1c,
      },
      {
        readAndUnderstood: application.readAndUnderstood,
      },
      {
        numberOfDocuments: application?.numberOfDocuments,
        section8a: application?.section8a,
        section8b: application?.section8b,
        submissionTimestamp: application?.submittedDate ? new Date(application.submittedDate).getTime() : null,
        proposedResponsibleOfficer: application?.proposedResponsibleOfficer
      }
    ],
    submissionTimestamp: application?.submittedDate ? new Date(application.submittedDate).getTime() : undefined,
    supportingDocuments: application?.supportingDocuments || [],
    _id: application?.id
  };

  return { spaApplication, currentApplication: application };
}

export function getUserApplication() {
  
  return async (dispatch : Function) => {
    dispatch({ type: GET_USER_APPLICATION_REQUEST });
    
    return getUserApplicationService().then((payload: Application) => {
      const appDetails = mapApiApplication(payload);
      dispatch({ type: GET_USER_APPLICATION_SUCCESS, payload: appDetails });
      
    }).catch(() => {
      
      dispatch({ type: GET_USER_APPLICATION_FAILURE });
      
    })
    
  }
  
}

export function submitUserApplicationPage(application: Application) {
  
  return async (dispatch : Function, getState: Function) => {
    
    dispatch({ type: SET_USER_APPLICATION_REQUEST });
    
    return submitUserApplicationPageService(application).then((payload: Application) => {
      const appDetails = mapApiApplication(payload);
      
      dispatch({ type: SET_USER_APPLICATION_SUCCESS, payload: appDetails });
      if(application.submittedDate != null) {
        dispatch(alertAction("Application successfully submitted. \nOfqual will be in touch with you shortly. You will receive a notification to log back into the gateway if necessary."));
      }
      
    }).catch(errors => {
      
      dispatch({ type: SET_USER_APPLICATION_FAILURE });
      
      dispatch(error(errors));
      
    });
    
  };
  
}

export function getApplicationStatus(applicationId: string) {
  
  return async (dispatch : Function) => {
    dispatch({ type: GET_APPLICATION_STATUS_REQUEST });
    
    return getApplicationStatusService(applicationId).then((payload: Object) => {
      
      dispatch({ type: GET_APPLICATION_STATUS_SUCCESS, payload });
      
    }).catch(() => {
      
      dispatch({ type: GET_APPLICATION_STATUS_FAILURE });
      
    })
    
  }
  
}

export function setApplicationStatus(applicationId: string, applicationStatus: Object) {
  
  return async (dispatch : Function) => {
    dispatch({ type: SET_APPLICATION_STATUS_REQUEST });
    
    return setApplicationStatusService(applicationId, applicationStatus).then((payload: Object) => {
      
      dispatch({ type: SET_APPLICATION_STATUS_SUCCESS, payload });
      
    }).catch((errors) => {
      
      dispatch({ type: SET_APPLICATION_STATUS_FAILURE });
      dispatch(error(errors));
    })
    
  }
  
}

export function uploadManagementFile(applicationId: string, file: object, documentType: string) {
  
  return async (dispatch: Function) => {
    dispatch({ type: UPLOAD_MANAGEMENT_REQUEST });
    
    return uploadManagementFileService(applicationId, [file], documentType).then((payload: Object) => {
      dispatch({ type: UPLOAD_MANAGEMENT_SUCCESS, payload });
    }).catch((errors) => {
      dispatch({ type: UPLOAD_MANAGEMENT_FAILURE });
      dispatch(error(errors));
    });
  };
  
}

export function getSupportingDocuments(userId: string) {
  
  return async (dispatch : Function) => {
    dispatch({ type: GET_APPLICATION_DOCUMENTS_REQUEST });
    
    return getSupportingDocumentsService(userId).then((payload: Object) => {
      
      dispatch({ type: GET_APPLICATION_DOCUMENTS_SUCCESS, payload });
      
    }).catch(() => {
      
      dispatch({ type: GET_APPLICATION_DOCUMENTS_FAILURE });
      
    })
    
  }
  
}

export function setSupportingDocuments(userId: string, files: FileList) {
  
  return async (dispatch : Function) => {
    dispatch({ type: SET_APPLICATION_DOCUMENTS_REQUEST });
    
    return uploadSupportingDocumentsService(userId, Array.from(files)).then((payload: any) => {
      const appDetails = mapApiApplication(payload);
      
      dispatch({ type: SET_APPLICATION_DOCUMENTS_SUCCESS, payload: appDetails });
      
    }).catch((err) => {
      dispatch({ type: SET_APPLICATION_DOCUMENTS_FAILURE });
      
    })
    
  }
}

export function getDownload(blobId: string, fileName: string) {
  return async (dispatch: Function) => {
    dispatch({ type: DOWNLOAD_APPLICATION_DOCUMENT_REQUEST });

    return getApplicationSupportingDocument(blobId).then((file) => {
      fileDownload(file, fileName);
      dispatch({ type: DOWNLOAD_APPLICATION_DOCUMENT_SUCCESS });
    }).catch(() => {
      dispatch({ type: DOWNLOAD_APPLICATION_DOCUMENT_FAILURE });
    });
    // return getApplicationSasDownloadUrl(link).then((sasResult: any) => {
    //   dispatch({ type: DOWNLOAD_APPLICATION_DOCUMENT_SUCCESS, payload: sasResult.uri });
    // }).catch(() => {
    //   dispatch({ type: DOWNLOAD_APPLICATION_DOCUMENT_FAILURE });
    // });
  }
}

export function dismissDownloadError() {
  return (dispatch: Function) => {
    dispatch({ type: DISMISS_DOWNLOAD_ERROR });
  }
}

export function deleteFile(link: string) {
  return async (dispatch: Function) => {
    dispatch({ type: DELETE_APPLICATION_DOCUMENT_REQUEST });
    return deleteFileService(link).then((result: any) => {
      const appDetails = mapApiApplication(result);
      
      dispatch({ type: DELETE_APPLICATION_DOCUMENT_SUCCESS, payload: appDetails });
    }).catch((err) => {
      dispatch({ type: DELETE_APPLICATION_DOCUMENT_FAILURE });
    })
  }
}

export function validatePage(pageNumber: number, application: Application) {
  return async (dispatch: Function) => {
    return new Promise<void>((resolve, _) => {
      const fieldErrors = runValidationForPage(pageNumber, application);
      if (Object.keys(fieldErrors).length > 0) {
        const payload = { errors: fieldErrors, shouldChangeCase: false };
        dispatch(error(payload));
      }
      resolve();
    })
  }
}

function runValidationForPage(pageNumber: number, application: Application) {
  switch (pageNumber) {
    case 0: 
      return runPage1Validation(application);
    case 1: 
      return runPage2Validation(application);
    case 3:
      return runPage4Validation(application);
    case 4:
      return runPage5Validation(application);
    case 5:
      return runPage6Validation(application);
    case 6:
      return runPage7Validation(application);
    case 7:
      return runPage8Validation(application);
    case 8:
      return runPage9Validation(application);
    default:
      return {};
  }

  function runPage1Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (!application?.privacyPolicyConfirmed) {
      fieldErrors["application.pageData.privacyPolicyConfirmed"] = "You must accept the privacy policy to continue";
    }
    return fieldErrors;
  }

  function runPage2Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (application?.qualificationDetails == null) {
      fieldErrors["application.pageData.qualificationDetails"] = "This field is mandatory";
    }
    if (application?.explanationOnRecognised == null) {
      fieldErrors["application.pageData.explanationOnRecognised"] = "This field is mandatory";
    }
    return fieldErrors;
  }

  function runPage4Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (application?.criteriaA4 == null) {
      fieldErrors["application.pageData.criteriaA4"] = "This field is mandatory";
    }
    if (application?.criteriaA5 == null) {
      fieldErrors["application.pageData.criteriaA5"] = "This field is mandatory";
    }
    if (application?.criteriaA6 == null) {
      fieldErrors["application.pageData.criteriaA6"] = "This field is mandatory";
    }
    return fieldErrors;
  }

  function runPage5Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (application?.criteriaB1 == null) {
      fieldErrors["application.pageData.criteriaB1"] = "Please select a value";
    }
    if (application?.criteriaB2 == null) {
      fieldErrors["application.pageData.criteriaB2"] = "Please select a value";
    }
    return fieldErrors;
  }

  function runPage6Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (application?.criteriaC1a == null) {
      fieldErrors["application.pageData.criteriaC1a"] = "This field is mandatory";
    }
    if (application?.criteriaC1b == null) {
      fieldErrors["application.pageData.criteriaC1b"] = "This field is mandatory";
    }
    return fieldErrors;
  }

  function runPage7Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (application?.competentD1a == null) {
      fieldErrors["application.pageData.competentD1a"] = "This field is mandatory";
    }
    if (application?.competentD1b == null) {
      fieldErrors["application.pageData.competentD1b"] = "This field is mandatory";
    }
    if (application?.competentD1c == null) {
      fieldErrors["application.pageData.competentD1c"] = "This field is mandatory";
    }
    return fieldErrors;
  }

  function runPage8Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (!application?.readAndUnderstood) {
      fieldErrors["application.pageData.readAndUnderstood"] = "You must confirm that you have read and understood the information to continue";
    }
    return fieldErrors;
  }

  function runPage9Validation(application: Application) {
    const fieldErrors: { [key: string]: string } = {};
    if (application?.numberOfDocuments == null) {
      fieldErrors["application.pageData.numberOfDocuments"] = "Please enter the number of documents you are uploading"
    }
    if (application?.section8a == null) {
      fieldErrors["application.pageData.section8a"] = "Please select an option"
    }
    if (application?.section8b == null) {
      fieldErrors["application.pageData.section8b"] = "Please select an option"
    }
    if (application?.proposedResponsibleOfficer == null) {
      fieldErrors["application.pageData.proposedResponsibleOfficer"] = "Please provide the name of your organisation's responsible officer"
    }
    return fieldErrors;
  }
}