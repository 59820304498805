import { set } from "lodash";
import {
  GET_ORGANISATIONS_REQUEST,
  GET_ORGANISATIONS_SUCCESS,
  GET_ORGANISATIONS_FAILURE,
  GET_ORGANISATION_DETAILS_REQUEST,
  GET_ORGANISATION_DETAILS_SUCCESS,
  GET_ORGANISATION_DETAILS_FAILURE,
  SET_ORGANISATION_DETAILS_REQUEST,
  SET_ORGANISATION_DETAILS_SUCCESS,
  SET_ORGANISATION_DETAILS_FAILURE,
  SORT_ORGANISATIONS_BY_FOLLOW_UP,
  SORT_ORGANISATIONS_BY_ASSIGNED,
  SORT_ORGANISATIONS_BY_LAST_RESPONSE,
  SORT_ORGANISATIONS_BY_STATUS,
  UPDATE_ORGANISATION_DETAILS
} from "../constants";

import { Action, IOrganisation, IOrganisationDetails } from "../types";

interface State {
  loading: boolean;
  submitting: boolean;
  organisations: IOrganisation[];
  originalOrganisations: IOrganisation[];
  organisationDetails: IOrganisationDetails;
  sortControls: any;
}

const initialState: State = {
  submitting: false,
  loading: false,
  organisations: [],
  originalOrganisations: [],
  organisationDetails: {
    typeOfLegalEntity: [],
    typeOfLegalEntityOther: undefined,
    tradingName: undefined,
    legalName: undefined,
    acronym: undefined,
    email: undefined,
    website: undefined,
    qualificationType: [],
    qualificationTypeOther: undefined,
    applicantDetails: {
      title: undefined,
      firstName: undefined,
      lastName: undefined,
      roleInOrg: undefined,
      userPhoneNumber: undefined,
      emailAddress: undefined
    },
    address: {
      lineOne: undefined,
      lineTwo: undefined,
      lineThree: undefined,
      lineFour: undefined,
      country: undefined,
      countryId: undefined,
      postcode: undefined,
      phoneNumber: undefined
    },
    responses: [],
    charityNumber: undefined,
    companyNumber: undefined,
    dateOfIncorporation: undefined,
    _id: '',
    id: undefined
  },
  sortControls: {}
};

function sortByStatus(originalOrganisations: IOrganisation[], flip: boolean) {
  
  const newArray = originalOrganisations.slice();
  
  return newArray.sort((a: IOrganisation, b: IOrganisation) => {
    if(flip) {
      const currentA = a;
      a = b;
      b = currentA;
    }
    return a.status - b.status;
  });
  
}

function sortByLastResponse(originalOrganisations: IOrganisation[], flip: boolean) {
  
  const newArray = originalOrganisations.slice();
  
  return newArray.sort((a: IOrganisation, b: IOrganisation) => {
    
    if(flip) {
      const currentA = a;
      a = b;
      b = currentA;
    }
    
    if(a.lastResponse === null && b.lastResponse === null) {
      return 0;
    }
    
    if(a.lastResponse && b.lastResponse) {
      return a.lastResponse - b.lastResponse;
    } else {
      if(a.lastResponse) {
        return -1;
      } else {
        return 1;
      }
    }

  });
  
}

function sortByAssigned(originalOrganisations: IOrganisation[], flip: boolean = false) {
  
  const newArray = originalOrganisations.slice();

  return newArray.sort((a: IOrganisation, b: IOrganisation) => {
    
    if(flip) {
      const currentA = a;
      a = b;
      b = currentA;
    }
    
    if(a.assignedTo === null && b.assignedTo === null) {
      return 0;
    }
    
    if(a.assignedTo && b.assignedTo) {
      return ("" + a.assignedTo).localeCompare(b.assignedTo);
    } else {
      if(a.assignedTo) {
        return -1;
      } else {
        return 1;
      }
    }

  });
  
}

function sortWithFunction(sortFunction: Function, action : Action, state: State) {
  
  const controlName = action.payload as string;
  const { sortControls, originalOrganisations } = state;
  let theseControls = 0;
  if(controlName in sortControls) {
    theseControls = sortControls[controlName];
  }
  
  theseControls++;
  if(theseControls > 2) {
    theseControls = 0;
  }

  if(theseControls === 0) {
    return {
      ...state,
      sortControls: { ...sortControls, [controlName] : theseControls },
      organisations : originalOrganisations.slice()
    }
  } else {
    return {
      ...state,
      sortControls: {
        ...sortControls,
        [controlName] : theseControls
      },
      organisations : sortFunction(originalOrganisations, theseControls === 2)
    }
  }
}

export function organisations(state = initialState, action: Action) {
  switch(action.type) {
    case GET_ORGANISATIONS_REQUEST:
      return { ...state, loading: true }
    case GET_ORGANISATIONS_SUCCESS:
      return { ...state, loading: false, organisations: action.payload, originalOrganisations: action.payload }
    case GET_ORGANISATIONS_FAILURE:
      return { ...state, loading: false }
    case GET_ORGANISATION_DETAILS_REQUEST:
      return { ...state, loading: true }
    case GET_ORGANISATION_DETAILS_SUCCESS:
      return { ...state, loading: false, organisationDetails: { ...action.payload } }
    case GET_ORGANISATION_DETAILS_FAILURE:
      return { ...state, loading: false }
    case SET_ORGANISATION_DETAILS_REQUEST:
      return { ...state, submitting: true }
    case SET_ORGANISATION_DETAILS_SUCCESS:
      return { ...state, submitting: false, organisationDetails: { ...action.payload } }
    case SET_ORGANISATION_DETAILS_FAILURE:
      return { ...state, submitting: false }
    case SORT_ORGANISATIONS_BY_FOLLOW_UP:
      return { ...state }
    case SORT_ORGANISATIONS_BY_ASSIGNED:
      return sortWithFunction(sortByAssigned, action, state);
    case SORT_ORGANISATIONS_BY_LAST_RESPONSE:
      return sortWithFunction(sortByLastResponse, action, state);
    case SORT_ORGANISATIONS_BY_STATUS:
      return sortWithFunction(sortByStatus, action, state);
    case UPDATE_ORGANISATION_DETAILS:
      const { property, value } = action.payload;
      return {
        ...state, organisationDetails:  {
          ...set(state.organisationDetails, property, value)
        }
      }
    default:
      return state;
  }
}