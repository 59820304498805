import { Component } from "react";
import { connect } from "react-redux";

interface Props {
  type: number;
  permissionType: number;
}

class PermissionWrap extends Component<Props> {
  
  render() {
    
    const {
      permissionType,
      type
    } = this.props;

    if (+type !== +permissionType) {
      return null;
    } else {
      return this.props.children;
    }
    
  }
  
}
const mapStateToProps = (state: any) => {
  
  const { authentication } = state;
  const { user } = authentication;
  
  let type = -1;
  if (user) {
    ({ type } = user);
  }
  
  return {
    type
  }
};

export default connect(mapStateToProps,null)(PermissionWrap);