import React, { Component } from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import { clearAlertDialog as clearAlertAction } from "../actions";

interface Props {
  clearAlert: Function,
  error: boolean,
  message: string
};

interface State {
  error: boolean,
  message: string,
  show: boolean
};

class Alert extends Component<Props, State> {
  state = { error: false, message: "", show: false }
  
  componentDidUpdate(prevProps: Props) {
    const { error, message } = this.props;
    
    if (message && message !== prevProps.message) {
      this.setState({ error, message }, () => {
        this.setState({ show: true });
      });
    }
  }
  
  closeAlert = () => {
    this.setState({ show: false }, () => {
      setTimeout(() => {
        this.props.clearAlert();
      }, 300);
    });
  }
  
  render() {
    const { error, message, show } = this.state;
    
    const title = error ? "Error" : "Message";
    
    return (
      <Dialog
        aria-labelledby={title}
        className="alert"
        fullWidth={true}
        maxWidth="sm"
        onClose={this.closeAlert}
        open={show}
      >
        <DialogTitle className="alert-title">{title}</DialogTitle>
        <DialogContent>
          <div className="alert-content">
            <Typography variant='body1'>{message}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeAlert} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  
  const { alert } = state;
  const { error, message } = alert;
  
  return { error, message };
  
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    clearAlert: () => dispatch(clearAlertAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
