import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Button from "@material-ui/core/Button";

import {
  getApplicationStatus,
  setApplicationStatus,
  clearAlert,
  uploadManagementFile
} from "../../../actions";
import { IApplication, IApplicationManagement, IFieldErrors } from "../../../types";
import { Selection, TextField, DatePicker, YesNo, } from "../../../components/form";
import { APPLICATION_STATUS, INTERNAL_APPLICATION_STATUS } from "../../../constants/applications";

interface Props {
  getApplicationStatus: Function,
  setApplicationStatus: Function,
  uploadManagementFile: Function,
  application: IApplication,
  applicationStatus: IApplicationManagement,
  fieldErrors: IFieldErrors,
  clearAlert: Function
}

interface IState extends IApplicationManagement {
}

class ApplicationManagement extends Component<Props, IState> {
  
  constructor(props: Props) {
    super(props);
    this.state = {
      application: null,
      caseNotes: "",
      confidenceRating: 0,
      followUpDate: 0,
      recognitionStatus: 0,
      reviewDocument: null,
      internalMeetingTime: 0,
      internalMeetingOutcome: "",
      reasonForRejection: "",
      internalRecognitionStatus: 0,
      internalMeetingStatus: 0,
      internalReviewRequested: false,
      internalReviewOutcome: "",
      panelMeetingTime: 0,
      panelMeetingNotes: null,
      panelMeetingMinutes: null,
      panelDecision: 0,
      panelConditionsImposed: false,
      panelDecisionDate: 0,
      panelMeetingNotesFeedback: "",
      panelFeedbackTime: 0,
      panelReasonForRejection: "",
      panelInternalReviewRequested: false,
      panelInternalReviewOutcome: 0,
      manager: "",
      recognitionNumber: -1
    }
  }
  
  componentDidMount() {
    const { application, getApplicationStatus } = this.props;
    if (application) {
      getApplicationStatus(application._id).then(() => {
        this.initialiseStateFromProps();
      });
    }
  }
  
  updateField = (property: keyof IState, value: string | number | boolean) => {
    const { clearAlert, fieldErrors } = this.props;
    let newState: any = {};
    newState[property] = value;
    this.setState(newState, () => {
      if (Object.keys(fieldErrors).length) {
        clearAlert();
      }
    });
  }
  
  updateApplicationStatus = () => {

    const { setApplicationStatus, application } = this.props;

    const { application : unused, manager, ...rest } = this.state;

    if(application) {
      setApplicationStatus(application._id, {
        ...rest,
        followUpDate: moment(rest.followUpDate, "X").unix(),
        internalMeetingTime: moment(rest.internalMeetingTime, "X").unix(),
        panelMeetingTime: moment(rest.panelMeetingTime, "X").unix(),
        panelDecisionDate: moment(rest.panelDecisionDate, "X").unix(),
        panelFeedbackTime: moment(rest.panelFeedbackTime, "X").unix()
      });
    }

    
  };
  
  initialiseStateFromProps = () => {
    
    const { applicationStatus } = this.props;
    
    if (applicationStatus && Object.keys(applicationStatus).length){
      
      const {
        followUpDate,
        internalMeetingTime,
        panelMeetingTime,
        panelDecisionDate,
        panelFeedbackTime
      } = applicationStatus;
      
      this.setState({
        ...applicationStatus,
        followUpDate: followUpDate === 0 ? moment(Date.now()).unix() : followUpDate,
        internalMeetingTime: internalMeetingTime === 0 ? moment(Date.now()).unix() : internalMeetingTime,
        panelMeetingTime: panelMeetingTime === 0 ? moment(Date.now()).unix() : panelMeetingTime,
        panelDecisionDate: panelDecisionDate === 0 ? moment(Date.now()).unix() : panelDecisionDate,
        panelFeedbackTime: panelFeedbackTime === 0 ? moment(Date.now()).unix() : panelFeedbackTime
      });
    }
    
  }
  
  render() {
    const { fieldErrors } = this.props;

    return (
      <div className="form-wrap application-management">
        <div className="form-content">
          <Selection
            error={fieldErrors["recognitionStatus"]}
            fieldName="recognitionStatus"
            fullWidth={true}
            label="EXTERNAL application status"
            options={APPLICATION_STATUS}
            onChange={(value: any) => this.updateField("recognitionStatus", value)}
            value={this.state.recognitionStatus}
          />
          <h3>Engagement</h3>
          <TextField
            error={fieldErrors["caseNotes"]}
            multiLine={true}
            fieldName="caseNotes"
            label="Case Notes:"
            value={this.state.caseNotes}
            onChange={(value: string) => this.updateField("caseNotes", value)}
          />
          <TextField
            error={fieldErrors["confidenceRating"]}
            fieldName="confidenceRating"
            label="Confidence Rating"
            value={this.state.confidenceRating.toString()}
            onChange={(value: string) => this.updateField("confidenceRating", value)}
          />
          <h4>Upload documents</h4>
          <DatePicker
            fullWidth={true}
            label="Follow-up Date"
            error={fieldErrors["followUpDate"]}
            fieldName="followUpDate"
            value={this.state.followUpDate}
            onChange={(values: any) => this.updateField("followUpDate", values)}
          />
          <h3>Recognition</h3>
          <Selection
            error={fieldErrors["internalRecognitionStatus"]}
            fieldName="internalRecognitionStatus"
            fullWidth={true}
            label="INTERNAL application status"
            options={INTERNAL_APPLICATION_STATUS}
            onChange={(value: any) => this.updateField("internalRecognitionStatus", value)}
            value={this.state.internalRecognitionStatus}
          />
          {/* <div className="supporting-documents">
            <b>Download application and supporting documents</b>
            <div className="document-links">
              {application && application.supportingDocuments.map((file, i) => <DownloadLink key={i} file={file}/>)}
            </div>
          </div> */}
          {/* <Upload
            value={this.state.reviewDocument ? this.state.reviewDocument[0] : null}
            label="Upload application review Document"
            uploadAction={(files: any) => uploadManagementFile(applicationId, files, "reviewDocument")}
          /> */}
          <DatePicker
            fullWidth={true}
            label="Date and time of internal meeting"
            error={fieldErrors["internalMeetingTime"]}
            fieldName="internalMeetingTime"
            value={this.state.internalMeetingTime}
            onChange={(values: any) => this.updateField("internalMeetingTime", values)}
          />
          <Selection
            error={fieldErrors["internalMeetingOutcome"]}
            fieldName="internalMeetingOutcome"
            fullWidth={true}
            label="Internal meeting outcome"
            options={["", "Reject", "Invite to external panel"]}
            onChange={(value: any) => this.updateField("internalMeetingOutcome", value)}
            value={this.state.internalMeetingOutcome}
          />
          <Selection
            error={fieldErrors["reasonForRejection"]}
            fieldName="reasonForRejection"
            fullWidth={true}
            label="If reject, please choose reason"
            options={["", "Reason 1", "Reason 2", "Reason 3"]}
            onChange={(value: any) => this.updateField("reasonForRejection", value)}
            value={this.state.reasonForRejection}
          />
          <YesNo
            error={fieldErrors["internalReviewRequested"]}
            label="Internal review requested"
            fieldName="internalReviewRequested"
            onChange={(value: string) => this.updateField("internalReviewRequested", value)}
            value={this.state.internalReviewRequested.toString()}
          />
          <Selection
            error={fieldErrors["internalReviewOutcome"]}
            fieldName="internalReviewOutcome"
            fullWidth={true}
            label="Internal Review Outcome"
            options={["", "Outcome 1", "Outcome 2", "Outcome 3"]}
            onChange={(value: any) => this.updateField("internalReviewOutcome", value)}
            value={this.state.internalReviewOutcome}
          />
          <DatePicker
            fullWidth={true}
            label="Panel meeting date and time"
            error={fieldErrors["panelMeetingTime"]}
            fieldName="panelMeetingTime"
            value={this.state.panelMeetingTime}
            onChange={(values: any) => this.updateField("panelMeetingTime", values)}
          />
          {/* <Upload
            value={this.state.panelMeetingNotes ? this.state.panelMeetingNotes[0] : null}
            label="Upload Panel meeting internal notes"
            uploadAction={(files: any) => uploadManagementFile(applicationId, files, "panelMeetingNotes")}
          />
          <Upload
            value={this.state.panelMeetingMinutes ? this.state.panelMeetingMinutes[0] : null}
            label="Upload Panel meeting minutes"
            uploadAction={(files: any) => uploadManagementFile(applicationId, files, "panelMeetingMinutes")}
          /> */}
          <Selection
            error={fieldErrors["panelDecision"]}
            fieldName="panelDecision"
            fullWidth={true}
            label="Panel Decision"
            options={["", "Recognised", "Recognised with special conditions", "Rejected"]}
            onChange={(value: any) => this.updateField("panelDecision", value)}
            value={this.state.panelDecision}
          />
          <TextField
            error={fieldErrors["recognitionNumber"]}
            multiLine={true}
            fieldName="recognitionNumber"
            label="Recognition Number"
            value={this.state.recognitionNumber.toString()}
            onChange={(value: string) => this.updateField("recognitionNumber", value)}
          />
          <YesNo
            error={fieldErrors["panelConditionsImposed"]}
            label="Special Conditions imposed"
            fieldName="panelConditionsImposed"
            onChange={(value: string) => this.updateField("panelConditionsImposed", value)}
            value={this.state.panelConditionsImposed.toString()}
          />
          <DatePicker
            fullWidth={true}
            label="Date of decision letter"
            error={fieldErrors["panelDecisionDate"]}
            fieldName="panelDecisionDate"
            value={this.state.panelDecisionDate}
            onChange={(values: any) => this.updateField("panelDecisionDate", values)}
          />
          <Selection
            error={fieldErrors["panelReasonForRejection"]}
            fieldName="panelReasonForRejection"
            fullWidth={true}
            label="Reason for rejection (if applicable)"
            options={["","Reason 1", "Reason 2", "Reason 3"]}
            onChange={(value: any) => this.updateField("panelReasonForRejection", value)}
            value={this.state.panelReasonForRejection}
          />
          <TextField
            error={fieldErrors["panelMeetingNotesFeedback"]}
            multiLine={true}
            fieldName="panelMeetingNotesFeedback"
            label="Feedback meeting notes (if applicable)"
            value={this.state.panelMeetingNotesFeedback}
            onChange={(value: string) => this.updateField("panelMeetingNotesFeedback", value)}
          />
          <DatePicker
            fullWidth={true}
            label="Feedback meeting date and time"
            error={fieldErrors["panelFeedbackTime"]}
            fieldName="panelFeedbackTime"
            value={this.state.panelFeedbackTime}
            onChange={(values: any) => this.updateField("panelFeedbackTime", values)}
          />
          <YesNo
            error={fieldErrors["panelInternalReviewRequested"]}
            label="Internal review requested"
            fieldName="panelInternalReviewRequested"
            onChange={(value: string) => this.updateField("panelInternalReviewRequested", value)}
            value={this.state.panelInternalReviewRequested.toString()}
          />
          <Selection
            error={fieldErrors["panelInternalReviewOutcome"]}
            fieldName="panelInternalReviewOutcome"
            fullWidth={true}
            label="Internal Review Outcome"
            options={["","Outcome 1", "Outcome 2", "Outcome 3"]}
            onChange={(value: any) => this.updateField("panelInternalReviewOutcome", value)}
            value={this.state.panelInternalReviewOutcome}
          />
          <Button
            className="submit-form"
            color="primary"
            variant="contained"
            onClick={this.updateApplicationStatus}
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
  
}

function mapStateToProps(state: any) {
  const {
    applications,
    alert
  } = state;
  
  const { fieldErrors } = alert;
  const { applicationStatus } = applications;
  
  return {
    applicationStatus,
    fieldErrors
  };
}

export default connect(mapStateToProps, {
  getApplicationStatus, setApplicationStatus, clearAlert, uploadManagementFile
})(ApplicationManagement);