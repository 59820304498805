import { ROLES } from "../constants";
import {
  Application,
  OrganisationDetails,
  Organisations,
  SupportAndGuidance
} from "../containers/routes";

export default [
  {
    component: OrganisationDetails,
    path: "/details/:userId",
    permissions: [ROLES.RECOGNITION_STAFF],
    exact: true
  },
  {
    component: Organisations,
    path: "/organisations",
    permissions: [ROLES.RECOGNITION_STAFF],
    title: "Organisations"
  },
  {
    component: SupportAndGuidance,
    path: "/support",
    permissions: [ROLES.RECOGNITION_APPLICANT],
    title: "Support & Guidance"
  },
  {
    component: Application,
    path: "/apply/",
    permissions: [ROLES.RECOGNITION_APPLICANT],
    title: "Apply"
  },
  {
    component: OrganisationDetails,
    path: "/",
    permissions: [ROLES.RECOGNITION_APPLICANT],
    title: "Organisation Details"
  }
];