import { IResponse } from "../types";
import { getV2, getBlobV2, postFormV2 } from "./";

export function getResponses(): Promise<IResponse[]> {
  
  return getV2(`/recognition/responses`);
  
}

// export async function uploadAttachments(orgId: string, files: Array<object>): Promise<any> {
  
//   return upload(`/responses/${orgId}`, files);
  
// }

// export async function uploadAttachments2(orgId: string, files: Array<File>) {
//   let getNewFileName = (fileName: string) => {
//     let datestamp = moment().format("[-]YYYYMMDD[_]HHMMSS");
//     let lastindex = fileName.lastIndexOf(".");
//     return fileName.substr(0, lastindex) + datestamp + fileName.substr(lastindex);
//   }

//   let tokenPromises = 
//     files
//       .map((file: File) => ({ file: file, fileName: getNewFileName(file.name)}))
//       .map((fileObj: any) => {
//         return new Promise<any>(
//           (resolve, reject) => {
//             get("/responses/upload-token", { orgId: orgId, fileName: fileObj.fileName }).then((response: any) => {
//               resolve({ response: response, file: fileObj.file, fileName: fileObj.fileName });
//             });
//           });
//       });

//   let results = await Promise.all(tokenPromises);
//   let credential = new AnonymousCredential();
//   let pipeline = StorageURL.newPipeline(credential);

//   let uploadPromises = results.map((r: any) => {
//     let fileUrl = new FileURL(r.response.uri, pipeline);
//     return new Promise<{ link: string, fileName: string }>((resolve) => {
//       uploadBrowserDataToAzureFile(Aborter.none, r.file, fileUrl).then(() => {
//         resolve({ link: `{portalservices}/recognition/${orgId}/${r.fileName}`, fileName: r.fileName });
//       });
//     });
//   });

//   return Promise.all(uploadPromises);
// }

// export async function getSasDownloadUrl(link: string): Promise<any> {
//   const splitLink = link.split('/');
//   const fileName = splitLink.pop();
//   const orgId = splitLink.pop();

//   return get(`/responses/download/${orgId}/${fileName}`);
// }

export async function sendResponse(payload: FormData): Promise<IResponse> {
  
  return postFormV2(`/recognition/responses`, payload);

}

export async function downloadFile(id: string, blobId: string): Promise<Blob> {
  return getBlobV2(`/recognition/responses/${id}/files/${blobId}`);
}