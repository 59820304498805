import React from "react";
import { Grid, Typography } from '@material-ui/core';

import { IApplicationProps } from "../../../types";
import { Checkbox } from "../../";

class ApplicationPage8 extends React.Component<IApplicationProps> {

  render() {
    
    const {
      fieldErrors,
      pageData,
      updateApplicationState,
      readonlyControls
    } = this.props;
    
    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>11. Conditions of application</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Freedom of information</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `The Office of Qualifications and Examinations Regulation ("Ofqual") 
              is subject to the requirements of the Freedom of Information Act 2000 
              ("FOIA") and the Environmental Information Regulations and we may be 
              required to disclose information provided by you to us as part of your 
              application for recognition.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `In line with FOIA, we shall be responsible for determining in our 
              absolute discretion whether any information is exempt from disclosure 
              in accordance with the provisions of the FOIA or the Environmental 
              Information Regulations.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `In the ordinary course of business, we will notify you prior to 
              disclosing information which directly relates to you under the FOIA 
              and permit you a reasonable period of time to make representations in 
              respect of such disclosures. Notwithstanding the foregoing, you 
              acknowledge that we may, acting in accordance with the Section 45 
              Code of Practice on the discharge of public authorities' functions 
              under Part 1 of FOIA and guidance issued by the Information 
              Commissioner, be obliged under the FOIA or the Environmental 
              Information Regulations to disclose information: without consulting 
              you, or following consultation with you and having taken your views 
              into account.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `You acknowledge that any material you send or supply to Ofqual will 
              be considered non-confidential or as outlined further below.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Data protection</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Ofqual processes personal data in accordance with the General Data Protection Regulation (GDPR) and Data Protection Act 2018 “together Data Protection legislation”. 
              The legal basis we rely upon to process personal data in your Recognition Application is Article 6(1)(e) of the GDPR, 
              which allows us to process personal data when this is necessary to perform our public tasks as a regulator.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Why we process your personal data (the legal basis)</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Ofqual’s recognition process requires you to provide personal data (for example contact details and proposed Responsible Officer details) to allow us to perform our public tasks as a regulator.
              `
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>We will use the information you provide to us in your application to perform our regulatory functions, in particular:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <ul>
                <li>determining your application for recognition, and</li>
                <li>{
                  `monitoring any future compliance with the conditions of recognition 
                  in relation to which that information is relevant.`
                }</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>What personal data we process</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Ofqual collects a range of information about you, and potentially others employed by your organisation, when you apply to become a recognised awarding organisation. 
              This includes: `
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <ul>
                <li>{
                  `Your name, address and contact details, including email address and telephone number. `
                }</li>
                <li>{
                  `Other personal details you provide, 
                  such as name, address, contact details, and details within CVs of anyone that you have decided
                  it is important for us to know about as part of your application`
                }</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Ofqual collects this information using our online application form and any communication you have with us via the Ofqual Gateway.
               Your information will be stored on our online application system, in our secure document management system and our email system.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Who we share your personal data with</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Your personal data will be shared internally for the purposes of the recognition process and if required, subsequent regulatory monitoring by Ofqual to ensure continued compliance with the Conditions of Recognition. 
              This includes members of staff involved in the review and evaluation of your application and those who administer the recognition application process.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `As part of the application, Ofqual staff will contact you to provide the outcome of your application, to arrange meetings, and/or to request further information from you.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `We will not disclose any personal data supplied to us as part of the recognition process to a third party, except to the following third parties who may access and use the information:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <ul>
                <li>{
                  `Other public authorities in order to further Ofqual’s qualifications functions, for example, 
                  the Institute for Apprenticeships and Technical Education and the Education and Skills Funding Agency;`
                }</li>
                <li>{
                  `Other qualifications regulators in the United Kingdom (where it is necessary for those regulators to have information in the course of carrying out their regulatory functions);`
                }</li>
                <li>{
                  `Ofqual’s data processors who are third parties who provide services for us. We have contracts in place with our data processors. 
                  This means that they cannot do anything with personal information unless we have instructed them to do it. 
                  They will not share your personal information with any organisation apart from us. 
                  They will hold it securely and retain it for the period we instruct;`
                }</li>
                <li>{
                  `The police, fraud prevention agencies or other law enforcement agencies (to investigate or prevent crime, including fraud);`
                }</li>
                <li>{
                  `The courts (in connection with court proceedings);`
                }</li>
                <li>{
                  `In circumstances we are legally obliged to share information. For example under a court order;`
                }</li>
                <li>{
                  `Another third party where we satisfy ourselves that we have a lawful basis on which to share the information.`
                }</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `In any scenario, we will satisfy ourselves that we have a lawful basis on which to share the information.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>How long we keep your personal data for</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Your personal data will be stored in our online application system, in our secure document management system and our email system.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `If recognised, we will hold the application form and all documents submitted for recognition, our decision documents, 
              and Ofqual reports for the life of the awarding organisation plus 12 months.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `If rejected, we will hold the application form submitted for recognition, our decision documents and Ofqual reports for 10 years following rejection. 
              Documents submitted by the applicant will be deleted after two years of the decision. 
              This is to allow sufficient time for you to reapply.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Ofqual will monitor incomplete applications and dormant accounts in the Ofqual Gateway. 
              We will contact the named individual through the Ofqual Gateway to ask whether you/they still need the account. 
              If you/they no longer need it, or we receive no response, we will delete all personal data from the account.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Your rights</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Under data protection legislation you have a number of rights in relation to your personal data. You can:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <ul>
                <li>{
                  `Access and obtain a copy of your data on request.`
                }</li>
                <li>{
                  `Require Ofqual to change incorrect or incomplete data`
                }</li>
                <li>{
                  `Require Ofqual to delete or stop processing your data, for example where the data is no longer necessary for the purposes of processing. `
                }</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `However, due to the purposes for which Ofqual may be processing your personal data, we may not be able to comply with some requests due to our legal or contractual obligations. `
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `You are under no statutory or contractual obligation to provide data to Ofqual during the application process. 
              However, if you do not provide the information, Ofqual will not be able to process your application properly or at all.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>How Ofqual protects your personal data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `All Ofqual staff are required to respect the personal data and privacy of others and must ensure that appropriate protection and security measures are taken against unlawful or unauthorised processing of personal data. 
              This includes controls to protect against the accidental loss of, or damage to personal data and to ensure only authorised access to personal data.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Data protection legislation includes provisions that promote accountability, good information governance and data security. 
              Ofqual has technical, procedural, administrative controls and processes in place to make sure your personal data is protected; 
              these are supported and underpinned by a number of information assurance and security policies and guidance. 
              For more information, please contact Ofqual’s Data Protection Officer (contact details given below).`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              For further information on how we handle personal data and subject rights please see Ofqual’s Personal Information Charter and Data Protection Policy <a href="https://www.gov.uk/government/organisations/ofqual/about/personal-information-charter" target="_blank" rel="noopener noreferrer">here.</a> 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Contact information</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `You can contact Ofqual at:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Ofqual`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Earlsdon Park`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `53-55 Butts Road`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Coventry`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `CV1 3BH`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>Email: <a href="mailto:public.enquiries@ofqual.gov.uk">public.enquiries@ofqual.gov.uk</a></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Telephone: 0300 303 3344`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>You can contact Ofqual’s Data Protection Officer in writing at the address above or by email at <a href="mailto:dp.requests@ofqual.gov.uk">dp.requests@ofqual.gov.uk</a></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Making a complaint</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `If you believe that Ofqual has not complied with your data protection rights, please contact Ofqual’s Data Protection Officer in the first instance.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>Or if you wish to make a formal complaint to Ofqual about how we process your personal data, please visit our complaints procedure page <a href="https://www.gov.uk/government/organisations/ofqual/about/complaints-procedure" target="_blank" rel="noopener noreferrer">here.</a></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `You also have the right to make a complaint to the Information Commissioner’s Office (ICO) at:`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `The Information Commissioner`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Wycliffe House`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Water Lane`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Wilmslow`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Cheshire`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `CV1 3BH`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Telephone: 0303 123 1113`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'><a href="https://www.ico.org.uk" target="_blank" rel="noopener noreferrer">www.ico.org.uk</a></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Confidentiality</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Subject to the Data Protection and Commercial sensitivity sections, 
              by submitting your application for recognition, you acknowledge that 
              any material you provide to us will be considered non-confidential 
              and we have the right to use, copy, distribute and disclose to third 
              parties, as stated above.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Commercial sensitivity</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `You can indicate that you reasonably believe information to be commercially sensitive by marking the information as 'commercially sensitive information' 
              at the time of submission to Ofqual. By giving this indication, 
              this alerts us to the potential sensitivity of this information and we will consult you prior to any disclosure of this information, 
              including in response to a request under FOIA or the Environmental Information Regulations and will, to the extent permitted by law, 
              give you a reasonable amount of time to make representations in respect of such disclosure. Such representations shall be taken into account prior to such disclosure.
              If it is apparent to us, acting reasonably, that the information could not be considered sensitive, 
              then we are entitled to disclose this information in accordance with the Freedom of information and Confidentiality sections of these conditions. 
              In the event of Ofqual receiving an information request, pending, withdrawn and rejected applications shall be deemed to be confidential information and, 
              subject to the Freedom of Information and Acknowledgement sections, we shall not disclose this information to third parties.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Acknowledgement</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `Subject to the Data Protection sections and notwithstanding the 
              Confidentiality and Commercial sensitivity sections you acknowledge 
              that we shall be entitled to disclose your confidential information 
              to any Crown Body, government agency, regulatory or public authority 
              or contracting authority (as defined in Regulation 3 of the Public 
              Contracts Regulations 2006) ("Authorities") or otherwise as 
              required to fulfil our statutory remit (as more particularly 
              described in the Apprenticeships, Skills, Children and Learning Act 
              2009). All Authorities receiving any confidential information shall 
              be entitled to further disclose such confidential information to 
              other Authorities on the basis that such confidential information is 
              confidential and is not to be disclosed to any third party.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Intellectual property</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{
              `You warrant that the material you have submitted does not breach a 
              third party's copyright or other intellectual property rights; the 
              Data Protection legislation; or any other law, contractual or other 
              duty to which you are subject.`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              Please indicate that you understand the above information. *
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={readonlyControls}
              error={fieldErrors["application.pageData.readAndUnderstood"]}
              fieldName="readAndUnderstood"
              label="I have read and understand the information above"
              onChange={(value: boolean) => updateApplicationState(7, "readAndUnderstood", value)}
              value={pageData.readAndUnderstood || false}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage8;