import React from "react";
import { Grid, Typography } from '@material-ui/core';

import {
  APPLICATION_CHARACTERS_MAX,
  APPLICATION_WORDS_MAX
} from "../../../constants";
import { IApplicationProps } from "../../../types";
import { WordCountField } from "../../";

class ApplicationPage6 extends React.Component<IApplicationProps> {

  render() {
    
    const {
      fieldErrors,
      pageData,
      updateApplicationState,
      readonlyControls
    } = this.props;
    
    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>9. Criteria C: Resources and financing</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>C.1 (a) Systems, processes and resources</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              The Applicant has, or has appropriate arrangements in place to 
              ensure that it will have:<br />
              the systems, processes and resources, to enable it to undertake, in 
              accordance with its Conditions of Recognition, the development, 
              delivery and award of any qualification which it may make available 
              as a recognised awarding organisation.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` explains the type of information 
              we would expect to see in your response as to how you meet criterion 
              C.1 (a).`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.criteriaC1a"]}
              label="Please explain how you meet Criterion C.1(a) and list the documents that support this."
              fieldName="criteriaC1a"
              maxCharacters={APPLICATION_CHARACTERS_MAX * 2}
              maxWords={APPLICATION_WORDS_MAX * 2}
              onChange={(value: string) => updateApplicationState(5, "criteriaC1a", value)}
              value={pageData["criteriaC1a"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>C.1 (b) Financial resources and facilities</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              The Applicant has, or has appropriate arrangements in place to 
              ensure that it will have<br />
              the financial resources and facilities to enable it to undertake, in 
              accordance with its Conditions of Recognition, the development, 
              delivery and award of any qualification which it may make available 
              as a recognised awarding organisation.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The <a href="https://www.gov.uk/government/publications/application-for-recognition-supporting-information/supporting-information-application-for-recognition" target="_blank" rel="noopener noreferrer">supporting information document</a>{
              ` explains the type of information 
              we would expect to see in your response as to how you meet criterion 
              C.1 (b).`
            }</Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.criteriaC1b"]}
              label="Please explain how you meet Criterion C.1(b) and list the documents that support this."
              fieldName="criteriaC1b"
              maxCharacters={APPLICATION_CHARACTERS_MAX}
              maxWords={APPLICATION_WORDS_MAX}
              onChange={(value: string) => updateApplicationState(5, "criteriaC1b", value)}
              value={pageData["criteriaC1b"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage6;