import React, { Component } from "react";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import {NewOrganisation as OrganisationModel} from "../../../types/NewOrganisation"
import {IdLabel} from "../../../types/referenceData";
import { Typography, Paper, Grid, Button, InputLabel, CircularProgress, TextField, FormControl, FilledInput, Chip, Select, MenuItem, Divider, Tabs, Tab, FormLabel, RadioGroup, Radio } from "@material-ui/core";
import {
  getOrganisationDetails,
  setOrganisationDetails,
  clearAlert,
  updateOrganisationDetails,
  acceptPrivacyPolicy,
} from "../../../actions";
import { getRefData } from "../../../actions/referenceData";

import { IFieldErrors, IUser } from "../../../types";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DatePicker } from "../../../components/form";
import PrivacyDialog from "../../../components/PrivacyDialog"
// import { Responses } from ".";
import ResponsesList from '../../responses/ResponsesList';
import { QuestionAnswer } from "@material-ui/icons";
import moment from "moment";
import { RefDataState } from "../../../reducers/referenceData";
import { Country, LegalEntityType, Title } from "../../../types/ref-data";

interface Props {
  userId: string | null,
  getOrganisationDetails: Function,
  setOrganisationDetails: Function,
  updateOrganisationDetails: Function,
  acceptPrivacyPolicy: Function,
  detailsLoading: boolean,
  detailsSubmitting: boolean,
  fieldErrors: IFieldErrors,
  clearAlert: Function,
  organisationDetails: any,
  applicationStatus: number,
  user: IUser | undefined
  application: any,
  cookies: Cookies
  applicationSubmitted: boolean;
  getRefData: Function;
  referenceData: RefDataState;
}

interface OrganisationState {
  countries: IdLabel[];
  titles: IdLabel[];
  legalEntityTypes: IdLabel[];
  showInvalidDialog: boolean;
}

const PRIVACY_POLICY_COOKIE = "ofqual-recognition-privacy-policy-accepted";
const COOKIE_CONSENT = "ofqual-recognition-cookie-consent";

class Organisation extends Component<Props, OrganisationState> {
  
  constructor(props: any) {
    super(props)
    this.state = {
      countries: [],
      titles: [],
      legalEntityTypes: [],
      showInvalidDialog: false
    }
  }

  componentDidMount() {

    this.props.getOrganisationDetails(this.props.userId, true);
    this.props.getRefData();

  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.referenceData && this.props.referenceData !== prevProps.referenceData) {
      this.setState({
        countries: this.getCountries(this.props.referenceData?.countries ?? []),
        titles: this.getTitles(this.props.referenceData?.titles ?? []),
        legalEntityTypes: this.getLegalEntityTypes(this.props.referenceData?.legalEntityTypes ?? [])
      });
    }
  }
  
  renderTextField = (errorProperty: string, property: string, value: any, label: string, 
    disabled: boolean = false, required: boolean = false) => {
    
    const {
      fieldErrors
    } = this.props;
    
    return (
      <TextField
        name={property}
        error={!!fieldErrors[errorProperty]}
        helperText={fieldErrors[errorProperty]}
        label={label}
        onChange={(e: any) => this.updateField(property, e.target.value)}
        value={value}
        disabled={disabled}
        fullWidth
        required={required}
        variant='filled'
        id={`org-text-field-${property}`}
        InputLabelProps={{
          shrink: !!value
        }}
      />
    );
  };

  renderCheckbox = (errorProperty: string, property: string, value: boolean, label: string) => {    
    const boolValue = value===undefined? false: value;
    return (

      <FormControl >
      <FormControlLabel
        control={
          <MaterialCheckbox
            checked={boolValue}
            onChange={event => this.updateField(property, event.target.checked)}
            value={property}            
          />
        }
        label={label}
      />
      {/* <FormHelperText>{error}</FormHelperText> */}
    </FormControl>      
    );
  };

  renderYesNoRadio = (property: string, value: boolean | undefined | null, label: string, required: boolean, disabled: boolean) => {
    const {
      fieldErrors
    } = this.props;
    
    const getValue = (v: boolean | undefined | null) => {
      switch (v) {
        case true: return "true";
        case false: return "false";
        default: return "";
      }
    }

    return (
      <FormControl required={required} disabled={disabled} error={!!fieldErrors[property]}>
        <FormLabel id={`${property}-label`} component="label">
          {label}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby={`${property}-label`}
          name={property}
          onChange={(e: any) => this.updateField(property, e.target.value === "true")}
          value={getValue(value)}
        >
          <FormControlLabel value="true" label="Yes" control={<Radio />} checked={value === true} />
          <FormControlLabel value="false" label="No" control={<Radio />} checked={value === false} />
        </RadioGroup>
        {!!fieldErrors[property] &&
          <FormHelperText>{fieldErrors[property]}</FormHelperText>
        }
      </FormControl>
    )
  }

  renderSelect = (property: string, label: string, value: IdLabel , options: Array<IdLabel>, multiple: boolean, disabled: boolean) => {
    const {
      fieldErrors
    } = this.props;
    
      return (
        <FormControl 
          className='form-field'
          margin='none'
          error={!!fieldErrors[property]}
          required
          variant='filled'
          fullWidth
        >
          <InputLabel shrink htmlFor={property}>
            {label}
          </InputLabel>
            <Select
              fullWidth
              disabled={disabled}
              value={value.id}
              name={property}
              onChange={(e: any) => {             
                let updatedId = e.target.value;
                if(updatedId===-1){
                  updatedId=null;
                }
                this.updateField(property, updatedId)
              }
              }
              required
              input={<FilledInput name={property} id={`org-select-${property}`} />}
            >
              {options.map(o => <MenuItem key={o.id} value={o.id}>{o.label}</MenuItem>)}
            </Select>
        </FormControl>
      );
  }

  renderStringValuesSelect = (property: string, label: string, value: string | Array<string>, options: Array<any>, multiple: boolean, disabled: boolean) => {
    const {
      fieldErrors
    } = this.props;

    if (multiple) {
      return (
        <FormControl 
          className='form-field'
          margin='none'
          error={!!fieldErrors[property]}
          required
          variant='filled'
          fullWidth
        >
          <InputLabel shrink htmlFor={property}>
            {label}
          </InputLabel>
            <Select
              fullWidth
              value={value}
              disabled={disabled}
              name={property}
              onChange={(e: any) => this.updateField(property, e.target.value)}
              required
              multiple
              input={<FilledInput name={property} id={`org-select-${property}`} />}
              renderValue={
                (val: any) => {
                  return (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                      {val.map((v: any) => <Chip color='primary' id={v} key={v} label={v} />)}
                    </div>
                  );
                }
              }
            >
              {options.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)}
            </Select>
        </FormControl>
      )
    } else {
      return (
        <FormControl 
          className='form-field'
          margin='none'
          error={!!fieldErrors[property]}
          required
          variant='filled'
          fullWidth
        >
          <InputLabel shrink htmlFor={property}>
            {label}
          </InputLabel>
            <Select
              fullWidth
              disabled={disabled}
              value={value}
              name={property}
              onChange={(e: any) => this.updateField(property, e.target.value)}
              required
              input={<FilledInput name={property} id={`org-select-${property}`} />}
            >
              {options.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)}
            </Select>
        </FormControl>
      );
    }
  }
  
  submit = () => {
    const { setOrganisationDetails, userId, organisationDetails, user } = this.props;
    let orgData : OrganisationModel = organisationDetails;

    orgData.contactEmail = user?.email ?? "";
    orgData.contactFirstName = user?.givenName ?? "";
    orgData.contactSurname = user?.surname ?? "";

    setOrganisationDetails(userId, orgData);
  }

  updateField = (property: string, value: string | boolean | number | Array<any> | Date) => {
    const { clearAlert, fieldErrors, updateOrganisationDetails } = this.props;
    
    updateOrganisationDetails(property, value);
    if (Object.keys(fieldErrors).length) {
      clearAlert();
    }
  }

  updateTypeOfLegalEntity = (value: Array<string>) => {
    const { updateOrganisationDetails } = this.props;
    const includesCharity = value.some((v: string) => v.toLowerCase().includes("charity"));

    if (!includesCharity) {
      updateOrganisationDetails("charityNumber", "");
    }

    this.updateField("typeOfLegalEntity", value);
  }

  getSelectedCountry = () => {
    if (this.props.referenceData?.countries && this.props.organisationDetails.countryId) {
      const refDataItem = this.props.referenceData.countries.find(x => x.id === this.props.organisationDetails.countryId);
      if (refDataItem) {
        const item: IdLabel = { id: refDataItem.id, label: refDataItem.description };
        return item;
      }
    }
    return { id: -1, label: " - " };
  }

  getSelectedOtherRegCountry = () => {
    if (this.props.referenceData?.countries && this.props.organisationDetails.otherCountryOfRegistration) {
      const refDataItem = this.props.referenceData.countries.find(x => x.id === this.props.organisationDetails.otherCountryOfRegistration);
      if (refDataItem) {
        const item: IdLabel = { id: refDataItem.id, label: refDataItem.description };
        return item;
      }
    }
    return { id: -1, label: " - " };
  }

  getSelectedTitle = () => {
    if (this.props.referenceData?.titles && this.props.organisationDetails.contactTitle) {
      const refDataItem = this.props.referenceData.titles.find(x => x.id === this.props.organisationDetails.contactTitle);
      if (refDataItem) {
        const item: IdLabel = { id: refDataItem.id, label: refDataItem.code };
        return item;
      }
    }
    return { id: -1, label: " - " };
  }

  getSelectedLegalEntityType = () => {
    if (this.props.referenceData?.legalEntityTypes && this.props.organisationDetails.otherLegalEntityType) {
      const refDataItem = this.props.referenceData.legalEntityTypes.find(x => x.id === this.props.organisationDetails.otherLegalEntityType);
      if (refDataItem) {
        const item: IdLabel = { id: refDataItem.id, label: refDataItem.code };
        return item;
      }
    }
    return { id: -1, label: " - " };
  }

  getCountries: (cs: Country[]) => IdLabel[] = (cs) => {
    return cs.sort((a, b) => a.sortOrder - b.sortOrder).map(x => ({ id: x.id, label: x.description }))
  }

  getTitles: (ts: Title[]) => IdLabel[] = (ts) => {
    return ts.map(x => ({ id: x.id, label: x.code }));
  }

  getLegalEntityTypes: (ts: LegalEntityType[]) => IdLabel[] = (ts) => {
    return ts.sort((a, b) => a.sortOrder - b.sortOrder).map(x => ({ id: x.id, label: x.value }));
  }

  fieldLabels = {
    "hasCompanyNumber": "Is the organisation registered with Companies House in the UK?",
    "hasCharityNumber": "Is the organisation registered with The Charity Commission in England and Wales?",
    "isOtherUkLegalEntity": "Is the organisation another kind of legal entity in the UK, such as a public body or further/higher education institution?",
    "isRegisteredInOtherCountry": "Is the organisation registered in another country?",
    "isIndividualOrPartnership": "Is the organisation an individual (sole trader) or a partnership (not including Limited Liability Partnerships)?",
    "intendingToOrDeliveringEPAs": "Are you intending to, or do they already deliver, apprenticeship end point assessments?"
  };
  
  render() {
    const {
      detailsLoading,
      detailsSubmitting,
      fieldErrors,
      organisationDetails,
      user,
      applicationSubmitted
    } = this.props;

    let orgData : OrganisationModel = organisationDetails;

    const tradingName = orgData.name;
    const legalName= orgData.legalName;
    const acronym = orgData.acronym;
    const listOfStandardFeesUrl = orgData.listOfStandardFeesUrl;
    const otherCountryIdentifier = orgData.otherCountryIdentifier;
    const email = orgData.email;

    const website = orgData.website;   
    const companyNumber= orgData.companyNumber;
    const charityNumber= orgData.charityNumber;

    const lineOne = orgData.line1;
    const lineTwo = orgData.line2;
    const lineThree = orgData.line3;
    const lineFour = orgData.line4;
    const phoneNumber = orgData.phoneNumber;
    const postcode = orgData.postCode;
  
    let firstName= orgData.contactFirstName;
    let lastName= orgData.contactSurname;
    let emailAddress= orgData.contactEmail;
    let roleInOrg = orgData.contactRoleInOrg;
    
    const showCharityNumberInput = orgData.hasCharityNumber;
    const showCompanyNumber = orgData.hasCompanyNumber;
    const isRegisteredInOtherCountry = orgData.isRegisteredInOtherCountry;
    const dateOfIncorporation = !!orgData.dateOfIncorporation
      ? orgData.dateOfIncorporation.toString()
      : "";

    if(user !== undefined){
      firstName = user.givenName;
      lastName = user.surname;
      emailAddress = user.email;
    }
    const readOnly = detailsLoading || applicationSubmitted;

    const acceptPolicy = () => { //acceptPrivacyPolicy(userId, user);
      const { cookies } = this.props;

      cookies.set(PRIVACY_POLICY_COOKIE, true, {
        sameSite: true,
        expires: moment().add(1, "months").toDate(),
        path: "/"
      });
    }

    const hasAcceptedPolicy = () => {
      const { cookies } = this.props;
      const privacyPolicyCookie = cookies.get(PRIVACY_POLICY_COOKIE);
      return privacyPolicyCookie
        ? Boolean(privacyPolicyCookie) === true
        : false;
    }

    const hasAcceptedCookies = () => {
      const { cookies } = this.props;
      return !!cookies.get(COOKIE_CONSENT);
    }

    return (
      <div className={`form-wrap${detailsLoading ? " loading" : ""}`}>
        <PrivacyDialog canShow={hasAcceptedCookies()} privacy={hasAcceptedPolicy()} acceptPrivacyPolicy={acceptPolicy}/>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Grid container spacing={0} justify='space-between' direction='row' alignItems='center'>
              <Grid item>
                <div>
                  <Typography variant='h5' align='left'>Organisation details</Typography>
                </div>
              </Grid>
              <Grid item>
                {detailsLoading && 
                  <div className="loading">
                    <CircularProgress/>
                  </div>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="form-content">
              <Grid container spacing={24}>
                <Grid item md={7} sm={12}>
                  {/* Org details */}
                  <Paper elevation={1} className='paper-detail'>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        {this.renderTextField("name", "name", tradingName, "Name", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>                    
                        {this.renderTextField("legalName", "legalName", legalName, "Legal Name", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("acronym", "acronym", acronym, "Acronym", readOnly, true)}
                      </Grid>                      
                      <Grid item xs={12}>
                        {this.renderTextField("email", "email", email, "Email", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("website", "website", website, "Website", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("listOfStandardFeesUrl", "listOfStandardFeesUrl", listOfStandardFeesUrl, "List of Standard Fees URL", readOnly, true)}                      
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderYesNoRadio("hasCompanyNumber", orgData?.hasCompanyNumber, this.fieldLabels["hasCompanyNumber"], true, readOnly)}
                      </Grid>
                      <Grid item xs={12} hidden={!orgData.hasCompanyNumber}>
                        {this.renderTextField("companyNumber", "companyNumber", companyNumber, "Registered company number", readOnly, showCompanyNumber)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderYesNoRadio("hasCharityNumber", orgData?.hasCharityNumber, this.fieldLabels["hasCharityNumber"], true, readOnly)}
                      </Grid>
                      <Grid item xs={12} hidden={!orgData.hasCharityNumber}> 
                        {this.renderTextField("charityNumber", "charityNumber", charityNumber, "Registered charity number", readOnly, showCharityNumberInput)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderYesNoRadio("isOtherUkLegalEntity", orgData?.isOtherUkLegalEntity, this.fieldLabels["isOtherUkLegalEntity"], false, readOnly)}
                      </Grid>
                      <Grid item xs={12} hidden={!orgData.isOtherUkLegalEntity}>
                        {this.renderSelect("otherLegalEntityType", "Other legal entity type", this.getSelectedLegalEntityType(), this.state.legalEntityTypes, false, readOnly)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderYesNoRadio("isRegisteredInOtherCountry", orgData?.isRegisteredInOtherCountry, this.fieldLabels["isRegisteredInOtherCountry"], false, readOnly)}
                      </Grid>
                      <Grid item xs={12} hidden={!orgData.isRegisteredInOtherCountry}>
                        {this.renderSelect("otherCountryOfRegistration", "Main country of registration", this.getSelectedOtherRegCountry(), this.state.countries, false, readOnly)}
                      </Grid>
                      <Grid item xs={12} hidden={!orgData.isRegisteredInOtherCountry}>
                        {this.renderTextField("otherCountryIdentifier", "otherCountryIdentifier", otherCountryIdentifier, "Registration number in that country", readOnly, isRegisteredInOtherCountry)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderYesNoRadio("isIndividualOrPartnership", orgData?.isIndividualOrPartnership, this.fieldLabels["isIndividualOrPartnership"], false, readOnly)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderYesNoRadio("intendingToOrDeliveringEPAs", orgData?.intendingToOrDeliveringEPAs, this.fieldLabels["intendingToOrDeliveringEPAs"], true, readOnly)}
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker 
                          disableFuture={true}
                          error={fieldErrors["dateOfIncorporation"]}
                          fieldName="dateOfIncorporation"
                          label="Date of incorporation"
                          onChange={(dt: Date) => {
                            this.updateField("dateOfIncorporation", dt)
                          }}
                          value={dateOfIncorporation.toString()}
                          disabled={readOnly}
                          fullWidth={true}
                          withManual={true}
                          format={"DD/MM/YYYY"}
                        />
                      </Grid>
                   
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='h6'>Organisations Address</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("line1", "line1", lineOne, "Line 1", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("line2", "line2", lineTwo, "Line 2", readOnly)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("line3", "line3", lineThree, "Line 3", readOnly)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("line4", "line4", lineFour, "Town/City", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("postCode", "postCode", postcode, "Postcode", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderSelect("countryId", "Country", this.getSelectedCountry(), this.state.countries, false, readOnly)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("phoneNumber", "phoneNumber", phoneNumber, "Phone Number", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='h6'>Contact details</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderSelect("contactTitle", "Title", this.getSelectedTitle(), this.state.titles, false, readOnly)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("contactFirstName", "contactFirstName", firstName, "First Name", true, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("contactSurname", "contactSurname", lastName, "Surname", true, true)}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderTextField("contactEmail", "contactEmail", emailAddress, "Email", true, true)}
                      </Grid>                      
                      <Grid item xs={12}>
                        {this.renderTextField("contactRoleInOrg", "contactRoleInOrg",
                          roleInOrg, "(Proposed) Role in Organisation", readOnly, true)}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      
                      <Grid item xs={12}>
                        <div style={{ float: 'right', paddingRight: 10 }}>
                          <Button
                            className="submit-form"
                            color="primary"
                            onClick={() => this.submit()}
                            variant="contained"
                            disabled={detailsSubmitting || readOnly}
                          >
                            Save
                            {detailsSubmitting && <CircularProgress size={20}/>}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item md={5} sm={12}>
                  {/* Responses */}
                  <Tabs value={0} onChange={() => {}} variant='fullWidth'>
                    <Tab component='div' label='Responses' icon={<QuestionAnswer />} />
                  </Tabs>
                  <div className='tab-content'>
                    <div id='responses'>
                      <Paper elevation={1} id='metadata' className='paper-detail'>
                        <ResponsesList />
                      </Paper>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  
}

function mapStateTopProps(state: any) {
  const { organisations, alert, applications, referenceData } = state;
  const { fieldErrors } = alert;
  const {
    organisationDetails,
    loading: detailsLoading,
    submitting: detailsSubmitting
  } = organisations;

  let application = null;
  if(organisationDetails) {
    ({ application } = organisationDetails);
  }
  
  return {
    organisationDetails,
    detailsLoading,
    detailsSubmitting,
    application,
    fieldErrors,
    applicationSubmitted: applications?.currentApplication?.submittedDate != null,
    referenceData
  }
}

const OrganisationWithCookies = withCookies(Organisation);

export default connect(mapStateTopProps, {
  getOrganisationDetails, setOrganisationDetails, clearAlert, updateOrganisationDetails, acceptPrivacyPolicy, getRefData
})(OrganisationWithCookies);