import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

interface Props {
    show: boolean;
    onClose: Function;
    onAttach: (files: FileList | null) => void;
}

const AttachmentUpload = (props: Props) => {
    const { show, onClose, onAttach } = props;

    let initialFiles: FileList | null = null;

    const [files, setFiles]: [FileList | null, Function] = useState(initialFiles);

    return (
        <Dialog open={show} onClose={() => onClose()}>
            <DialogTitle id='attachments-dialog-title'>Upload attachments</DialogTitle>
            <DialogContent>
                <DialogContentText id='attachments-dialog-description' style={{marginBottom: 20}}>
                    Select file(s)...
                </DialogContentText>
                <input type='file' name='file' multiple id='attachments-dialog-file-input' onChange={(e) => setFiles(e.target.files)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color='primary' id='attachments-dialog-close-button'>
                    Cancel
                </Button>
                <Button onClick={() => onAttach(files)} color='primary' id='attachments-dialog-upload-button'>
                    Upload
                </Button> 
            </DialogActions>
        </Dialog>
    );
}

export default AttachmentUpload;