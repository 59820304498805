import React from "react";
import { Grid, Typography } from '@material-ui/core';
import { IApplicationProps } from "../../../types";
import { Checkbox } from "../../";

class ApplicationPage1 extends React.Component<IApplicationProps> {
  
  render() {
    const { fieldErrors, pageData, updateApplicationState, readonlyControls } = this.props;
    const { privacyPolicyConfirmed } = pageData;
    
    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>1. Introduction</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Accuracy of the information you provide</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              We are required under <a href="https://www.legislation.gov.uk/ukpga/2009/22/notes/division/5/7/2/6" target="_blank" rel="noopener noreferrer">Section 148</a> of the <a href="https://www.legislation.gov.uk/ukpga/2009/22" target="_blank" rel="noopener noreferrer">Apprenticeships, Skills, Children and Learning Act 2009</a> to publish a Register of regulated qualifications. We publish a Register
              containing the details of awarding 
              organisations and regulated qualifications.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>The details of an awarding organisation will include:</Typography>
            <Typography variant='body1'> • the qualifications in respect of which it is recognised</Typography>
            <Typography variant='body1'> • the details of those qualifications which are awarded or authenticated by it</Typography>
            <Typography variant='body1'>
              {` • the awarding organisation's organisation name, legal name, abbreviation/acronym, email address, 
              website and trading address.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`Applicants must make sure all details provided in this application are accurate and current, as this 
                information will be published on the register should we grant recognition.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`We will complete a due diligence check through an external business information supplier to 
                verify information you have submitted as part of your application.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`If there are any changes to the information or a change in circumstances during the period between 
                submitting the application and us telling you of our decision 
                (for example, a change in the senior management team) you must notify us in writing. 
                Please email any changes to: recognition@ofqual.gov.uk`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`You can withdraw your application at any time before it is granted or returned. 
                Please email your request to withdraw your application to recognition@ofqual.gov.uk`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Saving your progress</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`You can save your progress and come back to complete your application later. 
                The application will save all details up to the last page you have completed 
                (it won't save the page you're currently on, so click 'Next' if you need to save details on the current 
                page).`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>Throughout this application form, all questions with an asterisk * must be answered.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Privacy Policy</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>I confirm that I have read and I accept the privacy statement.*</Typography>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={readonlyControls}
              error={fieldErrors["application.pageData.privacyPolicyConfirmed"]}
              fieldName="privacyPolicyConfirmed"
              label="I confirm the above statement"
              onChange={(value: boolean) => updateApplicationState(0, "privacyPolicyConfirmed", value)}
              value={privacyPolicyConfirmed}
            />
          </Grid>
        </Grid>
      </div> 
    );
  }
}

export default ApplicationPage1;