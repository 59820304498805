import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, Typography } from '@material-ui/core';

import { IApplicationProps } from "../../../types";
import { DatePicker, TextField } from "../../";

class ApplicationPage3 extends React.Component<IApplicationProps> {

  render() {
    
    const {
      fieldErrors,
      organisationDetails
    } = this.props;
  
    const showCharityTextField = organisationDetails?.hasCharityNumber ?? false;
    const showCompanyNumberField = organisationDetails?.hasCompanyNumber ?? false;

    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>4. Legal entity</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>These details can be changed in the <NavLink to="/">Organisation Details</NavLink> page.</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={fieldErrors["application.pageData.tradingName"]}
              fieldName="organisationDetails.name"
              label="Name of organisation"
              value={organisationDetails.name}
              disabled
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.legalName"]}
              fieldName="organisationDetails.legalName"
              label="Legal name (if different)"
              value={organisationDetails.legalName}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.acronym"]}
              fieldName="organisationDetails.acronym"
              label="Abbreviation or acronym – please check this is not the same or similar to one on our Register of Regulated Qualifications"
              value={organisationDetails.acronym}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>5. Company Number/Charity Number</Typography>
          </Grid>
          {!showCompanyNumberField && !showCharityTextField &&
            <Grid item xs={12}>
              <Typography variant="body1">No company number or charity number specified</Typography>
            </Grid>
          }
          {showCompanyNumberField && 
            <Grid item xs={12}>
              <TextField
                error={fieldErrors["application.pageData.companyHouseRegNumber"]}
                fieldName="organisationDetails.companyNumber"
                label="Company number"
                value={organisationDetails.companyNumber}
                disabled
              />
            </Grid>}
          {showCharityTextField && 
            <Grid item xs={12}>
              <TextField
                error={fieldErrors["application.pageData.charitiesCommissionRegNumber"]}
                fieldName="organisationDetails.charityNumber"
                label="Charities commission registration number (where applicable)"
                value={organisationDetails.charityNumber}
                disabled
              />
            </Grid>}
          <Grid item xs={12}>
            <Typography variant='h5'>6. Date of incorporation</Typography>
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              error={fieldErrors["application.pageData.dateOfIncorporation"]}
              fieldName="organisationDetails.dateOfIncorporation"
              value={organisationDetails.dateOfIncorporation}
              disabled
              format="DD/MM/YY"
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage3;