import {
  GET_ORGANISATIONS_REQUEST,
  GET_ORGANISATIONS_FAILURE,
  GET_ORGANISATIONS_SUCCESS,
  GET_ORGANISATION_DETAILS_REQUEST,
  GET_ORGANISATION_DETAILS_FAILURE,
  GET_ORGANISATION_DETAILS_SUCCESS,
  SET_ORGANISATION_DETAILS_REQUEST,
  SET_ORGANISATION_DETAILS_SUCCESS,
  SET_ORGANISATION_DETAILS_FAILURE,
  SORT_ORGANISATIONS_BY_FOLLOW_UP,
  SORT_ORGANISATIONS_BY_ASSIGNED,
  SORT_ORGANISATIONS_BY_LAST_RESPONSE,
  SORT_ORGANISATIONS_BY_STATUS,
  UPDATE_ORGANISATION_DETAILS
} from "../constants";

import {
  getOrganisationsV2 as getOrganisationsService,
  getOrganisationDetailsV2 as getOrganisationDetailsService,
  setOrganisationDetailsV2 as setOrganisationDetailsService
} from "../services";

import { IOrganisationDetails } from "../types";

import { error, alertAction, clearAlert } from "./";

export function sortOrgsByFollowUp(payload: string) {
  
  return (dispatch: Function) => {
    dispatch({ type : SORT_ORGANISATIONS_BY_FOLLOW_UP, payload });
  }
  
}

export function sortOrgsByStatus(payload: string) {
  
  return (dispatch: Function) => {
    dispatch({ type : SORT_ORGANISATIONS_BY_STATUS, payload });
  }
  
}

export function sortOrgsByAssigned(payload: string) {
  
  return (dispatch: Function) => {
    dispatch({ type : SORT_ORGANISATIONS_BY_ASSIGNED, payload });
  }
  
}

export function sortOrgsByLastResponse(payload: string) {
  
  return (dispatch: Function) => {
    dispatch({ type : SORT_ORGANISATIONS_BY_LAST_RESPONSE, payload });
  }
  
}

export function getOrganisations() {
  
  return async (dispatch : Function) => {
    dispatch({ type: GET_ORGANISATIONS_REQUEST });
    
    return getOrganisationsService().then((payload: Object) => {
      
      dispatch({ type: GET_ORGANISATIONS_SUCCESS, payload });
      
    }).catch((errors: any) => {
      dispatch({ type: GET_ORGANISATIONS_FAILURE });
      dispatch(error(errors));
    });
    
  }
  
}

export function updateOrganisationDetails(property: keyof IOrganisationDetails, value: any) {
  return (dispatch : Function) => {
    dispatch({ type: UPDATE_ORGANISATION_DETAILS, payload: { property, value } });
    dispatch(clearAlert());
  }
}

export function getOrganisationDetails(userId: string) {
  
  return async(dispatch : Function) => {
    dispatch({ type: GET_ORGANISATION_DETAILS_REQUEST });
    
    return getOrganisationDetailsService(userId).then((payload: Object) => {
      
      dispatch({ type: GET_ORGANISATION_DETAILS_SUCCESS, payload });
      
    }).catch(() => {
      
      dispatch({ type: GET_ORGANISATION_DETAILS_FAILURE });
    });
    
  }
  
}

export function setOrganisationDetails(userId: string, details: any) {
  
  return async(dispatch : Function) => {
    dispatch({ type: SET_ORGANISATION_DETAILS_REQUEST });
    
    return setOrganisationDetailsService(userId, details).then((payload: Object) => {
      
      dispatch({ type: SET_ORGANISATION_DETAILS_SUCCESS, payload });
      dispatch(alertAction("Details successfully updated. \nOfqual will be in touch with you shortly. You will receive a notification to log back into the gateway if necessary."));
      
    }).catch((errors: any) => {
      dispatch({ type: SET_ORGANISATION_DETAILS_FAILURE });
      dispatch(error(errors));
      
    });
    
  }
  
}
