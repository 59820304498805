import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from 'redux-saga'
import rootReducer from "../reducers";

import authSaga from '../sagas/authentication';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export const configureStore = () => { 
  const store = createStore(
    rootReducer(history),
    applyMiddleware(routerMiddleware(history), thunkMiddleware, sagaMiddleware)
  );

  sagaMiddleware.run(authSaga);
  return store;
}