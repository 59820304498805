import React from "react";
import { NavLink } from "react-router-dom";

import { IApplicationProps } from "../../../types";
import { TextField, WordCountField } from "../../";
import { Grid, Typography } from '@material-ui/core';

import {
  APPLICATION_CHARACTERS_MAX,
  APPLICATION_WORDS_MAX
} from "../../../constants";

interface IPage2State {
  contactTitle: string;
}

class ApplicationPage2 extends React.Component<IApplicationProps, IPage2State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contactTitle: ""
    };
  }

  getTitles() {
    return this.props.referenceData?.titles ?? [];
  }

  componentDidMount() {
    if (this.props.organisationDetails) {
      const selectedTitle = this.getTitles().filter(item=>item.id === this.props.organisationDetails.contactTitle);
      if(selectedTitle && selectedTitle.length===1){
        this.setState({
          contactTitle: selectedTitle[0]?.code
        });
      }
    }
  }

  componentDidUpdate(prevProps: IApplicationProps) {
    if (this.props.organisationDetails && this.props.organisationDetails !== prevProps.organisationDetails) {
      const selectedTitle = this.getTitles().filter(item=>item.id === this.props.organisationDetails.contactTitle);
      if(selectedTitle && selectedTitle.length===1){
        this.setState({
          contactTitle: selectedTitle[0]?.code
        });
      }
    }
  }

  render() {    
    const {
      fieldErrors,
      organisationDetails,
      pageData,
      updateApplicationState,
      readonlyControls
    } = this.props;
    
    return (
      <div className="content">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant='h5'>2. What do you want to be recognised by Ofqual for?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Type of Qualifications</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              {`Documentation regarding SSAs can be found `}
              <a href="https://www.gov.uk/government/publications/types-of-regulated-qualifications/qualification-descriptions#sector" target="_blank" rel="noopener noreferrer">here.</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.qualificationDetails"]}
              fieldName="qualificationDetails"
              label="Please give details of the specified qualifications, End Point Assessments (EPAs) or descriptions of qualifications you wish to offer, referring to SSAs and Levels where possible."
              maxCharacters={APPLICATION_CHARACTERS_MAX}
              maxWords={APPLICATION_WORDS_MAX}
              onChange={(value: string) => updateApplicationState(1, "qualificationDetails", value)}
              value={pageData["qualificationDetails"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>You are applying to Ofqual to be recognised to offer qualifications in England.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{`To offer qualifications regulated by Qualifications Wales, you must apply to `}
              <a href="https://www.qualificationswales.org/english/" target="_blank" rel="noopener noreferrer">Qualifications Wales.</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{`To offer qualifications regulated by CCEA Regulation in Northern Ireland, you must apply to `}
              <a href="http://ccea.org.uk/regulation" target="_blank" rel="noopener noreferrer">CCEA Regulator.</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <WordCountField
              error={fieldErrors["application.pageData.explanationOnRecognised"]}
              label="Please explain why you want to be recognised by Ofqual to offer regulated qualifications."
              fieldName="explanationOnRecognised"
              maxCharacters={APPLICATION_CHARACTERS_MAX}
              maxWords={APPLICATION_WORDS_MAX}
              onChange={(value: string) => updateApplicationState(1, "explanationOnRecognised", value)}
              value={pageData["explanationOnRecognised"] || ""}
              disabled={readonlyControls}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>3. Contact details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>These details can be changed in the <NavLink to="/">Organisation Details</NavLink> page.</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.title"]}
              fieldName="organisationDetails.contactTitle"
              label="Title"
              value={this.state.contactTitle}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.firstName"]}
              fieldName="organisationDetails.contactFirstName"
              label="First Name"
              value={organisationDetails.contactFirstName}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.lastName"]}
              fieldName="organisationDetails.contactSurname"
              label="Last Name"
              value={organisationDetails.contactSurname}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.emailAddress"]}
              fieldName="organisationDetails.contactEmail"
              label="Email"
              value={organisationDetails.contactEmail}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.roleInOrg"]}
              fieldName="organisationDetails.contactRoleInOrg"
              label="(Proposed) Role in Organisation"
              value={organisationDetails.contactRoleInOrg}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.email"]}
              fieldName="organisationDetails.email"
              label="Organisation's Email"
              value={organisationDetails.email}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.website"]}
              fieldName="organisationDetails.website"
              label="Website"
              value={organisationDetails.website}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.address.lineOne"]}
              fieldName="organisationDetails.line1"
              label="Trading address line 1"
              value={organisationDetails.line1}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.address.lineTwo"]}
              fieldName="organisationDetails.line2"
              label="Trading address line 2"
              value={organisationDetails.line2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.address.lineThree"]}
              fieldName="organisationDetails.line3"
              label="Trading address line 3"
              value={organisationDetails.line3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.address.lineFour"]}
              fieldName="organisationDetails.line4"
              label="Trading address town/city"
              value={organisationDetails.line4}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.address.country"]}
              fieldName="organisationDetails.country"
              label="Country"
              value={organisationDetails.country}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.address.postcode"]}
              fieldName="organisationDetails.postcode"
              label="Post Code"
              value={organisationDetails.postCode}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              error={fieldErrors["application.pageData.address.phoneNumber"]}
              fieldName="organisationDetails.phoneNumber"
              label="Telephone Number"
              value={organisationDetails.phoneNumber}
              required={true}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default ApplicationPage2;