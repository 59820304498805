import { getRefData } from "./api";
import { Country, Title, LegalEntityType } from "../types/ref-data";

export function getCountries(): Promise<Country[]> {
    return getRefData<Country[]>("/countries?activeOnly=true&portalOnly=true");
}

export function getTitles(): Promise<Title[]> {
    return getRefData<Title[]>("/titles?activeOnly=true");
}

export function getLegalEntityTypes(): Promise<LegalEntityType[]> {
    return getRefData<LegalEntityType[]>("/legalentitytypes?activeOnly=true");
}