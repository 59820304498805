import { push } from "connected-react-router";
import jwt_decode from "jwt-decode";

import {
  LOGOUT,
  USER_FAILURE,
  USER_SUCCESS,
  ACCEPT_PRIVACY_POLICY
} from "../constants";

import {
  getMe as getMeService,
  acceptPrivacyPolicy as acceptPrivacyPolicyService
} from "../services";
import { IUser } from "../types";

export function getMe() {
  return (dispatch: Function) => {
    getMeService().then((payload: object) => {
      dispatch({ type: USER_SUCCESS, payload });
    }).catch(err => {
      console.log("Something went wrong logging in");
      dispatch({ type: USER_FAILURE });
    })
  }
}

export function getMeFromToken(token: string) {
  return (dispatch: Function) => {
    const withoutBearer = token.replace("Bearer ", "");
    const decoded: any = jwt_decode(withoutBearer);
    const email: string = decoded.emails[0];
    const givenName = decoded["given_name"]
    const surname = decoded["family_name"];
    const user: IUser = {
      _id: "",
      givenName,
      surname,
      email,
      privacy: false,
      type: -1
    }
    dispatch({ type: USER_SUCCESS, payload: user });
  }
}

export function acceptPrivacyPolicy(userId: string, user: IUser) {
  return async(dispatch: Function) => {
    dispatch({ type: ACCEPT_PRIVACY_POLICY });
    return acceptPrivacyPolicyService(userId, user);
  }
}

export function logout() {
  return (dispatch: Function) => {
    dispatch({ type: LOGOUT });
    dispatch(push("/"));
  };
}