import React, { Component } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";

import { DownloadLink } from "./";
import { SupportingDocument } from "../../types";
import { MAX_UPLOAD_SIZE } from "../../constants";

interface IState {
  selectedFiles: FileList | null
}

interface Props {
  error?: string;
  label?: string;
  uploadAction: Function;
  value: Array<SupportingDocument> | null;
  multiple?: boolean;
  getDownload: Function;
  deleteFile: Function;
  downloadUrl: string;
  disabled: boolean;
}

class Upload extends Component<Props,IState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFiles: null
    }
  }
  onUploadChanged = (e: any) => {
    this.setState({ selectedFiles: e.target.files });
  }
  
  upload = () => {
    const { uploadAction, multiple } = this.props;
    const { selectedFiles } = this.state;
    if (selectedFiles) {
      if(multiple) {
        uploadAction(selectedFiles);
      } else {
        uploadAction(selectedFiles[0]);
      }
    }
  }
  
  render() {
    const { label, error, value, multiple, getDownload, downloadUrl, deleteFile, disabled } = this.props;
    const { selectedFiles } = this.state;
    
    let filesAboveLimit = false;
    let showError = error;
    if (selectedFiles) {
      for(let i=0; i < selectedFiles.length; i++) {
        const file = selectedFiles.item(i);
        if (file && file.size > MAX_UPLOAD_SIZE) {
          filesAboveLimit = true;
          const fileInMb = Math.round(
            MAX_UPLOAD_SIZE / Math.pow(1024, Math.floor(Math.log(MAX_UPLOAD_SIZE) / Math.log(1024)))
          );
          showError = `A file selected exceeded the limit of ${fileInMb}MB`;
          break;
        }
      }
    }

    return (
      <div className="upload">
        <InputLabel>{label}</InputLabel>
        <Input
          fullWidth={true}
          type="file"
          onChange={this.onUploadChanged}
          error={(error !== undefined) || filesAboveLimit}
          inputProps={{ multiple }}
          disabled={disabled || filesAboveLimit}
        />
        <FormHelperText>{showError}</FormHelperText>
        {value && (value as Array<SupportingDocument>).length > 0 && (
          <div>
            <InputLabel>Download: </InputLabel>
            <DownloadLink file={value} getDownload={getDownload} downloadUrl={downloadUrl} deleteFile={deleteFile} disabled={disabled} />
          </div>
        )}
        {selectedFiles && (
          <Button
            fullWidth={true}
            variant="contained"
            onClick={this.upload}
            disabled={error !== undefined || filesAboveLimit || disabled}
          >
              Upload
          </Button>
        )}
      </div>
    )
  }
}

export default Upload;