import React from "react";
import { Typography } from '@material-ui/core';
import { TextField } from "./";

interface Props {
  error: undefined | string,
  fieldName: string,
  label?: string,
  maxCharacters: number,
  maxWords: number,
  onChange: Function,
  rows?: number,
  rowsMax?: number,
  value: string,
  disabled?: boolean
};

const WordCountField = (props: Props) => {
  const {
    error,
    fieldName,
    label,
    maxCharacters,
    maxWords,
    onChange,
    rows = 10,
    rowsMax = 20,
    value,
    disabled
  } = props;
  
  const wordsRemaining = maxWords - value.split(" ").length;
  const charsRemaining = maxCharacters - value.length;
  
  return (
    <div className="word-count-wrap">
      <TextField
        error={error}
        fieldName={fieldName}
        label={label}
        onChange={onChange}
        multiLine={true}
        rows={rows}
        rowsMax={rowsMax}
        value={value}
        disabled={disabled}
        required
      />
      <Typography variant='caption'>{wordsRemaining} words / {charsRemaining} characters</Typography>
    </div>
  )
};

export default WordCountField;
