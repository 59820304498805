import { LOADING_REF_DATA, REF_DATA_SUCCESS, REF_DATA_ERROR } from "../constants/referenceData";
import { 
    getCountries as getCountriesService,
    getTitles as getTitlesService,
    getLegalEntityTypes as getLegalEntityTypesService,
} from "../services/refData";
import { LegalEntityType, Country, Title } from "../types/ref-data";

export function getRefData() {
    return async (dispatch : Function) => {
        dispatch({ type: LOADING_REF_DATA });
        
        const promises: [ Promise<Country[]>, Promise<LegalEntityType[]>, Promise<Title[]> ] = [
            getCountriesService(),
            getLegalEntityTypesService(),
            getTitlesService()
        ];

        return Promise.all(promises).then(([countries, legalEntityTypes, titles]) => {
            const payload = { countries, titles, legalEntityTypes };
            dispatch({ type: REF_DATA_SUCCESS, payload })
        }).catch(err => {
            dispatch({ type: REF_DATA_ERROR });
        });        
      }
}