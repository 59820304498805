import {
    GET_SUPPORT_DOCUMENT_REQUEST,
    GET_SUPPORT_DOCUMENT_SUCCESS,
    GET_SUPPORT_DOCUMENT_FAILURE
} from "../constants";

import { Action } from "../types"

interface State {
    supportDownloadUrl: string,
    loading: boolean
}

const initialState : State = {
    supportDownloadUrl: '',
    loading: false
}

export function support(state = initialState, action: Action) {
    const { payload }: { payload?: any } = action;

    switch(action.type) {
        case GET_SUPPORT_DOCUMENT_REQUEST:
            return { ...state, loading: true, supportDownloadUrl: '' };
        case GET_SUPPORT_DOCUMENT_SUCCESS:
            return { ...state, loading: false, supportDownloadUrl: payload };
        case GET_SUPPORT_DOCUMENT_FAILURE:
            return { ...state, loading: false };
        default:
            return state
    }
}