import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@material-ui/core";

const DownloadErrorDialog: React.FC<{ open: boolean, dismiss: Function }> = (props) => {
  const { open, dismiss } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>Something went wrong</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Please try again later or contact support if the problem persists</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismiss()}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadErrorDialog;