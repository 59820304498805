import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import {
  getUserApplication
} from "../../../actions";
import { IFieldErrors, IApplication, IResponse, IApplicationManagement, IUser } from "../../../types";
import { withFormErrors } from "../../utils";
import { ApplicationManagement, Organisation } from "./";

interface Props extends RouteComponentProps {
  title: string,
  userId: string | null,
  getOrganisationDetails: Function,
  setOrganisationDetails: Function,
  // receivedMessageFromSocket: Function,
  getApplicationStatus: Function,
  setApplicationStatus: Function,
  getUserApplication: Function,
  type: number,
  fieldErrors: IFieldErrors,
  clearAlert: Function,
  organisationDetails: any,
  application: IApplication,
  responses: Array<IResponse>,
  applicationStatus: IApplicationManagement
  user: IUser | undefined,
  getRefData: Function
}

interface State {
  detailView: number
};

class OrganisationDetails extends Component<Props, State> {
  
  constructor(props : Props) {
    super(props);
    
    this.state = {
      detailView: 0,
    };
  }
  
  componentDidMount() {
    
    const {
      getUserApplication,
    } = this.props;

    getUserApplication();
  }
  
  componentDidUpdate(prevProps: Props) {
    const { match, userId } = this.props;
    const { userId : fromUrl } = match.params as any;

    if(prevProps.userId !== userId || (prevProps.match.params as any).userId !== fromUrl) {
      getUserApplication();
    }
  }

  renderOrgDetails = () => {
    return (
      <div className="page-wrap details">
        {this.renderApplicantView()}
      </div>
    )
  };

  renderManagerView = () => {
    
    const { application } = this.props;
    if(application) {
      return <ApplicationManagement application={application}/>
    } 
    
    return null;
    
  };
  
  renderApplicantView = () => {
    
    const { match, userId, application, user } = this.props;
    const { userId : fromUrl } = match.params as any;
    
    const initStatus = -1;
    let status = initStatus;
    if (application) {
      status = application?.status ?? initStatus;
    }

    return (
      <Organisation
        userId={fromUrl || userId}
        applicationStatus={status}
        user={user}
      />
    )
  };
  
  // renderMessagePanel = () => {
    
  //   const { socketConnection, userId, application, type } = this.props;
    
  //   if(application) {
  //     return <Responses
  //       socketConnection={socketConnection}
  //       userId={userId}
  //       applicationId={application._id}
  //       isAdmin={+type === +ROLES.RECOGNITION_STAFF}
  //     />
  //   } else {
  //     return null;
  //   }
    
  // };
  
  render() {

    return (
      <div className="organisation-details-wrap">
        {this.renderOrgDetails()}
      </div>
    )
  }
  
}

const mapStateToProps = (state: any, props: Props) => {
  const {
    applications
  } = state;
  
  const { application } = applications;

  const { userId } = props;

  return {
    userId,
    application
  }
}

export default withFormErrors(connect(mapStateToProps, {
  getUserApplication
})(OrganisationDetails));